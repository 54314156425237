import { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Card, Col, Divider, Row, Spin, Table, Drawer, PageHeader, notification } from "antd";
import { getDisputes, resolveDisputes, signOut } from "../../../services/http_service";
import NProgress from "nprogress";
import dayjs from "dayjs";
import numeral from "numeral";

import { DescriptionItem } from "./MerchantDocs";

import "./Merchants.scss";

export const BucketButton = ({ text, onClick, active_name }) => {
  return (
    <Col span={4}>
      <Card bordered className={`crd ${active_name === text.split(" ")[0].toLowerCase() && "active_bucket"}`}>
        <Button style={{ fontSize: "12px" }} className="px-0" onClick={() => onClick()} type="link">
          {text}
        </Button>
      </Card>
    </Col>
  );
};

export const Disputes = () => {
  const [is_fetching, setIsFetching] = useState(false);
  const [active_name, setActiveName] = useState("merchant");
  const [tableDetails, setTableDetails] = useState([]);
  const [openDrawer, setDrawerOpen] = useState(false);
  const [currentDetails, setCurrentDetails] = useState({});
  let history = useHistory();
  let location = useLocation();

  let handleLogout = useCallback(() => {
    NProgress.inc();

    signOut()
      .then((res) => {
        localStorage.clear();
        sessionStorage.clear();
        history.push("/?return_url=" + location.pathname);
      })
      .catch((err) => {
        localStorage.clear();
        sessionStorage.clear();
        history.push("/?return_url=" + location.pathname);
      })
      .finally(() => {
        NProgress.done();
      });
  }, [history, location.pathname]);

  const fetchDisputes = async (type, page) => {
    try {
      setActiveName(type);
      NProgress.inc();
      setIsFetching(true);
      const res = await getDisputes({ type }, page || 1);
      setTableDetails(res.data);
      setIsFetching(false);
      NProgress.done();
    } catch (err) {
      setTableDetails("error", err);
      setIsFetching(false);
      NProgress.done();
      if (err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();
    }
  };

  useEffect(() => {
    fetchDisputes(active_name, 1);
  }, []);

  const table_columns = [
    {
      title: "Merchant",
      dataIndex: "transacation",
      key: "merchant",
      render: (_, record) => {
        return <span className="">{record?.transaction?.wallet?.merchant?.businessname}</span>;
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => {
        let color = status === "pending" ? "orange" : "green";
        return <span style={{ color }}>{status}</span>;
      },
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, record) => {
        return <span className="text-cut">{dayjs(record.createdAt).format("DD MMM YYYY")}</span>;
      },
    },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
      render: (_, record) => {
        return <span className="text-cut">{record.remark}</span>;
      },
    },
    {
      title: "Transaction Ref",
      dataIndex: "transaction",
      key: "transaction",
      render: (_, record) => {
        return <p className="text-cut">{record.transaction.transactionref}</p>;
      },
    },
  ];

  let _type_ = active_name[0].toUpperCase() + active_name.substring(1, active_name.length);

  return (
    <div className="merchants">
      <Spin spinning={is_fetching}>
        <Divider />
        <div className="site-card-wrapper">
          <Row align="bottom" gutter={16}>
            <BucketButton active_name={active_name} text="Merchant Disputes" onClick={() => fetchDisputes("merchant")} />
            <BucketButton active_name={active_name} text="Cashpoint Disputes" onClick={() => fetchDisputes("cashpoint")} />
            <BucketButton active_name={active_name} text="Aggregator Disputes" onClick={() => fetchDisputes("aggregator")} />
            <BucketButton active_name={active_name} text="Bonus Disputes" onClick={() => fetchDisputes("bonus")} />
            <BucketButton active_name={active_name} text="Referral Disputes" onClick={() => fetchDisputes("referral")} />
          </Row>
        </div>
        <Divider />
        {tableDetails?.disputes && (
          <Table
            pagination={{
              total: Number(tableDetails.pages * tableDetails.disputes.length),
              current: Number(tableDetails.page),
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  setCurrentDetails(record);
                  setDrawerOpen(true);
                }, // click row
              };
            }}
            onChange={(event) => fetchDisputes(active_name, event.current)}
            showSizeChanger
            className="ant-dispute-table"
            bordered
            size="small"
            rowKey={(record) => record._id}
            columns={table_columns}
            dataSource={tableDetails.disputes}
          />
        )}
      </Spin>
      <Drawer visible={openDrawer} width={900} onClose={() => setDrawerOpen(false)} closable={true}>
        <Row gutter={30}>
          {currentDetails && openDrawer && active_name !== "cashpoint" && (
            <MerchantDisputeDetails active_name={active_name} setDrawerOpen={setDrawerOpen} fetchDisputes={fetchDisputes} type={_type_} details={currentDetails} />
          )}
          {currentDetails && openDrawer && active_name === "cashpoint" && (
            <CashpointtDisputeDetails active_name={active_name} setDrawerOpen={setDrawerOpen} fetchDisputes={fetchDisputes} type={_type_} details={currentDetails} />
          )}
          {!currentDetails && <PageHeader className="site-page-header px-0" title="Empty Content" />}
        </Row>
      </Drawer>
    </div>
  );
};

export const MerchantDisputeDetails = ({ type, details, fetchDisputes, active_name, setDrawerOpen }) => {
  const [is_loading, setLoading] = useState(false);
  const history = useHistory();
  const {
    remark = "",
    title = "",
    status = "",
    _id = "",
    transaction: {
      wallet: {
        merchant: { businessname = "", businessemail = "", businessphone = "", _id: merchant_id = "" },
      },
      amount = 0,
      beneficiary_account_name = "",
      beneficiary_account_number = "",
      beneficiary_bank = "",
      narrative = "",
      type: Type = "",
    },
  } = details;

  const onResolveClick = async () => {
    let data = {
      type: type.toLowerCase(),
      dispute_id: _id,
      user_id: merchant_id,
    };
    try {
      setLoading(true);
      const res = await resolveDisputes(data);
      setLoading(false);
      notification.info({
        message: "SUCCESS",
        description: res.data.message,
      });
      fetchDisputes(active_name, 1);
      setDrawerOpen(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  return (
    <Col style={{ marginLeft: "3em" }} md={24}>
      <PageHeader className="site-page-header px-0" title={`${type} Information`} />
      <Row>
        <Col span={12}>
          <DescriptionItem title={`${type} Name `} content={<span style={{ fontSize: "13px" }}>{businessname && businessname}</span>} />
        </Col>
        <Col span={12}>
          <DescriptionItem title={`${type} Phone `} content={<span style={{ fontSize: "13px" }}>{businessphone && businessphone}</span>} />
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <DescriptionItem title={`${type} Business email `} content={<span style={{ fontSize: "13px" }}>{businessemail && businessemail}</span>} />
        </Col>
      </Row>

      <PageHeader className="site-page-header px-0" title={`Transaction Information`} />

      <Row>
        <Col span={12}>
          <DescriptionItem title="Amount Transacted" content={<span style={{ fontSize: "13px" }}>N{amount && numeral(amount).format("0,0.00")}</span>} />
        </Col>
        <Col span={12}>
          <DescriptionItem title="Beneficiary Account Name" content={<span style={{ fontSize: "13px" }}>{beneficiary_account_name && beneficiary_account_name}</span>} />
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <DescriptionItem title="Beneficiary Account Number" content={<span style={{ fontSize: "13px" }}>{beneficiary_account_number && beneficiary_account_number}</span>} />
        </Col>
        <Col span={12}>
          <DescriptionItem title="Beneficiary Bank " content={<span style={{ fontSize: "13px" }}>{beneficiary_bank && beneficiary_bank}</span>} />
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <DescriptionItem title="Narative" content={<span style={{ fontSize: "13px" }}>{narrative && narrative}</span>} />
        </Col>
        <Col span={12}>
          <DescriptionItem title="Transaction Type" content={<span style={{ fontSize: "13px" }}>{Type && Type}</span>} />
        </Col>
      </Row>

      <PageHeader className="site-page-header px-0" title={`Dispute Information`} />

      <Row>
        <Col span={12}>
          <DescriptionItem title="Title" content={<span style={{ fontSize: "13px" }}>{title && title}</span>} />
        </Col>
        <Col span={10}>
          <DescriptionItem title="Remark" content={<span style={{ fontSize: "13px",padding:"10px" }}>{remark && remark}</span>} />
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <DescriptionItem title="Status" content={<span style={{ fontSize: "13px", color: "orange" }}>{status && status}</span>} />
        </Col>
        <Button loading={is_loading} type="primary" htmlType="button" onClick={onResolveClick}>
          Resolve Dispute
        </Button>
      </Row>

      <Row>
        <Col span={12}>
          <Button
            type="button"
            htmlType="button"
            onClick={() => {
              history.push(`/merchant/${merchant_id}`);
            }}
          >
            Click to view merchant details
          </Button>
        </Col>
      </Row>
    </Col>
  );
};

export const CashpointtDisputeDetails = ({ type, details, fetchDisputes, active_name, setDrawerOpen }) => {
  const [is_loading, setLoading] = useState(false);
  const {
    remark = "",
    title = "",
    status = "",
    _id = "",
    transaction: {
      wallet: {
        cashpoint: { contactfirstname = "", email = "", phone = "", _id: cashier_id = "" },
      },
      amount = 0,
      beneficiary_account_name = "",
      beneficiary_account_number = "",
      beneficiary_bank = "",
      narrative = "",
      type: Type = "",
    },
  } = details;

  const onResolveClick = async () => {
    let data = {
      type: type.toLowerCase(),
      dispute_id: _id,
      user_id: cashier_id,
    };
    try {
      setLoading(true);
      const res = await resolveDisputes(data);
      console.log(res);
      setLoading(false);
      notification.info({
        message: "SUCCESS",
        description: res.data.message,
      });
      fetchDisputes(active_name, 1);
      setDrawerOpen(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  return (
    <Col style={{ marginLeft: "3em" }} md={24}>
      <PageHeader className="site-page-header px-0" title={`${type} Information`} />
      <Row>
        <Col span={12}>
          <DescriptionItem title={`${type} Name `} content={<span style={{ fontSize: "13px" }}>{contactfirstname && contactfirstname}</span>} />
        </Col>
        <Col span={12}>
          <DescriptionItem title={`${type} Phone `} content={<span style={{ fontSize: "13px" }}>{phone && phone}</span>} />
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <DescriptionItem title={`${type} Business email `} content={<span style={{ fontSize: "13px" }}>{email && email}</span>} />
        </Col>
      </Row>

      <PageHeader className="site-page-header px-0" title={`Transaction Information`} />

      <Row>
        <Col span={12}>
          <DescriptionItem title="Amount Transacted" content={<span style={{ fontSize: "13px" }}>N{amount && numeral(amount).format("0,0.00")}</span>} />
        </Col>
        <Col span={12}>
          <DescriptionItem title="Beneficiary Account Name" content={<span style={{ fontSize: "13px" }}>{beneficiary_account_name && beneficiary_account_name}</span>} />
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <DescriptionItem title="Beneficiary Account Number" content={<span style={{ fontSize: "13px" }}>{beneficiary_account_number && beneficiary_account_number}</span>} />
        </Col>
        <Col span={12}>
          <DescriptionItem title="Beneficiary Bank " content={<span style={{ fontSize: "13px" }}>{beneficiary_bank && beneficiary_bank}</span>} />
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <DescriptionItem title="Narative" content={<span style={{ fontSize: "13px" }}>{narrative && narrative}</span>} />
        </Col>
        <Col span={12}>
          <DescriptionItem title="Transaction Type" content={<span style={{ fontSize: "13px" }}>{Type && Type}</span>} />
        </Col>
      </Row>

      <PageHeader className="site-page-header px-0" title={`Dispute Information`} />

      <Row>
        <Col span={12}>
          <DescriptionItem title="Title" content={<span style={{ fontSize: "13px" }}>{title && title}</span>} />
        </Col>
        <Col span={12}>
          <DescriptionItem title="Remark" content={<span style={{ fontSize: "13px" }}>{remark && remark}</span>} />
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <DescriptionItem title="Status" content={<span style={{ fontSize: "13px", color: "orange" }}>{status && status}</span>} />
        </Col>
        <Button loading={is_loading} type="primary" htmlType="button" onClick={onResolveClick}>
          Resolve Dispute
        </Button>
      </Row>
    </Col>
  );
};
