import {
  Button,
  Input,
  Modal,
  Dropdown,
  Menu,
  Space,
  DatePicker,
  Form,
  Image as AntImage,
  Radio,
  Divider,
  notification,
  Empty,
  Spin,
  List,
  Comment,
  Row,
  PageHeader,
  Col,
  Table,
  Tag,
  Switch,
  Collapse,
  Select,
  Tooltip,
  Upload,
  Drawer,
  Descriptions,
} from "antd";
import {
  CloseOutlined,
  ArrowLeftOutlined,
  LoadingOutlined,
  ExclamationCircleOutlined,
  DownOutlined,
  UploadOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";

import React, { useState, useCallback, useEffect, useContext } from "react";
import { useFeatureFlag } from "../../hooks/use_Feature_Flag";
import numeral from "numeral";
import store from "store";
import moment from "moment";

import "./Merchants.scss";
import "../../../assets/css/merchant_docs.css";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import NProgress from "nprogress";
import Sidebar from "../../../components/Sidebar/Sidebar";
import {
  ROLES,
  INDUSTRIES,
  cloudinary_video_url,
} from "../../../store/utilities";

import {
  createVerificationComment,
  rejectAddressProof,
  rejectIdCard,
  rejectPassport,
  investigateMerchant,
  unVerifyMerchant,
  certifyForUpgrade,
  saveMetaData,
  updateRestrictionInfomation,
  getMerchantInfo,
  getMerchantProofOfAddress,
  getVerificationComments,
  getMerchantIdCard,
  getMerchantInternationalPassport,
  getMerchantBvnInfo,
  verifyMerchant,
  getMerchantNinInfo,
  signOut,
  uploadAddressProof,
  uploadLiveVideo,
  uploadIntlPassport,
  uploadIDCard,
  exemptBVNOTP,
  updatePostNoCredit,
  updateMerchantInfo,
  updateExemptStatus,
  verifyBVNPhone,
  getMerchantTxns,
  getMerchantBusinesses,
  getMerchantCashpoints,
  updateCashier,
  deactivateMerchant,
  getMerchantBillings,
  generateMerchantTxnHistory,
  generateMerchantTxnHistoryCSV,
  searchOuboundTxn,
  searchInboundTxn,
  activateBank,
  getLiveVideo,
  rejectVideo,
  getAmmbanCertificate,
  rejectAmmban,
  ammbanUpload,
  getAggregator,
  addMember,
  getCAC,
  getOtherDoc,
  rejectCAC,
  rejectOtherDocument,
  sendLimitedUpgrade,
  sandboxLimitedUpgrade,
  trashAccountService,
  removetrashAccountService,
  updateMerchantPndFalse,
  getPasswordResetVideo,
  getLimitIncreaseVideo,
  reuploadRolesLiveVideo,
  reuploadRolesLiveImage,
} from "../../../services/http_service";

import { appContext } from "../../../store/appContext";

import {
  cloudinary_api_key,
  cloudinary_cloud_name,
  cloudinary_upload_preset,
  cloudinary_url,
} from "../../../store/utilities";
import { baseUrl } from "../../../services/urls";
import { changeImageExt, splitTitleFromOtherDocument } from "../../../helpers";
import Search from "antd/lib/input/Search";
import VideoDisplay from "../../VideoDisplay/VideoDisplay";
import { MediaNavigation } from "./Live Videos/live_video";
import { UserPndLog } from "./UserPndLog";
import { RolesVideos } from "./Live Videos/roles_videos";

export const DescriptionItem = ({ title, content }) => (
  <div
    style={{ alignItems: "baseline" }}
    className="site-description-item-profile-wrapper d-flex"
  >
    <p
      className="site-description-item-profile-p-label mr-1"
      style={{ fontSize: "12px", fontWeight: "bold" }}
    >
      {title}:
    </p>
    {content}
  </div>
);

let antIcon = <LoadingOutlined style={{ fontSize: 15 }} spin />;
const { confirm } = Modal;
let publicKey =
  "SXZhbkFsZXJnYXBJc05vdE9ubHlBV2l0dHlPbmVIYW5uYWhCYW5la3VJc1RoZVNlY29uZCNOdW4=";
let requestId = new Date().getTime();

let meta_columns = [
  {
    title: "Source",
    dataIndex: "source",
    width: 300,
    key: "source",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Value",
    dataIndex: "value",
    key: "value",
  },
];

let bvn_image_prefix = "data:image/jpeg;base64,";
let nin_image_prefix = "data:image/png;base64,";

const { RangePicker } = DatePicker;

function MerchantDocuments(props) {
  let location = useLocation();
  const { isFlagRequired } = useFeatureFlag("admin.loan");
  let logged_in_admin = {};

  let merchant_id = props.match.params.merchant_id;

  let context = useContext(appContext);
  let { globalFilter } = context;

  let [activetab, setActiveTab] = useState("id_card");
  let [is_loading, setLoading] = useState(false);
  let [target_loader, setTargetLoader] = useState("");

  let [rejection_ongoing, setRejectOnGoing] = useState(false);
  const [searching, setSearching] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  let [request_error, setRequestError] = useState("");
  let [radio_value, setRadio] = useState("front");

  let [user_inputs, setMetaDataInputs] = useState({
    meta_type: "",
    meta_source: "",
    meta_source_other: "",
    meta_data: "",
    investigate_above: 0,
    confirmed_limit: 0,
    unconfirmed_limit: 0,
    restriction_level: "",
    merchant_class: "",
    address: "",
    business_name: "",
    business_email: "",
    referral_code: "",
    business_phone: "",
    reject_message: "",
    cashier_email: "",
    cashier_phone: "",
    cashier_firstname: "",
    cashier_lastname: "",
    cashier_id: "",
    contactfirstname: "",
    contactlastname: "",
    industry: "",
    r_account_number: "",
    t_amount: 0,
    t_date: "",
    s_account_number: "",
    sessionid: "",
  });

  let [merchant_details, setMerchantDetails] = useState({});
  let [active_merchant, setActiveMerchant] = useState({
    wallet_id: "",
    bvnotptrials: 0,
    exemptbvnotp: false,
    postnodebit: false,
    postnocredit: false,
    verified: false,
    investigate: false,
    businessname: "",
  });
  let [is_comments_loading, setCommentsLoading] = useState(false);
  let [v_comments, setVComments] = useState([]);
  let [is_verification_ongoing, setVerificationOngoing] = useState(false);

  let [proofOfAddress, setProofOfAddress] = useState({});
  let [idCard, setIdCard] = useState({});
  let [internationalPassport, setInternationalPassport] = useState({});
  let [bvnInfo, setBvnInfo] = useState({});
  let [bvn_users, setBvnUsers] = useState([]);
  let [ninInfo, setNinInfo] = useState({});
  let [nin_users, setNinUsers] = useState([]);
  let [documents_fetched, setDocumentsFetched] = useState({
    bvn: false,
    nin: false,
    passport: false,
    id_card: false,
    proof_of_address: false,
    video: false,
    ammban: false,
    cac: false,
    otherDocs: false,
  });
  let [target_modal, setTargetModal] = useState({
    target: "",
    modal: "",
    drawer: "",
  });

  let [active_id_image, setActiveIdImage] = useState("");
  let [active_international_passport, setActiveInternationalPassport] =
    useState("");
  const [liveVideo, setLiveVideo] = useState({});
  const [ammban, setAmmban] = useState({});
  const [cac, setCAC] = useState({});
  const [otherDocs, setOtherDocs] = useState([]);
  const [otherDocID, setOtherDocID] = useState(0);
  const [passwordResetVideo, setPasswordResetVideo] = useState({
    videoUrl: "",
    question: "",
    type: "",
    request_time: "",
  });
  const [limitIncreaseVideo, setLimitIncreaseVideo] = useState({
    videoUrl: "",
    amount: null,
  });

  let [fileList, setFileList] = useState({
    passport: [],
    id_card: [],
    address_proof: [],
    video: [],
    ammban: [],
    cac: [],
    otherDocs: [],
    rolesVideo: [],
    rolesImage: [],
  });
  let [txn, setTxn] = useState({ outbound: {}, inbound: {} });

  let history = useHistory();
  let match = useRouteMatch();
  let [name, setName] = useState("Merchant Info");
  let dateFormat = "YYYY-MM-DD";
  let [_txn_type, setTxnType] = useState("outbound");

  let [merchant_txns, setMerchantTxns] = useState({
    txns: [],
    total_debit: 0,
    total_credit: 0,
  });
  let [merchant_billings, setMerchantBillings] = useState({ billings: [] });
  let [merchant_biz, setMerchantBiz] = useState([]);
  let [merchant_cashpoints, setMerchantCashPoints] = useState({
    cashpoints: [],
  });
  const [searchAccount, setSearchAccount] = useState(false);
  let txn_pagination = store.get("txn_pagination");
  if (!txn_pagination)
    store.set("txn_pagination", {
      filters: ["credit", "debit"],
      total: 0,
      page: 1,
      limit: 10,
      all_time: true,
      single_date: null,
      start_date: null,
      end_date: null,
    });

  let bvn_users_columns = [
    {
      title: "Name",
      dataIndex: "businessname",
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => {
            //history.push("/merchant/" + record._id)
            window.location = "/merchant/" + record._id;
          }}
        >
          {record.businessname}
        </Button>
      ),
      key: "businessname",
    },
    {
      title: "Email",
      dataIndex: "businessemail",
      key: "businessemail",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Industry",
      dataIndex: "industry",
      key: "industry",
    },
    {
      title: "Verified",
      dataIndex: "verified",
      render: (verified) => (
        <Tag color={verified ? "green" : "red"}>
          {verified ? "TRUE" : "FALSE"}
        </Tag>
      ),
      key: "verified",
    },
  ];

  let handleLogout = useCallback(() => {
    NProgress.inc();

    signOut()
      .then((res) => {
        localStorage.clear();
        sessionStorage.clear();
        history.push("/?return_url=" + location.pathname);
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        sessionStorage.clear();
        history.push("/?return_url=" + location.pathname);
      })
      .finally(() => {
        NProgress.done();
      });
  }, [history, location.pathname]);

  if (sessionStorage.getItem("dartpay_token")) {
    logged_in_admin = JSON.parse(
      window.atob(sessionStorage.getItem("dartpay_token").split(".")[1])
    );
  } else {
    handleLogout();
  }

  function confirmModal(target) {
    let text = "";

    switch (target) {
      case "investigate":
        text =
          "Are you sure you want to put this merchant under investigation?";
        break;
      case "downgrade":
        text = "Are you sure you want to put downgrade this merchant?";
        break;
      case "exemptbvnotp":
        text =
          "Are you sure you want to exempt this merchant from receiving BVN OTP?";
        break;
      case "postnocredit":
        text =
          "Are you sure you want to bar this merchant from perfoming transactions?";
        break;
      case "exempt_merchant":
        text = "Are you sure you want to update this merchant's exempt status?";
        break;
      case "deactivate_merchant":
        text = "Are you sure you want to update this merchant's active status?";
        break;
      case "sandboxLimitedUpgrade":
        text =
          "Are you sure you want to give merchant sandbox account a limited upgrade?";
        break;
      case "trashAccount":
        text = "Are you sure you want to move this account to trash?";
        break;
      case "removeAccountFromTrash":
        text = "Are you sure you want to remove this account from trash?";
        break;
      default:
        break;
    }

    confirm({
      title: text,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        switch (target) {
          case "investigate":
            handleInvestigateMerchant();
            break;
          case "downgrade":
            handleDowngradeMerchant();
            break;
          case "exemptbvnotp":
            handleExemptBVNOTP();
            break;
          case "postnocredit":
            handlePostNoCredit();
            break;
          case "exempt_merchant":
            handleUpdateExemptStatus();
            break;
          case "deactivate_merchant":
            deactivateHandler();
            break;
          case "sandboxLimitedUpgrade":
            sendsandboxLimitedUpgrade();
          // eslint-disable-next-line no-fallthrough
          case "trashAccount":
            trashAccount();
            break;
          case "removeAccountFromTrash":
            removeAccountFromTrash();
            break;
          default:
            break;
        }
      },
      onCancel() {},
    });
  }

  let fetchMerchantInfo = useCallback(
    (merchant_id) => {
      NProgress.inc();

      getMerchantInfo(merchant_id)
        .then((res) => {
          setMerchantDetails(res.data.merchant_info);
          setActiveMerchant({
            bvnotptrials: res.data.merchant_info.bvnotptrials,
            exemptbvnotp: res.data.merchant_info.exemptbvnotp,
            postnodebit: res.data.merchant_info.postnodebit,
            postnocredit: res.data.merchant_info.postnocredit,
            verified: res.data.merchant_info.verified,
            investigate: res.data.merchant_info.investigate,
            businessname: res.data.merchant_info.businessname,
            wallet_id: res.data.merchant_wallet_id,
          });

          NProgress.done();
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            (err.response.status === 401 || err.response.status === 403)
          )
            handleLogout();
        })
        .finally(() => {
          NProgress.done();
        });
    },
    [handleLogout]
  );

  let onRadioChange = (e) => {
    setRadio(e.target.value);

    if (activetab === "id_card" && Object.keys(idCard).length) {
      switch (e.target.value) {
        case "front":
          setActiveIdImage(idCard && idCard.front.image_url);
          break;
        case "back":
          setActiveIdImage(idCard && idCard.back.image_url);
          break;
        case "selfie":
          setActiveIdImage(idCard && idCard.selfie.image_url);
          break;
        default:
          setActiveIdImage(idCard && idCard.front.image_url);
          break;
      }
    } else if (
      activetab === "passport" &&
      Object.keys(internationalPassport).length
    ) {
      switch (e.target.value) {
        case "front":
          setActiveInternationalPassport(
            internationalPassport && internationalPassport.front.image_url
          );
          break;
        case "back":
          setActiveInternationalPassport(
            internationalPassport && internationalPassport.back.image_url
          );
          break;
        case "selfie":
          setActiveInternationalPassport(
            internationalPassport && internationalPassport.selfie.image_url
          );
          break;
        default:
          setActiveInternationalPassport(
            internationalPassport && internationalPassport.front.image_url
          );
          break;
      }
    }
  };

  let handleRadioInput = (e) => {
    setMetaDataInputs({
      amount: "",
      t_date: "",
      r_account_number: "",
      _date: "",
      s_account_number: "",
    });
    setTxnType(e.target.value);
  };

  let fetchMerchantBiz = async () => {
    setLoading(true);
    setTargetLoader("merchant_biz");

    try {
      let result = await getMerchantBusinesses(merchant_details.user);
      setMerchantBiz(result.data.data);

      setLoading(false);
      setTargetLoader("");

      setTargetModal({
        drawer: "merchant_biz",
      });
    } catch (err) {
      if (
        err &&
        err.response &&
        (err.response.status === 401 || err.response.status === 403)
      )
        handleLogout();
      notification.error({
        message: "ERROR",
        description: err.response
          ? err.response.data.message
          : "An error occured. Please try again.",
      });

      setLoading(false);
      setTargetLoader("");
    }
  };

  let fetchMerchantWalletTxns = async (data) => {
    setLoading(true);
    setTargetLoader("txns");

    if (!data) data = { ...store.get("txn_pagination") };

    try {
      let result = await getMerchantTxns(active_merchant.wallet_id, data, {
        amount: searchValue,
      });
      setMerchantTxns(result.data);

      store.set("txn_pagination", {
        total: result.data.total,
        page: result.data.page,
        limit: result.data.limit,
        filters: store.get("txn_pagination").filters,
        all_time: store.get("txn_pagination").all_time,
        single_date: store.get("txn_pagination").single_date,
        start_date: store.get("txn_pagination").start_date,
        end_date: store.get("txn_pagination").end_date,
      });

      setTargetModal({
        drawer: "merchant_txns",
      });

      setLoading(false);
      setTargetLoader("");
    } catch (err) {
      if (
        err &&
        err.response &&
        (err.response.status === 401 || err.response.status === 403)
      )
        handleLogout();
      notification.error({
        message: "ERROR",
        description: err.response
          ? err.response.data.message
          : "An error occured. Please try again.",
      });

      setLoading(false);
      setTargetLoader("");
    }
  };

  let fetchComments = useCallback(
    (merchant_id) => {
      setCommentsLoading(true);
      NProgress.inc();

      getVerificationComments(merchant_id)
        .then((res) => {
          setVComments(res.data);
          setCommentsLoading(false);
        })
        .catch((err) => {
          setCommentsLoading(false);
          if (
            err &&
            err.response &&
            (err.response.status === 401 || err.response.status === 403)
          )
            handleLogout();

          notification.error({
            message: "ERROR",
            description:
              err && err.response && err.response.data
                ? err.response.data.message
                : "User hasn't uploaded proof of address.",
          });
        })
        .finally(() => {
          setCommentsLoading(false);
          NProgress.done();
        });
    },
    [handleLogout]
  );

  let fetchMerchantDocuments = useCallback(
    async (merchant_id) => {
      let details = await getMerchantInfo(merchant_id);

      NProgress.inc();
      setDocumentsFetched({
        bvn: false,
        nin: false,
        passport: false,
        id_card: false,
        proof_of_address: false,
      });

      getMerchantProofOfAddress(merchant_id)
        .then((res) => {
          setProofOfAddress(res.data.proof_of_address);

          setDocumentsFetched((prev) => ({
            ...prev,
            proof_of_address: true,
          }));
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            (err.response.status === 401 || err.response.status === 403)
          )
            handleLogout();

          setDocumentsFetched((prev) => ({
            ...prev,
            proof_of_address: true,
          }));
        });
      //.finally(() => { NProgress.done(); });

      NProgress.inc();
      getMerchantIdCard(merchant_id)
        .then((res) => {
          setIdCard(res.data.ID_card);
          setActiveIdImage(
            res.data && res.data.ID_card && res.data.ID_card.front.image_url
          );

          setDocumentsFetched((prev) => ({
            ...prev,
            id_card: true,
          }));
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            (err.response.status === 401 || err.response.status === 403)
          )
            handleLogout();

          setDocumentsFetched((prev) => ({
            ...prev,
            id_card: true,
          }));
        });
      //.finally(() => { NProgress.done(); });

      NProgress.inc();
      getCAC(merchant_id)
        .then((res) => {
          const newImageURL = changeImageExt(res?.data?.cac, "jpg");

          // manipulate pdf to image
          setCAC(res.data.cac);
          // setCAC(newImageURL);
          setDocumentsFetched((prev) => ({
            ...prev,
            cac: true,
          }));
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            (err.response.status === 401 || err.response.status === 403)
          )
            handleLogout();
          setDocumentsFetched((prev) => ({
            ...prev,
            cac: true,
          }));
        });
      NProgress.inc();
      getOtherDoc(merchant_id)
        .then((res) => {
          setOtherDocs(res.data.other_documents);
          setDocumentsFetched((prev) => ({
            ...prev,
            otherDocs: true,
          }));
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            (err.response.status === 401 || err.response.status === 403)
          )
            handleLogout();
          setDocumentsFetched((prev) => ({
            ...prev,
            otherDocs: true,
          }));
        });

      NProgress.inc();
      getMerchantInternationalPassport(merchant_id)
        .then((res) => {
          setInternationalPassport(res.data.international_passport);
          setActiveInternationalPassport(
            res.data &&
              res.data.international_passport &&
              res.data.international_passport.front.image_url
          );

          setDocumentsFetched((prev) => ({
            ...prev,
            passport: true,
          }));
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            (err.response.status === 401 || err.response.status === 403)
          )
            handleLogout();

          setDocumentsFetched((prev) => ({
            ...prev,
            passport: true,
          }));
        });
      //.finally(() => { NProgress.done(); });

      NProgress.inc();
      getMerchantBvnInfo(merchant_id)
        .then((res) => {
          setBvnInfo(res.data.bvn_info);
          setBvnUsers(res.data.bvn_users_aggregate);

          setDocumentsFetched((prev) => ({
            ...prev,
            bvn: true,
          }));
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            (err.response.status === 401 || err.response.status === 403)
          )
            handleLogout();

          setDocumentsFetched((prev) => ({
            ...prev,
            bvn: true,
          }));
        });
      //.finally(() => { NProgress.done(); });

      NProgress.inc();
      getMerchantNinInfo(merchant_id)
        .then((res) => {
          setNinInfo(res.data.nin_info);
          setNinUsers(res.data.nin_users_aggregate);

          setDocumentsFetched((prev) => ({
            ...prev,
            nin: true,
          }));
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            (err.response.status === 401 || err.response.status === 403)
          )
            handleLogout();

          setDocumentsFetched((prev) => ({
            ...prev,
            nin: true,
          }));
        });
      //.finally(() => { NProgress.done(); });

      NProgress.inc();
      getLiveVideo(merchant_id)
        .then((res) => {
          setLiveVideo(res.data.liveliness_test);
          setDocumentsFetched((prev) => ({
            ...prev,
            video: true,
          }));
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            (err.response.status === 401 || err.response.status === 403)
          )
            handleLogout();
          setDocumentsFetched((prev) => ({
            ...prev,
            video: true,
          }));
        });

      NProgress.inc();
      getAmmbanCertificate(merchant_id)
        .then((res) => {
          setAmmban(res.data.ammban_certificate);
          setDocumentsFetched((prev) => ({
            ...prev,
            ammban: true,
          }));
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            (err.response.status === 401 || err.response.status === 403)
          )
            handleLogout();
          setDocumentsFetched((prev) => ({
            ...prev,
            ammban: true,
          }));
        });

      // NProgress.inc();
      // try {
      //   let passwordVideo = await getPasswordResetVideo(merchant_id);
      //   if (passwordVideo?.data?.password_log) {
      //     setPasswordResetVideo((prev) => ({
      //       ...prev,
      //       videoUrl: passwordVideo?.data?.password_log?.video_url,
      //       question: passwordVideo?.data?.password_log?.question,
      //       type: passwordVideo?.data?.password_log?.type,
      //       request_time: passwordVideo?.data?.password_log?.last_change_date,
      //     }));
      //   }
      // } catch (err) {
      //   if (details?.data?.merchant_info?.has_custom_card) {
      //     return;
      //   }
      //   notification.error({
      //     message: "ERROR",
      //     description: err.response
      //       ? err.response.data.message
      //       : "An error occured. Please try again.",
      //   });
      // }
      NProgress.done();

      // NProgress.inc();
      // try {
      //   let limitIncreaseVideo = await getLimitIncreaseVideo(merchant_id);
      //   console.log("HEYYY SCISSORS LIMIT <<>>><<>>> ", limitIncreaseVideo);
      //   if (limitIncreaseVideo?.data?.limit_request?.videoUrl) {
      //     setLimitIncreaseVideo((prev) => ({
      //       ...prev,
      //       videoUrl: limitIncreaseVideo?.data?.limit_request?.videoUrl,
      //       amount: limitIncreaseVideo?.data?.limit_request?.amount,
      //     }));
      //   }
      // } catch (err) {
      //   if (details?.data?.merchant_info?.has_custom_card) {
      //     return;
      //   }
      //   notification.error({
      //     message: "ERROR",
      //     description: err.response
      //       ? err.response.data.message
      //       : "An error occured. Please try again.",
      //   });
      // }
    },

    [handleLogout]
  );

  let handleMetaDataInput = (e) => {
    let { name, value } = e.target;

    setMetaDataInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  let onSelectChange = (value) => {
    setMetaDataInputs((prev) => ({
      ...prev,
      meta_type: value,
    }));
  };

  let submitMetaData = () => {
    setLoading(true);
    setTargetLoader("meta_data");

    let source =
      user_inputs.meta_source === "Others"
        ? user_inputs.meta_source_other
        : user_inputs.meta_source;

    let data = {
      meta_type: user_inputs.meta_type,
      meta_data: user_inputs.meta_data,
      meta_source: source,
    };

    saveMetaData(merchant_id, data)
      .then((res) => {
        fetchMerchantInfo(merchant_id);

        notification.info({
          message: "SUCCESS",
          description: res.data.message,
        });

        setMetaDataInputs({
          meta_data: "",
          meta_source: "",
          meta_type: "",
          meta_source_other: "",
        });
        document.getElementsByName("meta_source")[0].value = "";
        document.getElementsByName("meta_type")[0].value = "";
        document.getElementsByName("meta_data")[0].value = "";
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          (err.response.status === 401 || err.response.status === 403)
        )
          handleLogout();

        notification.error({
          message: "ERROR",
          description: err.response
            ? err.response.data.message
            : "An error occured. Please try again.",
        });
      })
      .finally(() => {
        setLoading(false);
        setTargetLoader("");
      });
  };

  let handleRejectAddressProof = () => {
    setRejectOnGoing(true);

    rejectAddressProof({
      message: user_inputs.reject_message,
      user_id: proofOfAddress.user,
      doc_id: proofOfAddress._id,
    })
      .then((res) => {
        setActiveMerchant(res.data.data);

        setRejectOnGoing(false);
        setProofOfAddress({});

        notification.info({
          message: "SUCCESS",
          description: res.data.message,
        });

        setMetaDataInputs({
          reject_message: "",
        });

        setTargetModal("");

        document.getElementById("stubborn_input1").value = "";
        document.getElementById("stubborn_input1").defaultValue = "";

        fetchComments(merchant_id);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          (err.response.status === 401 || err.response.status === 403)
        )
          handleLogout();

        setRejectOnGoing(false);
        notification.error({
          message: "ERROR",
          description: err.response
            ? err.response.data.message
            : "An error occured. Please try again.",
        });
      });
  };

  let handleRejectPassport = () => {
    setRejectOnGoing(true);

    rejectPassport({
      message: user_inputs.reject_message,
      user_id: internationalPassport.user,
      doc_id: internationalPassport._id,
    })
      .then((res) => {
        setActiveMerchant(res.data.data);

        setRejectOnGoing(false);
        setInternationalPassport({});
        setActiveInternationalPassport("");

        notification.info({
          message: "SUCCESS",
          description: res.data.message,
        });

        setMetaDataInputs({
          reject_message: "",
        });

        setTargetModal("");

        document.getElementById("stubborn_input1").value = "";
        document.getElementById("stubborn_input1").defaultValue = "";

        fetchComments(merchant_id);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          (err.response.status === 401 || err.response.status === 403)
        )
          handleLogout();

        setRejectOnGoing(false);
        notification.error({
          message: "ERROR",
          description: err.response
            ? err.response.data.message
            : "An error occured. Please try again.",
        });
      });
  };

  let handleRejectIdCard = () => {
    setRejectOnGoing(true);

    rejectIdCard({
      message: user_inputs.reject_message,
      user_id: idCard.user,
      doc_id: idCard._id,
    })
      .then((res) => {
        setActiveMerchant(res.data.data);

        setRejectOnGoing(false);
        setIdCard({});
        setActiveIdImage("");

        notification.info({
          message: "SUCCESS",
          description: res.data.message,
        });

        setMetaDataInputs({
          reject_message: "",
        });

        setTargetModal("");

        document.getElementById("stubborn_input1").value = "";
        document.getElementById("stubborn_input1").defaultValue = "";

        fetchComments(merchant_id);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          (err.response.status === 401 || err.response.status === 403)
        )
          handleLogout();

        setRejectOnGoing(false);
        notification.error({
          message: "ERROR",
          description: err.response
            ? err.response.data.message
            : "An error occured. Please try again.",
        });
      });
  };

  let handleRejectLiveVideo = () => {
    setRejectOnGoing(true);

    rejectVideo({
      message: user_inputs.reject_message,
      user_id: liveVideo.user,
      doc_id: liveVideo._id,
    })
      .then((res) => {
        setActiveMerchant(res.data.data);
        setRejectOnGoing(false);
        setLiveVideo({});

        notification.info({
          message: "SUCCESS",
          description: res.data.message,
        });

        setMetaDataInputs({
          reject_message: "",
        });

        setTargetModal("");

        document.getElementById("stubborn_input1").value = "";
        document.getElementById("stubborn_input1").defaultValue = "";

        fetchComments(merchant_id);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          (err.response.status === 401 || err.response.status === 403)
        )
          handleLogout();

        setRejectOnGoing(false);
        notification.error({
          message: "ERROR",
          description: err.response
            ? err.response.data.message
            : "An error occured. Please try again.",
        });
      });
  };

  let handleRejectAmmban = () => {
    setRejectOnGoing(true);

    rejectAmmban({
      message: user_inputs.reject_message,
      user_id: ammban.user,
      doc_id: ammban._id,
    })
      .then((res) => {
        setActiveMerchant(res.data.data);
        setRejectOnGoing(false);
        setAmmban({});

        notification.info({
          message: "SUCCESS",
          description: res.data.message,
        });

        setMetaDataInputs({
          reject_message: "",
        });

        setTargetModal("");

        document.getElementById("stubborn_input1").value = "";
        document.getElementById("stubborn_input1").defaultValue = "";

        fetchComments(merchant_id);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          (err.response.status === 401 || err.response.status === 403)
        )
          handleLogout();

        setRejectOnGoing(false);
        notification.error({
          message: "ERROR",
          description: err.response
            ? err.response.data.message
            : "An error occured. Please try again.",
        });
      });
  };

  let handleRejectCAC = () => {
    setRejectOnGoing(true);

    rejectCAC({
      message: user_inputs.reject_message,
      user_id: cac.user,
      doc_id: cac._id,
    })
      .then((res) => {
        setActiveMerchant(res.data.data);
        setRejectOnGoing(false);
        setCAC({});

        notification.info({
          message: "SUCCESS",
          description: res.data.message,
        });

        setMetaDataInputs({
          reject_message: "",
        });

        setTargetModal("");

        document.getElementById("stubborn_input1").value = "";
        document.getElementById("stubborn_input1").defaultValue = "";

        fetchComments(merchant_id);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          (err.response.status === 401 || err.response.status === 403)
        )
          handleLogout();

        setRejectOnGoing(false);
        notification.error({
          message: "ERROR",
          description: err.response
            ? err.response.data.message
            : "An error occured. Please try again.",
        });
      });
  };

  let handleRejectOtherDoc = () => {
    setRejectOnGoing(true);

    rejectOtherDocument({
      message: user_inputs.reject_message,
      user_id: otherDocs[otherDocID]?.user,
      doc_id: otherDocs[otherDocID]?._id,
      suffix: splitTitleFromOtherDocument(
        otherDocs[otherDocID].complience_type
      ),
    })
      .then((res) => {
        setActiveMerchant(res.data.data);
        setRejectOnGoing(false);
        setOtherDocs(otherDocs.filter((_, idx) => idx !== otherDocID));

        notification.info({
          message: "SUCCESS",
          description: res.data.message,
        });

        setMetaDataInputs({
          reject_message: "",
        });

        setTargetModal("");

        document.getElementById("stubborn_input1").value = "";
        document.getElementById("stubborn_input1").defaultValue = "";

        fetchComments(merchant_id);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          (err.response.status === 401 || err.response.status === 403)
        )
          handleLogout();

        setRejectOnGoing(false);
        notification.error({
          message: "ERROR",
          description: err.response
            ? err.response.data.message
            : "An error occured. Please try again.",
        });
      });
  };

  let handleInvestigateMerchant = () => {
    setLoading(true);
    setTargetLoader("investigate");

    investigateMerchant(merchant_id)
      .then((res) => {
        setActiveMerchant(res.data.data);

        notification.info({
          message: "SUCCESS",
          description: res.data.message,
        });

        fetchComments(merchant_id);
        fetchMerchantInfo(merchant_id);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          (err.response.status === 401 || err.response.status === 403)
        )
          handleLogout();

        setRejectOnGoing(false);
        notification.error({
          message: "ERROR",
          description: err.response
            ? err.response.data.message
            : "An error occured. Please try again.",
        });
      })
      .finally(() => {
        setLoading(false);
        setTargetLoader("");
      });
  };

  let handleExemptBVNOTP = () => {
    setLoading(true);
    setTargetLoader("exemptbvnotp");

    exemptBVNOTP(merchant_details.user)
      .then((res) => {
        notification.info({
          message: "SUCCESS",
          description: res.data.message,
        });

        fetchComments(merchant_id);
        fetchMerchantInfo(merchant_id);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          (err.response.status === 401 || err.response.status === 403)
        )
          handleLogout();

        notification.error({
          message: "ERROR",
          description: err.response
            ? err.response.data.message
            : "An error occured. Please try again.",
        });
      })
      .finally(() => {
        setLoading(false);
        setTargetLoader("");
      });
  };

  let handleDowngradeMerchant = () => {
    setLoading(true);
    setTargetLoader("unverify");

    unVerifyMerchant(merchant_id)
      .then((res) => {
        setActiveMerchant(res.data.data);

        notification.info({
          message: "SUCCESS",
          description: res.data.message,
        });

        fetchComments(merchant_id);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          (err.response.status === 401 || err.response.status === 403)
        )
          handleLogout();

        notification.error({
          message: "ERROR",
          description: err.response
            ? err.response.data.message
            : "An error occured. Please try again.",
        });
      })
      .finally(() => {
        setLoading(false);
        setTargetLoader("");
      });
  };

  let handlePostNoCredit = async () => {
    setLoading(true);
    setTargetLoader("postnocredit");

    try {
      let result = await updatePostNoCredit(merchant_id);
      notification.info({
        message: "SUCCESS",
        description: result.data.message,
      });

      setActiveMerchant(result.data.data);
      fetchComments(merchant_id);

      setLoading(true);
      setTargetLoader("");
    } catch (err) {
      setLoading(true);
      setTargetLoader("");

      if (
        err &&
        err.response &&
        (err.response.status === 401 || err.response.status === 403)
      )
        handleLogout();
    }
  };

  let handleCertifyUpgrade = () => {
    setLoading(true);
    setTargetLoader("certify");

    certifyForUpgrade(merchant_id)
      .then((res) => {
        notification.info({
          message: "SUCCESS",
          description: res.data.message,
        });

        fetchComments(merchant_id);
        fetchMerchantInfo(merchant_id);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          (err.response.status === 401 || err.response.status === 403)
        )
          handleLogout();

        notification.error({
          message: "ERROR",
          description: err.response
            ? err.response.data.message
            : "An error occured. Please try again.",
        });
      })
      .finally(() => {
        setLoading(false);
        setTargetLoader("");
      });
  };

  let handleUpdateExemptStatus = async () => {
    setLoading(true);
    setTargetLoader("exempt_merchant");

    try {
      let res = await updateExemptStatus(merchant_id);
      //setActiveMerchant(result.data.data);
      fetchMerchantInfo(merchant_id);
      fetchComments(merchant_id);

      notification.info({
        message: "SUCCESS",
        description: res.data.message,
      });

      setLoading(true);
      setTargetLoader("");
    } catch (err) {
      if (
        err &&
        err.response &&
        (err.response.status === 401 || err.response.status === 403)
      )
        handleLogout();

      notification.error({
        message: "ERROR",
        description: err.response
          ? err.response.data.message
          : "An error occured. Please try again.",
      });

      setLoading(true);
      setTargetLoader("");
    }
  };

  let handleVerifyBVNPhone = async () => {
    setLoading(true);
    setTargetLoader("verify_phone");

    try {
      let res = await verifyBVNPhone(merchant_details.user);

      notification.info({
        message: "SUCCESS",
        description: res.data.message,
      });

      fetchComments(merchant_id);
      fetchMerchantInfo(merchant_id);

      setLoading(true);
      setTargetLoader("");
    } catch (err) {
      if (
        err &&
        err.response &&
        (err.response.status === 401 || err.response.status === 403)
      )
        handleLogout();

      notification.error({
        message: "ERROR",
        description: err.response
          ? err.response.data.message
          : "An error occured. Please try again.",
      });

      setLoading(true);
      setTargetLoader("");
    }
  };

  let updateRestrictionInfo = useCallback(() => {
    setLoading(true);
    setTargetLoader("restriction");

    let data = {
      unconfirmedlimit: user_inputs.unconfirmed_limit,
      confirmedlimit: user_inputs.confirmed_limit,
      investigateabove: user_inputs.investigate_above,
      restrict: user_inputs.restriction_level,
    };

    if (parseFloat(user_inputs.confirmed_limit) > 10000000) {
      notification.error({
        message: "ERROR",
        description: "You have exceeded the limit of ₦10,000,000.00",
      });
      setLoading(false);
      setTargetLoader("");
      return;
    }

    updateRestrictionInfomation(merchant_id, data)
      .then((res) => {
        fetchMerchantInfo(merchant_id);

        notification.info({
          message: "SUCCESS",
          description: res.data.message,
        });
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          (err.response.status === 401 || err.response.status === 403)
        )
          handleLogout();

        notification.error({
          message: "ERROR",
          description: err.response
            ? err.response.data.message
            : "An error occured. Please try again.",
        });
      })
      .finally(() => {
        setLoading(false);
        setTargetLoader("");
      });
  }, [
    fetchMerchantInfo,
    handleLogout,
    merchant_id,
    user_inputs.confirmed_limit,
    user_inputs.investigate_above,
    user_inputs.restriction_level,
    user_inputs.unconfirmed_limit,
  ]);

  let updateMerchantInfoHandler = async () => {
    setLoading(true);
    setTargetLoader("updatemerchantinfo");

    let data = {
      classification: user_inputs.merchant_class,
      address: user_inputs.address,
      businessname: user_inputs.business_name,
      businessemail: user_inputs.business_email,
      referralcode: user_inputs.referral_code,
      businessphone: user_inputs.business_phone,
      contactfirstname: user_inputs.contactfirstname,
      contactlastname: user_inputs.contactlastname,
      industry: user_inputs.industry,
      wallet_id: active_merchant.wallet_id,
    };

    try {
      let result = await updateMerchantInfo(merchant_id, data);
      fetchMerchantInfo(merchant_id);

      notification.info({
        message: "SUCCESS",
        description: result.data.message,
      });

      setLoading(false);
      setTargetLoader("");
    } catch (err) {
      setLoading(false);
      setTargetLoader("");
      notification.error({
        message: "ERROR",
        description: err.response
          ? err.response.data.message
          : "An error occured. Please try again.",
      });

      if (
        err &&
        err.response &&
        (err.response.status === 401 || err.response.status === 403)
      )
        handleLogout();
    }
  };

  let fetchCashpoints = async (page) => {
    setTargetLoader("merchant_cashpoints");

    try {
      let res = await getMerchantCashpoints({
        ...globalFilter,
        page,
        merchant: merchant_id,
      });
      setMerchantCashPoints(res.data);

      setTargetLoader("");

      setTargetModal({
        drawer: "merchant_cashpoints",
      });
    } catch (err) {
      setTargetLoader("");

      if (
        err &&
        err.response &&
        (err.response.status === 401 || err.response.status === 403)
      )
        handleLogout();
    }
  };

  let fetchAirtimeBillings = async (data) => {
    setTargetLoader("merchant_billings");
    if (!data) data = { ...store.get("txn_pagination") };

    try {
      let result = await getMerchantBillings(merchant_id, data);
      setMerchantBillings(result.data);

      store.set("txn_pagination", {
        total: result.data.total,
        page: result.data.page,
        limit: result.data.limit,
        all_time: store.get("txn_pagination").all_time,
        single_date: store.get("txn_pagination").single_date,
        start_date: store.get("txn_pagination").start_date,
        end_date: store.get("txn_pagination").end_date,
      });

      setTargetLoader("");
      setTargetModal({
        drawer: "merchant_billings",
      });
    } catch (err) {
      setTargetLoader("");

      if (
        err &&
        err.response &&
        (err.response.status === 401 || err.response.status === 403)
      )
        handleLogout();
    }
  };

  let handleRestrictInfoPopulate = (event) => {
    if (Number(event) === 7 && merchant_details.wallet_info) {
      setMetaDataInputs((e) => ({
        unconfirmed_limit: merchant_details.wallet_info.unconfirmedlimit,
        confirmed_limit: merchant_details.wallet_info.confirmedlimit,
        investigate_above: merchant_details.wallet_info.investigateabove,
        restriction_level: merchant_details.wallet_info.restrict,
      }));
    }
  };

  let handleMerchantInfoPopulate = (event) => {
    if (Number(event) === 7) {
      setMetaDataInputs((e) => ({
        merchant_class: merchant_details.classification,
        address: merchant_details.address,
        business_name: merchant_details.businessname,
        business_email: merchant_details.businessemail,
        business_phone: merchant_details.businessphone,
        referral_code: merchant_details.referralcode,
        contactfirstname: merchant_details.contactfirstname,
        contactlastname: merchant_details.contactlastname,
        industry: merchant_details.industry,
      }));
    }
  };

  let verifyMerchantHandler = (id) => {
    setVerificationOngoing(true);

    verifyMerchant(id)
      .then((res) => {
        setVerificationOngoing(false);
        setActiveMerchant(res.data.data);

        notification.info({
          message: "SUCCESS",
          description: res.data.message,
        });
      })
      .catch((err) => {
        setVerificationOngoing(false);
        if (
          err &&
          err.response &&
          (err.response.status === 401 || err.response.status === 403)
        )
          handleLogout();
      });
  };

  let submitIntlPassport = async () => {
    setLoading(true);
    setTargetLoader("passport_upload");

    let front = new FormData();
    front.append("file", fileList.passport[0].originFileObj);
    front.append("cloud_name", cloudinary_cloud_name);
    front.append("upload_preset", cloudinary_upload_preset);
    front.append("api_key", cloudinary_api_key);
    front.append("folder", "PersonalIdentificationDocument");

    let back = new FormData();
    back.append("file", fileList.passport[1].originFileObj);
    back.append("cloud_name", cloudinary_cloud_name);
    back.append("upload_preset", cloudinary_upload_preset);
    back.append("api_key", cloudinary_api_key);
    back.append("folder", "PersonalIdentificationDocument");

    let selfie = new FormData();
    selfie.append("file", fileList.passport[2].originFileObj);
    selfie.append("cloud_name", cloudinary_cloud_name);
    selfie.append("upload_preset", cloudinary_upload_preset);
    selfie.append("api_key", cloudinary_api_key);
    selfie.append("folder", "PersonalIdentificationDocument");

    try {
      let [res1, res2, res3] = await Promise.all([
        fetch(cloudinary_url, { method: "POST", body: front }),
        fetch(cloudinary_url, { method: "POST", body: back }),
        fetch(cloudinary_url, { method: "POST", body: selfie }),
      ]);

      let front_image = await res1.json();
      let back_image = await res2.json();
      let selfie_image = await res3.json();

      let result = await uploadIntlPassport(
        {
          front_image_url: front_image.secure_url,
          front_imagebase64: fileList.passport[0].thumbUrl.replace(
            /^data:image\/[a-z]+;base64,/,
            ""
          ),

          back_image_url: back_image.secure_url,
          back_imagebase64: fileList.passport[1].thumbUrl.replace(
            /^data:image\/[a-z]+;base64,/,
            ""
          ),

          selfie_image_url: selfie_image.secure_url,
          selfie_imagebase64: fileList.passport[2].thumbUrl.replace(
            /^data:image\/[a-z]+;base64,/,
            ""
          ),
        },
        merchant_details.user
      );

      notification.info({
        message: "SUCCESS",
        description: result.data.message,
      });

      setFileList({ passport: [], id_card: [], address_proof: [] });

      fetchComments(merchant_id);
      setTimeout(() => {
        setTargetModal({ target: "", drawer: "", modal: "" });
      }, 2000);

      setDocumentsFetched({ ...documents_fetched, passport: false });

      let res = await getMerchantInternationalPassport(merchant_id);

      setInternationalPassport(res.data.international_passport);
      setActiveInternationalPassport(
        res.data &&
          res.data.international_passport &&
          res.data.international_passport.front.image_url
      );

      setDocumentsFetched((prev) => ({
        ...prev,
        passport: true,
      }));

      setLoading(false);
      setTargetLoader("");
    } catch (error) {
      notification.error({
        message: "ERROR",
        description: error.response
          ? error.response.data.message
          : "An error occured. Please try again.",
      });

      setLoading(false);
      setTargetLoader("");
    }
  };

  let submitIdCard = async () => {
    setLoading(true);
    setTargetLoader("id_upload");

    let front = new FormData();
    front.append("file", fileList.id_card[0].originFileObj);
    front.append("cloud_name", cloudinary_cloud_name);
    front.append("upload_preset", cloudinary_upload_preset);
    front.append("api_key", cloudinary_api_key);
    front.append("folder", "PersonalIdentificationDocument");

    let back = new FormData();
    back.append("file", fileList.id_card[1].originFileObj);
    back.append("cloud_name", cloudinary_cloud_name);
    back.append("upload_preset", cloudinary_upload_preset);
    back.append("api_key", cloudinary_api_key);
    back.append("folder", "PersonalIdentificationDocument");

    let selfie = new FormData();
    selfie.append("file", fileList.id_card[2].originFileObj);
    selfie.append("cloud_name", cloudinary_cloud_name);
    selfie.append("upload_preset", cloudinary_upload_preset);
    selfie.append("api_key", cloudinary_api_key);
    selfie.append("folder", "PersonalIdentificationDocument");

    try {
      let [res1, res2, res3] = await Promise.all([
        fetch(cloudinary_url, { method: "POST", body: front }),
        fetch(cloudinary_url, { method: "POST", body: back }),
        fetch(cloudinary_url, { method: "POST", body: selfie }),
      ]);

      let front_image = await res1.json();
      let back_image = await res2.json();
      let selfie_image = await res3.json();

      let result = await uploadIDCard(
        {
          front_image_url: front_image.secure_url,
          front_imagebase64: fileList.id_card[0].thumbUrl.replace(
            /^data:image\/[a-z]+;base64,/,
            ""
          ),

          back_image_url: back_image.secure_url,
          back_imagebase64: fileList.id_card[1].thumbUrl.replace(
            /^data:image\/[a-z]+;base64,/,
            ""
          ),

          selfie_image_url: selfie_image.secure_url,
          selfie_imagebase64: fileList.id_card[2].thumbUrl.replace(
            /^data:image\/[a-z]+;base64,/,
            ""
          ),
        },
        merchant_details.user
      );

      notification.info({
        message: "SUCCESS",
        description: result.data.message,
      });

      setFileList({ passport: [], id_card: [], address_proof: [] });

      fetchComments(merchant_id);
      setTimeout(() => {
        setTargetModal({ target: "", drawer: "", modal: "" });
      }, 2000);
      setDocumentsFetched({ ...documents_fetched, id_card: false });

      let res = await getMerchantIdCard(merchant_id);

      setIdCard(res.data.ID_card);
      setActiveIdImage(
        res.data && res.data.ID_card && res.data.ID_card.front.image_url
      );

      setDocumentsFetched((prev) => ({
        ...prev,
        id_card: true,
      }));

      setLoading(false);
      setTargetLoader("");
    } catch (error) {
      notification.error({
        message: "ERROR",
        description: error.response
          ? error.response.data.message
          : "An error occured. Please try again.",
      });
    }
  };

  let submitAddressProof = async () => {
    setLoading(true);
    setTargetLoader("address_proof_upload");

    let front = new FormData();
    front.append("file", fileList.address_proof[0].originFileObj);
    front.append("cloud_name", cloudinary_cloud_name);
    front.append("upload_preset", cloudinary_upload_preset);
    front.append("api_key", cloudinary_api_key);
    front.append("folder", "ProofOfAddress");

    try {
      let [res1] = await Promise.all([
        fetch(cloudinary_url, { method: "POST", body: front }),
      ]);

      let front_image = await res1.json();

      let result = await uploadAddressProof(
        {
          image_url: front_image.secure_url,
          imagebase64: fileList.address_proof[0].thumbUrl.replace(
            /^data:image\/[a-z]+;base64,/,
            ""
          ),
        },
        merchant_details.user
      );

      notification.info({
        message: "SUCCESS",
        description: result.data.message,
      });

      setFileList({ passport: [], id_card: [], address_proof: [] });

      fetchComments(merchant_id);
      setTimeout(() => {
        setTargetModal({ target: "", drawer: "", modal: "" });
      }, 2000);

      setDocumentsFetched({ ...documents_fetched, proof_of_address: false });

      let res = await getMerchantProofOfAddress(merchant_id);

      setProofOfAddress(res.data.proof_of_address);
      setDocumentsFetched((prev) => ({
        ...prev,
        proof_of_address: true,
      }));

      setLoading(false);
      setTargetLoader("");
    } catch (error) {
      notification.error({
        message: "ERROR",
        description: error.response
          ? error.response.data.message
          : "An error occured. Please try again.",
      });
      setLoading(false);
      setTargetLoader("");
    }
  };

  let submitVideo = async () => {
    setLoading(true);
    setTargetLoader("video_upload");

    let video = new FormData();
    video.append("file", fileList.video[0].originFileObj);
    video.append("cloud_name", cloudinary_cloud_name);
    video.append("upload_preset", cloudinary_upload_preset);
    video.append("api_key", cloudinary_api_key);
    // video.append("resource_type", "video");
    video.append("folder", "sample");

    try {
      let res = await fetch(cloudinary_video_url, {
        method: "POST",
        body: video,
      });

      let cloud_video = await res.json();

      let obj = {
        video_url: cloud_video.secure_url,
        question: merchant_details.liveliness_question,
      };

      let result = await uploadLiveVideo(obj, merchant_details.user);

      notification.info({
        message: "SUCCESS",
        description: result.data.message,
      });

      clearUploadData();

      fetchComments(merchant_id);
      setTimeout(() => {
        setTargetModal({ target: "", drawer: "", modal: "" });
      }, 2000);

      setDocumentsFetched({ ...documents_fetched, video: false });

      let response = await getLiveVideo(merchant_id);

      setLiveVideo(response.data.liveliness_test);
      setDocumentsFetched((prev) => ({
        ...prev,
        video: true,
      }));

      setLoading(false);
      setTargetLoader("");
    } catch (error) {
      notification.error({
        message: "ERROR",
        description: error.response
          ? error.response.data.message
          : "An error occured. Please try again.",
      });
      setLoading(false);
      setTargetLoader("");
    }
  };

  // roles video
  let submitRolesVideo = async () => {
    setLoading(true);
    setTargetLoader("video_upload");

    let video = new FormData();
    video.append("file", fileList?.rolesVideo[0].originFileObj);
    video.append("cloud_name", cloudinary_cloud_name);
    video.append("upload_preset", cloudinary_upload_preset);
    video.append("api_key", cloudinary_api_key);
    // video.append("resource_type", "video");
    video.append("folder", "Roles Video");

    try {
      let res = await fetch(cloudinary_video_url, {
        method: "POST",
        body: video,
      });

      let cloud_video = await res.json();

      let info = {
        video_url: cloud_video.secure_url,
      };

      // let info = {
      //   video_url: "approved",
      // };

      let result = await reuploadRolesLiveVideo(info, merchant_details.user);

      notification.info({
        message: "SUCCESS",
        description: result.data.message,
      });

      clearUploadData();

      fetchComments(merchant_id);
      setTimeout(() => {
        setTargetModal({ target: "", drawer: "", modal: "" });
      }, 2000);

      // setDocumentsFetched({ ...documents_fetched, video: false });

      // let response = await getLiveVideo(merchant_id);

      // setLiveVideo(response.data.liveliness_test);
      // setDocumentsFetched((prev) => ({
      //   ...prev,
      //   video: true,
      // }));

      setLoading(false);
      setTargetLoader("");
    } catch (error) {
      // console.log(error?.response, " error here ");
      notification.error({
        message: "ERROR",
        description: error.response
          ? error.response.data.error
          : "An error occured. Please try again.",
      });
      setLoading(false);
      setTargetLoader("");
    }
  };

  //roles image
  let submitRolesImage = async () => {
    setLoading(true);
    setTargetLoader("roles_image");

    let doc = new FormData();
    doc.append("file", fileList.rolesImage[0].originFileObj);
    doc.append("cloud_name", cloudinary_cloud_name);
    doc.append("upload_preset", cloudinary_upload_preset);
    doc.append("api_key", cloudinary_api_key);
    doc.append("folder", "RolesImage");

    try {
      let [res1] = await Promise.all([
        fetch(cloudinary_url, { method: "POST", body: doc }),
      ]);

      let doc_image = await res1.json();

      let result = await reuploadRolesLiveImage(
        { otheruser_image_url: doc_image.secure_url },
        merchant_details.user
      );

      notification.info({
        message: "SUCCESS",
        description: result.data.message,
      });

      clearUploadData();

      fetchComments(merchant_id);
      setTimeout(() => {
        setTargetModal({ target: "", drawer: "", modal: "" });
      }, 2000);

      setDocumentsFetched({ ...documents_fetched, ammban: false });

      setLoading(false);
      setTargetLoader("");
    } catch (error) {
      notification.error({
        message: "ERROR",
        description: error.response
          ? error.response.data?.error
          : "An error occured. Please try again.",
      });
      console.log(error);
      setLoading(false);
      setTargetLoader("");
    }
  };

  let submitAmmban = async () => {
    setLoading(true);
    setTargetLoader("ammban_upload");

    let doc = new FormData();
    doc.append("file", fileList.ammban[0].originFileObj);
    doc.append("cloud_name", cloudinary_cloud_name);
    doc.append("upload_preset", cloudinary_upload_preset);
    doc.append("api_key", cloudinary_api_key);
    doc.append("folder", "AmmBan");

    try {
      let [res1] = await Promise.all([
        fetch(cloudinary_url, { method: "POST", body: doc }),
      ]);

      let doc_image = await res1.json();

      let result = await ammbanUpload(
        { image_url: doc_image.secure_url },
        merchant_details.user
      );

      notification.info({
        message: "SUCCESS",
        description: result.data.message,
      });

      setFileList({ passport: [], id_card: [], address_proof: [] });

      fetchComments(merchant_id);
      setTimeout(() => {
        setTargetModal({ target: "", drawer: "", modal: "" });
      }, 2000);

      setDocumentsFetched({ ...documents_fetched, ammban: false });

      let res = await getAmmbanCertificate(merchant_id);

      setAmmban(res.data.ammban_certificate);
      setDocumentsFetched((prev) => ({
        ...prev,
        ammban: true,
      }));

      setLoading(false);
      setTargetLoader("");
    } catch (error) {
      notification.error({
        message: "ERROR",
        description: error.response
          ? error.response.data.message
          : "An error occured. Please try again.",
      });
      console.log(error);
      setLoading(false);
      setTargetLoader("");
    }
  };

  let handleDocumentItemChange = async (
    doc_name,
    { fileList: newFileList }
  ) => {
    let errant_obj = newFileList.find((obj) => {
      return obj.status !== "done";
    });

    newFileList = newFileList.filter((obj) => {
      return obj.status === "done";
    });

    errant_obj.status = "done";
    newFileList.push(errant_obj);

    setFileList({
      ...fileList,
      [doc_name]: newFileList,
    });
  };

  let clearUploadData = () => {
    setTargetModal({ target: "", drawer: "", modal: "" });
    setFileList({
      passport: [],
      id_card: [],
      address_proof: [],
      video: [],
      rolesVideo: [],
      rolesImage: [],
    });
  };

  let scrollToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  let deactivateHandler = () => {
    setTargetLoader("deactivate_merchant");

    deactivateMerchant(merchant_id)
      .then((res) => {
        setTargetLoader("");

        notification.info({
          message: "SUCCESS",
          description: res.data.message,
        });

        fetchMerchantInfo(merchant_id);
        fetchComments(merchant_id);
      })
      .catch((err) => {
        setTargetLoader("");
        if (
          err &&
          err.response &&
          (err.response.status === 401 || err.response.status === 403)
        )
          handleLogout();
      });
  };

  let handleEditCashpoint = (record) => {
    setTargetModal({ modal: "edit_cashier", drawer: "merchant_cashpoints" });

    setMetaDataInputs({
      cashier_id: record._id,
      cashier_phone: record.phone,
      cashier_email: record.email,
      cashier_firstname: record.contactfirstname,
      cashier_lastname: record.contactlastname,
    });
  };

  let cashPointEditHandler = async () => {
    setTargetLoader("edit_cashier");

    let data = {
      email: user_inputs.cashier_email,
      phone: user_inputs.cashier_phone,
      contactfirstname: user_inputs.cashier_firstname,
      contactlastname: user_inputs.cashier_lastname,
      cashier_id: user_inputs.cashier_id,
    };

    try {
      let result = await updateCashier(merchant_id, data);
      fetchCashpoints(1);

      notification.info({
        message: "SUCCESS",
        description: result.data.message,
      });

      setLoading(false);
      setTargetLoader("");
      fetchComments(merchant_id);
    } catch (err) {
      setLoading(false);
      setTargetLoader("");

      notification.error({
        message: "ERROR",
        description: err.response
          ? err.response.data.message
          : "An error occured. Please try again.",
      });

      if (
        err &&
        err.response &&
        (err.response.status === 401 || err.response.status === 403)
      )
        handleLogout();
    }
  };

  let searchOutboundTxn = async () => {
    setTargetLoader("outbound_txn");
    setTxn({ inbound: {}, outbound: {} });

    let taty_min_future = moment(user_inputs._date)
      .subtract(1, "hours")
      .add(1, "minutes")
      .add(1, "seconds")
      .format();
    let taty_min_past = moment(user_inputs._date)
      .subtract(1, "hours")
      .subtract(1, "minutes")
      .subtract(1, "seconds")
      .format();

    let data = {
      amount: user_inputs.t_amount,
      t_date_from: taty_min_past,
      t_date_to: taty_min_future,
      r_account_number: user_inputs.r_account_number,
    };

    try {
      let res = await searchOuboundTxn(merchant_id, data);
      setTxn({ outbound: res.data, inbound: {} });

      setTargetLoader("");
    } catch (error) {
      setTargetLoader("");
      notification.error({
        message: "ERROR",
        description: error.response
          ? error.response.data.message
          : "An error occured. Please try again.",
      });
    }
  };

  let searchInboundTxnFn = async () => {
    setTargetLoader("inbound_txn");
    setTxn({ inbound: {}, outbound: {} });

    let taty_min_future = moment(user_inputs._date)
      .subtract(1, "hours")
      .add(1, "minutes")
      .add(1, "seconds")
      .format();
    let taty_min_past = moment(user_inputs._date)
      .subtract(1, "hours")
      .subtract(1, "minutes")
      .subtract(1, "seconds")
      .format();

    let data = {
      amount: user_inputs.t_amount,
      t_date_from: taty_min_past,
      t_date_to: taty_min_future,
      r_account_number: user_inputs.r_account_number,
      s_account_number: user_inputs.s_account_number,
    };

    try {
      let res = await searchInboundTxn(merchant_id, data);
      setTxn({ inbound: res.data, outbound: {} });

      setTargetLoader("");
    } catch (error) {
      setTargetLoader("");
      notification.error({
        message: "ERROR",
        description: error.response
          ? error.response.data.message
          : "An error occured. Please try again.",
      });
    }
  };

  let searchTxnWithSessionIdFn = async () => {
    setTargetLoader("inbound_txn");
    setTxn({ inbound: {}, outbound: {} });

    let data = {
      sessionId: user_inputs?.sessionid,
    };

    try {
      let res = await searchInboundTxn(merchant_id, data);
      console.log("this is the response " + res);
      setTxn({ inbound: res.data, outbound: {} });

      setTargetLoader("");
    } catch (error) {
      setTargetLoader("");
      notification.error({
        message: "ERROR",
        description: error.response
          ? error.response.data.message
          : "An error occured. Please try again.",
      });
    }
  };

  let activateBankHandler = () => {
    setTargetLoader("verify_bank");
    activateBank(merchant_id)
      .then((res) => {
        notification.info({
          message: "SUCCESS",
          description: `Merchant bank successfully activated`,
        });

        fetchMerchantInfo(merchant_id);

        fetchComments(merchant_id);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          (err.response.status === 401 || err.response.status === 403)
        )
          handleLogout();
        notification.error({
          message: "ERROR",
          description: err.response
            ? err.response.data.message
            : "An error occured. Please try again.",
        });
      })
      .finally(() => {
        setTargetLoader("");
      });
  };

  let makePNDFalse = () => {
    setTargetLoader("pnd");
    updateMerchantPndFalse(merchant_id)
      .then((res) => {
        notification.info({
          message: "SUCCESS",
          description: `postnodebit successfully set to false`,
        });

        fetchMerchantInfo(merchant_id);

        fetchComments(merchant_id);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          (err.response.status === 401 || err.response.status === 403)
        )
          handleLogout();
        notification.error({
          message: "ERROR",
          description: err.response
            ? err.response.data.message
            : "An error occured. Please try again.",
        });
      })
      .finally(() => {
        setTargetLoader("");
      });
  };

  const sendUpgrade = async () => {
    try {
      setLoading(true);
      setTargetLoader("send_upgrade");
      const res = await sendLimitedUpgrade({ id: merchant_details._id });
      notification.success({
        message: "Success",
        description: res.data?.message,
      });
      setLoading(false);
      setTargetLoader("");
      fetchMerchantInfo(merchant_id);
    } catch (error) {
      setTargetLoader("");
      setLoading(false);
    }
  };

  const trashAccount = async () => {
    try {
      setLoading(true);
      setTargetLoader("trashAccount");
      const res = await trashAccountService(merchant_details._id);
      notification.success({
        message: "Success",
        description: res.data?.message,
      });
      setLoading(false);
      setTargetLoader("");
      fetchMerchantInfo(merchant_id);
    } catch (error) {
      setLoading(false);
      setTargetLoader("");
    }
  };

  const removeAccountFromTrash = async () => {
    try {
      setLoading(true);
      setTargetLoader("removeAccountFromTrash");
      const res = await removetrashAccountService(merchant_details._id);
      notification.success({
        message: "Success",
        description: res.data?.message,
      });
      setLoading(false);
      setTargetLoader("");
      fetchMerchantInfo(merchant_id);
    } catch (error) {
      setTargetLoader("");
      setLoading(false);
    }
  };

  const sendsandboxLimitedUpgrade = async () => {
    try {
      setLoading(true);
      setTargetLoader("sandboxLimitedUpgrade");
      const res = await sandboxLimitedUpgrade({ id: merchant_details._id });
      notification.success({
        message: "Success",
        description: res.data?.message,
      });
      setLoading(false);
      setTargetLoader("");
      fetchMerchantInfo(merchant_id);
    } catch (error) {
      setTargetLoader("");
      setLoading(false);
    }
  };

  function showPromiseSendUpgrade() {
    confirm({
      title: "Are you sure you want to give this merchant a limited upgrade?",
      icon: <ExclamationCircleOutlined />,
      content: ``,
      async onOk() {
        try {
          return sendUpgrade();
        } catch {
          return console.log("Oops errors!");
        }
      },
      onCancel() {},
    });
  }

  function showFullUpgradePromise(id) {
    confirm({
      title: "Are you sure you want to upgrade this merchant?",
      icon: <ExclamationCircleOutlined />,
      content: ``,
      async onOk() {
        try {
          return verifyMerchantHandler(id);
        } catch {
          return console.log("Oops errors!");
        }
      },
      onCancel() {},
    });
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (!sessionStorage.getItem("dartpay_token")) {
      handleLogout();
    } else {
      fetchMerchantInfo(merchant_id);
      fetchMerchantDocuments(merchant_id);
      fetchComments(merchant_id);
    }
  }, [
    fetchMerchantDocuments,
    fetchMerchantInfo,
    fetchComments,
    merchant_id,
    history,
    handleLogout,
  ]);

  const NINInfo = () => {
    return (
      <div className="">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="card-title mb-4">
                    <div className="d-flex justify-content-start">
                      <div className="image-container">
                        <AntImage
                          src={
                            ninInfo && ninInfo.image
                              ? nin_image_prefix + ninInfo.image
                              : "https://via.placeholder.com/150"
                          }
                          fallback="https://via.placeholder.com/150"
                          width={"100%"}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div
                        className="tab-pane fade show active"
                        id="basicInfo"
                        role="tabpanel"
                        aria-labelledby="basicInfo-tab"
                      >
                        <div className="row">
                          <div className="col-sm-3 col-md-2 col-5">
                            <label style={{ fontWeight: "bold" }}>
                              First Name
                            </label>
                          </div>
                          <div className="col-md-8 col-6">
                            {ninInfo && ninInfo.firstname}
                          </div>
                        </div>
                        <hr />

                        <div className="row">
                          <div className="col-sm-3 col-md-2 col-5">
                            <label style={{ fontWeight: "bold" }}>
                              Last Name
                            </label>
                          </div>
                          <div className="col-md-8 col-6">
                            {ninInfo && ninInfo.lastname}
                          </div>
                        </div>
                        <hr />

                        <div className="row">
                          <div className="col-sm-3 col-md-2 col-5">
                            <label style={{ fontWeight: "bold" }}>
                              Middle Name
                            </label>
                          </div>
                          <div className="col-md-8 col-6">
                            {ninInfo && ninInfo.middlename}
                          </div>
                        </div>
                        <hr />

                        <div className="row">
                          <div className="col-sm-3 col-md-2 col-5">
                            <label style={{ fontWeight: "bold" }}>NIN</label>
                          </div>
                          <div className="col-md-8 col-6">
                            {ninInfo && ninInfo.nin}
                          </div>
                        </div>
                        <hr />

                        <div className="row">
                          <div className="col-sm-3 col-md-2 col-5">
                            <label style={{ fontWeight: "bold" }}>
                              Birth Date
                            </label>
                          </div>
                          <div className="col-md-8 col-6">
                            {ninInfo && new Date(ninInfo.dob).toDateString()}
                          </div>
                        </div>
                        <hr />

                        <div className="row">
                          <div className="col-sm-3 col-md-2 col-5">
                            <label style={{ fontWeight: "bold" }}>
                              Phone Number
                            </label>
                          </div>
                          <div className="col-md-8 col-6">
                            {ninInfo && ninInfo.phone}
                          </div>
                        </div>
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  <p>NIN Shared By:</p>
                  <Table
                    key={(_, idx) => idx}
                    dataSource={nin_users.length && nin_users}
                    columns={bvn_users_columns}
                    pagination={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const BVNInfo = ({ bvnInfo }) => {
    return (
      <div className="">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="card-title mb-4">
                    <div className="d-flex justify-content-start">
                      <div className="image-container">
                        <AntImage
                          src={
                            bvnInfo && bvnInfo.image
                              ? bvn_image_prefix + bvnInfo.image
                              : "https://via.placeholder.com/150"
                          }
                          fallback="https://via.placeholder.com/150"
                          width={"100%"}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div
                        className="tab-pane fade show active"
                        id="basicInfo"
                        role="tabpanel"
                        aria-labelledby="basicInfo-tab"
                      >
                        <div className="row">
                          <div className="col-sm-3 col-md-2 col-5">
                            <label style={{ fontWeight: "bold" }}>
                              First Name
                            </label>
                          </div>
                          <div className="col-md-8 col-6">
                            {bvnInfo && bvnInfo.firstname}
                          </div>
                        </div>
                        <hr />

                        <div className="row">
                          <div className="col-sm-3 col-md-2 col-5">
                            <label style={{ fontWeight: "bold" }}>
                              Last Name
                            </label>
                          </div>
                          <div className="col-md-8 col-6">
                            {bvnInfo && bvnInfo.lastname}
                          </div>
                        </div>
                        <hr />

                        <div className="row">
                          <div className="col-sm-3 col-md-2 col-5">
                            <label style={{ fontWeight: "bold" }}>
                              Middle Name
                            </label>
                          </div>
                          <div className="col-md-8 col-6">
                            {bvnInfo && bvnInfo.middlename}
                          </div>
                        </div>
                        <hr />

                        {logged_in_admin.role_level >= ROLES.verifier.level && (
                          <div className="row">
                            <div className="col-sm-3 col-md-2 col-5">
                              <label style={{ fontWeight: "bold" }}>BVN</label>
                            </div>
                            <div className="col-md-8 col-6">
                              {bvnInfo && bvnInfo.bvn}
                            </div>
                          </div>
                        )}

                        {logged_in_admin.role_level >= ROLES.verifier.level && (
                          <hr />
                        )}

                        <div className="row">
                          <div className="col-sm-3 col-md-2 col-5">
                            <label style={{ fontWeight: "bold" }}>
                              Birth Date
                            </label>
                          </div>
                          <div className="col-md-8 col-6">
                            {bvnInfo && new Date(bvnInfo.dob).toDateString()}
                          </div>
                        </div>
                        <hr />

                        <div className="row">
                          <div className="col-sm-3 col-md-2 col-5">
                            <label style={{ fontWeight: "bold" }}>
                              Phone Number
                            </label>
                          </div>
                          <div className="col-md-8 col-6">
                            {bvnInfo && bvnInfo.phone}
                          </div>
                        </div>
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  <p>BVN Shared By:</p>
                  <Table
                    key={(_, idx) => idx}
                    dataSource={bvn_users.length && bvn_users}
                    columns={bvn_users_columns}
                    pagination={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const VerificationLogs = () => {
    let handleSwitch = (value) => {
      setSwitchValue(value);
    };

    let [switch_value, setSwitchValue] = useState(false);
    let [verification_comment, setInput] = useState("");

    let handleInput = (e) => {
      let { name, value } = e.target;

      setRequestError("");

      setInput((prev) => ({
        ...prev,
        [name]: value,
      }));
    };

    let handleCreateComment = () => {
      setLoading(true);
      setTargetLoader("comment");

      createVerificationComment(merchant_id, {
        send_to_slack: switch_value,
        comment: verification_comment.verification_comment,
      })
        .then((res) => {
          notification.success({
            message: "Comment Added.",
            description: res.message,
          });

          setInput((prev) => ({
            [verification_comment.verification_comment]: "",
          }));

          document.getElementById("stubborn_input").value = "";
          document.getElementById("stubborn_input").defaultValue = "";

          fetchComments(merchant_id);
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            (err.response.status === 401 || err.response.status === 403)
          )
            handleLogout();

          notification.error({
            message: "ERROR",
            description:
              err && err.response && err.response.data
                ? err.response.data.message
                : "Unable to create verification comment.",
          });
        })
        .finally(() => {
          setLoading(false);
          setTargetLoader("");
        });
    };

    return (
      <div className="id_doc">
        {v_comments && v_comments.comments && v_comments.comments.length ? (
          <div
            className="px-3"
            style={{
              maxHeight: "400px",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <List
              className="comment-list"
              //header={v_comments.length && `${v_comments.length} comments.`}
              itemLayout="horizontal"
              dataSource={v_comments.comments}
              renderItem={(item) => (
                <li>
                  <Comment
                    author={item.admin && item.admin.name}
                    content={item.comment}
                    datetime={item.createdon}
                  />
                </li>
              )}
            />
            <Divider />
          </div>
        ) : (
          <div className="p-3">
            <Empty description={"No comments found."} />
            <Divider />
          </div>
        )}

        <Form.Item className="p-3">
          <textarea
            className="w-100 rounded"
            id="stubborn_input"
            rows={4}
            onChange={handleInput}
            name="verification_comment"
          />
        </Form.Item>

        <Form.Item className="px-3 mb-0">
          <span className="mr-2">Send to tech support</span>
          <Switch
            name="message_toggle"
            defaultChecked={false}
            onChange={handleSwitch}
          />
        </Form.Item>

        <Form.Item className="p-3 mb-0">
          <Button
            disabled={
              !verification_comment.verification_comment ||
              logged_in_admin.role_level === ROLES.compliance.level
            }
            htmlType="button"
            loading={is_loading && target_loader === "comment"}
            onClick={handleCreateComment}
            type="primary"
          >
            Submit Comment
          </Button>

          <Button
            danger={merchant_details.certified ? true : false}
            disabled={logged_in_admin.role_level < ROLES.verifier.level}
            htmlType="button"
            loading={is_loading && target_loader === "certify"}
            onClick={handleCertifyUpgrade}
            className="ml-3"
          >
            {merchant_details.certified
              ? "Unverify merchant"
              : "Verify for Upgrade"}
          </Button>
          <Button
            disabled={
              logged_in_admin.role_level < ROLES.verifier.level ||
              merchant_details?.trashed === false ||
              merchant_details?.trashed === undefined
            }
            loading={target_loader === "removeAccountFromTrash"}
            type="primary"
            onClick={() => confirmModal("removeAccountFromTrash")}
            className="ml-3"
          >
            Remove account from trash
          </Button>
        </Form.Item>

        <Form.Item className="p-3">
          <Divider />
          <Row>
            <Col>
              <Tooltip
                title={
                  logged_in_admin.role_level < ROLES.super_admin.level
                    ? "You need to be a super admin to perform this operation."
                    : ""
                }
              >
                <Button
                  disabled={
                    active_merchant.verified ||
                    logged_in_admin.role_level < ROLES.super_admin.level
                  }
                  htmlType="button"
                  type="primary"
                  loading={is_verification_ongoing}
                  onClick={() => {
                    showFullUpgradePromise(merchant_id);
                  }}
                >
                  Upgrade Merchant
                </Button>
              </Tooltip>
            </Col>

            <Col>
              <Button
                disabled={
                  logged_in_admin.role_level < ROLES.super_admin.level ||
                  merchant_details?.limited_upgrade ||
                  merchant_details?.verified
                }
                loading={target_loader === "send_upgrade"}
                type="primary"
                onClick={showPromiseSendUpgrade}
                className="ml-3"
              >
                Limited upgrade notification
              </Button>
            </Col>

            <Col>
              <Button
                disabled={
                  logged_in_admin.role_level < ROLES.super_admin.level ||
                  merchant_details?.limited_upgrade ||
                  merchant_details?.verified
                }
                loading={target_loader === "sandboxLimitedUpgrade"}
                type="primary"
                onClick={() => confirmModal("sandboxLimitedUpgrade")}
                className="ml-3"
              >
                Sandbox limited upgrade
              </Button>
            </Col>
            <Col>
              <Button
                className="ml-3"
                disabled={
                  logged_in_admin.role_level < ROLES.verifier.level ||
                  merchant_details.trashed === true
                }
                loading={target_loader === "trashAccount"}
                type="primary"
                onClick={() => confirmModal("trashAccount")}
                danger
              >
                Move account to trash
              </Button>
            </Col>
          </Row>

          <Divider />
        </Form.Item>
      </div>
    );
  };

  let PassportForm = () => {
    return (
      <div className="px-4">
        <CloseOutlined
          style={{ fontSize: "22px" }}
          className="py-3"
          onClick={clearUploadData}
        />

        <p className="text-danger">
          Upload the front, back and selfie of unexpired Nigerian
          Government-issued international passport.{" "}
        </p>
        {/* <ImgCrop rotate> */}
        <Upload
          listType="picture-card"
          fileList={fileList.passport}
          onChange={(fileList) =>
            handleDocumentItemChange("passport", fileList)
          }
          //onPreview={handleFilePreview}
          showUploadList={{ showRemoveIcon: false, showPreviewIcon: false }}
          accept="image/png, image/jpeg, image/jpg"
        >
          {fileList.passport.length < 3 && "+ Upload"}
        </Upload>
        {/* </ImgCrop> */}

        {fileList.passport.length === 3 && "+ Upload" && (
          <Button
            disabled={
              logged_in_admin.role_level < ROLES.verifier.level ||
              Object.keys(internationalPassport).length
            }
            loading={is_loading && target_loader === "passport_upload"}
            onClick={submitIntlPassport}
            className="mt-4"
            type="primary"
          >
            Submit
          </Button>
        )}
      </div>
    );
  };

  let IDCardForm = () => {
    return (
      <div className="px-4">
        <CloseOutlined
          style={{ fontSize: "22px" }}
          className="py-3"
          onClick={clearUploadData}
        />

        <p className="text-danger">
          Upload the front, back and selfie of unexpired Nigerian
          Government-issued Driver's License, Voter's Card, NIN card, or
          National ID Card.{" "}
        </p>
        {/* <ImgCrop rotate> */}
        <Upload
          listType="picture-card"
          fileList={fileList.id_card}
          onChange={(fileList) => handleDocumentItemChange("id_card", fileList)}
          //onPreview={handleFilePreview}
          showUploadList={{ showRemoveIcon: false, showPreviewIcon: false }}
          accept="image/png, image/jpeg, image/jpg"
        >
          {fileList.id_card.length < 3 && "+ Upload"}
        </Upload>
        {/* </ImgCrop> */}

        {fileList.id_card.length === 3 && "+ Upload" && (
          <Button
            disabled={
              logged_in_admin.role_level < ROLES.verifier.level ||
              Object.keys(idCard).length
            }
            loading={is_loading && target_loader === "id_upload"}
            onClick={submitIdCard}
            className="mt-4"
            type="primary"
          >
            Submit
          </Button>
        )}
      </div>
    );
  };

  let AddressProofForm = () => {
    return (
      <div className="px-3">
        <CloseOutlined
          style={{ fontSize: "22px" }}
          className="py-3"
          onClick={clearUploadData}
        />

        <p>Upload either of: </p>
        <p>
          {" "}
          Utility bill - Water, Electricity, Rent, Waste bills showing your
          address{" "}
          <strong>(document should not be more than 3 months old)</strong>
        </p>
        <p>
          {" "}
          Bank statement showing your name & address{" "}
          <strong>(under 1 month old)</strong>
        </p>
        <p>
          {" "}
          Government-issued document showing your address{" "}
          <strong>
            (e.g contract letter, tax receipt, land use charges, etc)
          </strong>
        </p>
        {/* <ImgCrop rotate> */}
        <Upload
          listType="picture-card"
          fileList={fileList.address_proof}
          onChange={(fileList) =>
            handleDocumentItemChange("address_proof", fileList)
          }
          //onPreview={handleFilePreview}
          showUploadList={{ showRemoveIcon: false, showPreviewIcon: false }}
          accept="image/png, image/jpeg, image/jpg"
        >
          {fileList.address_proof.length < 1 && "+ Upload"}
        </Upload>
        {/* </ImgCrop> */}

        {fileList.address_proof.length === 1 && "+ Upload" && (
          <Button
            disabled={
              logged_in_admin.role_level < ROLES.verifier.level ||
              Object.keys(proofOfAddress).length
            }
            loading={is_loading && target_loader === "address_proof_upload"}
            onClick={submitAddressProof}
            className="mt-4"
            type="primary"
          >
            Submit
          </Button>
        )}
      </div>
    );
  };

  let LivelinessVideoForm = () => {
    return (
      <div className="px-3">
        <CloseOutlined
          style={{ fontSize: "22px" }}
          className="py-3"
          onClick={clearUploadData}
        />
        <p className="pb-4">Question: {merchant_details.liveliness_question}</p>
        <Upload
          fileList={fileList.video}
          name="file"
          accept="mp4, video/mp4, video/quicktime, video/x-m4v, video/*"
          onChange={(fileList) => handleDocumentItemChange("video", fileList)}
          showUploadList={{ showRemoveIcon: false, showPreviewIcon: false }}
          progress={{
            strokeColor: {
              "0%": "#108ee9",
              "100%": "#87d068",
            },
            strokeWidth: 3,
            format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
          }}
        >
          <Button icon={<UploadOutlined />}>
            {" "}
            {fileList.video.length < 1
              ? "Click to Upload video"
              : "Re-upload video"}
          </Button>
        </Upload>
        {fileList.video.length === 1 && "Click to Upload video" && (
          <Button
            disabled={
              logged_in_admin.role_level < ROLES.verifier.level ||
              Object.keys(liveVideo).length
            }
            loading={is_loading && target_loader === "video_upload"}
            onClick={submitVideo}
            className="mt-4"
            type="primary"
          >
            Submit
          </Button>
        )}
      </div>
    );
  };

  let AMMBANCertificateForm = () => {
    return (
      <div className="px-3">
        <CloseOutlined
          style={{ fontSize: "22px" }}
          className="py-3"
          onClick={clearUploadData}
        />

        <p>Upload merchant's AMMBAN Certificate </p>
        {/* <ImgCrop rotate> */}
        <Upload
          listType="picture-card"
          fileList={fileList.ammban}
          onChange={(fileList) => handleDocumentItemChange("ammban", fileList)}
          //onPreview={handleFilePreview}
          showUploadList={{ showRemoveIcon: false, showPreviewIcon: false }}
          accept="image/png, image/jpeg, image/jpg"
        >
          {fileList?.ammban?.length < 1 && "+ Upload"}
        </Upload>
        {/* </ImgCrop> */}

        {fileList?.ammban?.length === 1 && "+ Upload" && (
          <Button
            disabled={
              logged_in_admin.role_level < ROLES.verifier.level ||
              Object.keys(ammban)?.length
            }
            loading={is_loading && target_loader === "ammban_upload"}
            onClick={submitAmmban}
            className="mt-4"
            type="primary"
          >
            Submit
          </Button>
        )}
      </div>
    );
  };

  let CacDocForm = () => {
    return (
      <div className="px-3">
        <CloseOutlined
          style={{ fontSize: "22px" }}
          className="py-3"
          onClick={clearUploadData}
        />

        <p>Upload merchant's CAC Certificate </p>
        {/* <ImgCrop rotate> */}
        <Upload
          listType="picture-card"
          fileList={fileList.cac}
          onChange={(fileList) => handleDocumentItemChange("cac", fileList)}
          //onPreview={handleFilePreview}
          showUploadList={{ showRemoveIcon: false, showPreviewIcon: false }}
          accept="image/png, image/jpeg, image/jpg"
        >
          {fileList?.cac?.length < 1 && "+ Upload"}
        </Upload>
        {/* </ImgCrop> */}

        {fileList?.cac?.length === 1 && "+ Upload" && (
          <Button
            disabled={
              logged_in_admin.role_level < ROLES.verifier.level ||
              Object.keys(cac)?.length
            }
            loading={is_loading && target_loader === "ammban_upload"}
            onClick={submitAmmban}
            className="mt-4"
            type="primary"
          >
            Submit
          </Button>
        )}
      </div>
    );
  };

  let PasswordLivelinessVideoForm = () => {
    return (
      <div className="px-3">
        <CloseOutlined
          style={{ fontSize: "22px" }}
          className="py-3"
          onClick={clearUploadData}
        />

        <p>Upload merchant's password liveliness video </p>

        <Upload
          fileList={fileList.video}
          name="file"
          accept="mp4, video/mp4, video/quicktime, video/x-m4v, video/*"
          onChange={(fileList) => handleDocumentItemChange("video", fileList)}
          showUploadList={{ showRemoveIcon: false, showPreviewIcon: false }}
          progress={{
            strokeColor: {
              "0%": "#108ee9",
              "100%": "#87d068",
            },
            strokeWidth: 3,
            format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
          }}
        >
          <Button icon={<UploadOutlined />}>
            {" "}
            {fileList.video.length < 1
              ? "Click to Upload video"
              : "Re-upload video"}
          </Button>
        </Upload>

        {fileList?.cac?.length === 1 && "+ Upload" && (
          <Button
            disabled={
              logged_in_admin.role_level < ROLES.verifier.level ||
              Object.keys(cac)?.length
            }
            loading={is_loading && target_loader === "ammban_upload"}
            onClick={submitAmmban}
            className="mt-4"
            type="primary"
          >
            Submit
          </Button>
        )}
      </div>
    );
  };

  let PasswordLivelinessChangeVideoForm = () => {
    return (
      <div className="px-3">
        <CloseOutlined
          style={{ fontSize: "22px" }}
          className="py-3"
          onClick={clearUploadData}
        />

        <p>Upload merchant's password change liveliness video </p>

        <Upload
          fileList={fileList.video}
          name="file"
          accept="mp4, video/mp4, video/quicktime, video/x-m4v, video/*"
          onChange={(fileList) => handleDocumentItemChange("video", fileList)}
          showUploadList={{ showRemoveIcon: false, showPreviewIcon: false }}
          progress={{
            strokeColor: {
              "0%": "#108ee9",
              "100%": "#87d068",
            },
            strokeWidth: 3,
            format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
          }}
        >
          <Button icon={<UploadOutlined />}>
            {" "}
            {fileList.video.length < 1
              ? "Click to Upload video"
              : "Re-upload video"}
          </Button>
        </Upload>

        {fileList?.cac?.length === 1 && "+ Upload" && (
          <Button
            disabled={
              logged_in_admin.role_level < ROLES.verifier.level ||
              Object.keys(cac)?.length
            }
            loading={is_loading && target_loader === "ammban_upload"}
            onClick={submitAmmban}
            className="mt-4"
            type="primary"
          >
            Submit
          </Button>
        )}
      </div>
    );
  };

  let PinResetLivelinessVideoForm = () => {
    return (
      <div className="px-3">
        <CloseOutlined
          style={{ fontSize: "22px" }}
          className="py-3"
          onClick={clearUploadData}
        />

        <p>Upload merchant's password change liveliness video </p>
        {/* <ImgCrop rotate> */}
        <Upload
          fileList={fileList.video}
          name="file"
          accept="mp4, video/mp4, video/quicktime, video/x-m4v, video/*"
          onChange={(fileList) => handleDocumentItemChange("video", fileList)}
          showUploadList={{ showRemoveIcon: false, showPreviewIcon: false }}
          progress={{
            strokeColor: {
              "0%": "#108ee9",
              "100%": "#87d068",
            },
            strokeWidth: 3,
            format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
          }}
        >
          {fileList?.cac?.length < 1 && "+ Upload"}
        </Upload>
        {/* </ImgCrop> */}

        {fileList?.cac?.length === 1 && "+ Upload" && (
          <Button
            disabled={
              logged_in_admin.role_level < ROLES.verifier.level ||
              Object.keys(cac)?.length
            }
            loading={is_loading && target_loader === "ammban_upload"}
            onClick={submitAmmban}
            className="mt-4"
            type="primary"
          >
            Submit
          </Button>
        )}
      </div>
    );
  };

  let PinChangeLivelinessVideoForm = () => {
    return (
      <div className="px-3">
        <CloseOutlined
          style={{ fontSize: "22px" }}
          className="py-3"
          onClick={clearUploadData}
        />

        <p>Upload merchant's password change liveliness video </p>
        {/* <ImgCrop rotate> */}
        <Upload
          fileList={fileList.video}
          name="file"
          accept="mp4, video/mp4, video/quicktime, video/x-m4v, video/*"
          onChange={(fileList) => handleDocumentItemChange("video", fileList)}
          showUploadList={{ showRemoveIcon: false, showPreviewIcon: false }}
          progress={{
            strokeColor: {
              "0%": "#108ee9",
              "100%": "#87d068",
            },
            strokeWidth: 3,
            format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
          }}
        >
          {fileList?.cac?.length < 1 && "+ Upload"}
        </Upload>
        {/* </ImgCrop> */}

        {fileList?.cac?.length === 1 && "+ Upload" && (
          <Button
            disabled={
              logged_in_admin.role_level < ROLES.verifier.level ||
              Object.keys(cac)?.length
            }
            loading={is_loading && target_loader === "ammban_upload"}
            onClick={submitAmmban}
            className="mt-4"
            type="primary"
          >
            Submit
          </Button>
        )}
      </div>
    );
  };

  let RolesLivelinessVideoForm = () => {
    return (
      <div className="px-3">
        <CloseOutlined
          style={{ fontSize: "22px" }}
          className="py-3"
          onClick={clearUploadData}
        />

        <p>Upload merchant's roles liveliness video </p>
        <Upload
          fileList={fileList.rolesVideo}
          name="file"
          accept="mp4, video/mp4, video/quicktime, video/x-m4v, video/*"
          onChange={(fileList) =>
            handleDocumentItemChange("rolesVideo", fileList)
          }
          showUploadList={{ showRemoveIcon: false, showPreviewIcon: false }}
          progress={{
            strokeColor: {
              "0%": "#108ee9",
              "100%": "#87d068",
            },
            strokeWidth: 3,
            format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
          }}
        >
          <Button icon={<UploadOutlined />}>
            {" "}
            {fileList.rolesVideo?.length < 1
              ? "Click to Upload roles video"
              : "Re-upload video"}
          </Button>
        </Upload>

        {fileList?.rolesVideo?.length === 1 && "+ Upload" && (
          <Button
            disabled={logged_in_admin.role_level < ROLES.verifier.level}
            loading={is_loading && target_loader === "video_upload"}
            onClick={submitRolesVideo}
            className="mt-4"
            type="primary"
          >
            Submit
          </Button>
        )}
      </div>
    );
  };

  let RolesLiveImage = () => {
    return (
      <div className="px-3">
        <CloseOutlined
          style={{ fontSize: "22px" }}
          className="py-3"
          onClick={clearUploadData}
        />

        <p>Upload merchant's roles image </p>

        <Upload
          listType="picture-card"
          fileList={fileList.rolesImage}
          onChange={(fileList) =>
            handleDocumentItemChange("rolesImage", fileList)
          }
          //onPreview={handleFilePreview}
          showUploadList={{ showRemoveIcon: false, showPreviewIcon: false }}
          accept="image/png, image/jpeg, image/jpg"
        >
          {fileList?.rolesImage?.length < 1 && "+ Upload"}
        </Upload>

        {fileList?.rolesImage?.length === 1 && "+ Upload" && (
          <Button
            disabled={logged_in_admin.role_level < ROLES.verifier.level}
            loading={is_loading && target_loader === "roles_image"}
            onClick={submitRolesImage}
            className="mt-4"
            type="primary"
          >
            Submit
          </Button>
        )}
      </div>
    );
  };

  const KycReupload = () => {
    let data = [
      {
        title: "International Passport",
        target: "passport",
      },
      {
        title: "Driver's License, Voter's Card, NIN card, or National ID Card",
        target: "id_card",
      },
      {
        title: "Proof of address",
        target: "address_proof",
      },
      {
        title: "Liveliness Video",
        target: "video",
      },
      {
        title: "AMMBAN Certificate",
        target: "ammban",
      },
      {
        title: "CAC Document",
        target: "cac_docs",
      },
    ];

    return (
      <section>
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={
                  <Button
                    type="link"
                    onClick={() => {
                      setTargetModal({
                        target: item.target,
                        drawer: "kyc_documents",
                      });
                    }}
                  >
                    {item.title}
                  </Button>
                }
              />
            </List.Item>
          )}
        />
      </section>
    );
  };

  const LivelinessReupload = () => {
    let data = [
      {
        title: "Roles Video Re-upload",
        target: "roles_video_reupload",
      },
      {
        title: "Roles Image Re-upload",
        target: "roles_image_reupload",
      },
      {
        title: "Pin Reset Video Re-upload",
        target: "pin_reset_video_reupload",
      },
      {
        title: "Pin Change Re-upload",
        target: "pin_change_reupload",
      },
      {
        title: "Password Reset Re-upload",
        target: "password_reset_reupload",
      },
      {
        title: "Password Change Re-upload",
        target: "password_change_reupload",
      },
      {
        title: "Limit Increase Video Reupload",
        target: "limit_increase_reupload",
      },
    ];

    return (
      <section>
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={
                  <Button
                    type="link"
                    onClick={() => {
                      setTargetModal({
                        target: item.target,
                        drawer: "kyc_documents",
                      });
                    }}
                  >
                    {item.title}
                  </Button>
                }
              />
            </List.Item>
          )}
        />
      </section>
    );
  };

  const PasswordReset = () => {
    return (
      <div className="px-3">
        <CloseOutlined
          style={{ fontSize: "22px" }}
          className="py-3"
          onClick={clearUploadData}
        />
        <p className="pb-4">Question: {merchant_details.liveliness_question}</p>
        <Upload
          fileList={fileList.video}
          name="file"
          accept="mp4, video/mp4, video/quicktime, video/x-m4v, video/*"
          onChange={(fileList) => handleDocumentItemChange("video", fileList)}
          showUploadList={{ showRemoveIcon: false, showPreviewIcon: false }}
          progress={{
            strokeColor: {
              "0%": "#108ee9",
              "100%": "#87d068",
            },
            strokeWidth: 3,
            format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
          }}
        >
          <Button icon={<UploadOutlined />}>
            {" "}
            {fileList.video.length < 1
              ? "Click to Upload video"
              : "Re-upload video"}
          </Button>
        </Upload>
        {fileList.video.length === 1 && "Click to Upload video" && (
          <Button
            disabled={
              logged_in_admin.role_level < ROLES.verifier.level ||
              Object.keys(liveVideo).length
            }
            loading={is_loading && target_loader === "video_upload"}
            onClick={submitVideo}
            className="mt-4"
            type="primary"
          >
            Submit
          </Button>
        )}
      </div>
    );
  };

  const MerchantDocumentTabs = () => {
    return (
      <div className="row">
        <div className="col-xs-12 w-100">
          <nav>
            <div
              className="nav nav-tabs nav-fill w-100"
              id="nav-tab"
              role="tablist"
            >
              <button
                type="button"
                onClick={() => {
                  setActiveTab("comment");
                }}
                className={
                  activetab === "comment"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
                id="nav-comment-tab"
                data-toggle="tab"
                role="tab"
                aria-controls="nav-comment"
                aria-selected={activetab === "comment" ? "true" : "false"}
              >
                Logs {is_comments_loading && <Spin indicator={antIcon} />}
              </button>
              <button
                type="button"
                onClick={() => {
                  setActiveTab("id_card");
                }}
                className={
                  activetab === "id_card"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
                id="nav-id_card-tab"
                data-toggle="tab"
                role="tab"
                aria-controls="nav-id_card"
                aria-selected={activetab === "id_card" ? "true" : "false"}
              >
                ID Card{" "}
                {!documents_fetched.id_card && <Spin indicator={antIcon} />}
              </button>

              <button
                type="button"
                onClick={() => {
                  setActiveTab("video");
                }}
                className={
                  activetab === "video"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
                id="nav-video-tab"
                data-toggle="tab"
                role="tab"
                aria-controls="nav-video"
                aria-selected={activetab === "video" ? "true" : "false"}
              >
                Live Video{" "}
                {!documents_fetched.video && <Spin indicator={antIcon} />}
              </button>

              <button
                type="button"
                onClick={() => {
                  setActiveTab("passport");
                }}
                className={
                  activetab === "passport"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
                id="nav-passport-tab"
                data-toggle="tab"
                role="tab"
                aria-controls="nav-passport"
                aria-selected={activetab === "passport" ? "true" : "false"}
              >
                Intl Passport{" "}
                {!documents_fetched.passport && <Spin indicator={antIcon} />}
              </button>

              <button
                type="button"
                onClick={() => {
                  setActiveTab("proof_of_address");
                }}
                className={
                  activetab === "proof_of_address"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
                id="nav-proof_of_address-tab"
                data-toggle="tab"
                role="tab"
                aria-controls="nav-proof_of_address"
                aria-selected={
                  activetab === "proof_of_address" ? "true" : "false"
                }
              >
                Proof of Address{" "}
                {!documents_fetched.proof_of_address && (
                  <Spin indicator={antIcon} />
                )}
              </button>

              <button
                type="button"
                onClick={() => {
                  setActiveTab("bvn");
                }}
                className={
                  activetab === "bvn"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
                id="nav-bvn-tab"
                data-toggle="tab"
                role="tab"
                aria-controls="nav-bvn"
                aria-selected={activetab === "bvn" ? "true" : "false"}
              >
                BVN {!documents_fetched.bvn && <Spin indicator={antIcon} />}
              </button>

              <button
                type="button"
                onClick={() => {
                  setActiveTab("nin");
                }}
                className={
                  activetab === "nin"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
                id="nav-nin-tab"
                data-toggle="tab"
                role="tab"
                aria-controls="nav-nin"
                aria-selected={activetab === "nin" ? "true" : "false"}
              >
                NIN {!documents_fetched.nin && <Spin indicator={antIcon} />}
              </button>

              <button
                type="button"
                onClick={() => {
                  setActiveTab("cac");
                }}
                className={
                  activetab === "cac"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
                id="nav-cac-tab"
                data-toggle="tab"
                role="tab"
                aria-controls="nav-cac"
                aria-selected={activetab === "cac" ? "true" : "false"}
              >
                CAC Document{" "}
                {!documents_fetched.cac && <Spin indicator={antIcon} />}
              </button>

              <button
                type="button"
                onClick={() => {
                  setActiveTab("otherDocs");
                }}
                className={
                  activetab === "otherDocs"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
                id="nav-otherDocs-tab"
                data-toggle="tab"
                role="tab"
                aria-controls="nav-otherDocs"
                aria-selected={activetab === "otherDocs" ? "true" : "false"}
              >
                Other Documents{" "}
                {!documents_fetched.otherDocs && <Spin indicator={antIcon} />}
              </button>

              {merchant_details?.classification === "pos" && (
                <button
                  type="button"
                  onClick={() => {
                    setActiveTab("ammban");
                  }}
                  className={
                    activetab === "ammban"
                      ? "nav-item nav-link active"
                      : "nav-item nav-link"
                  }
                  id="nav-ammban-tab"
                  data-toggle="tab"
                  role="tab"
                  aria-controls="nav-video"
                  aria-selected={activetab === "ammban" ? "true" : "false"}
                >
                  AMMBAN Certificate{" "}
                  {!documents_fetched.ammban && <Spin indicator={antIcon} />}
                </button>
              )}

              <button
                type="button"
                onClick={() => {
                  setActiveTab("kyc_upload");
                }}
                className={
                  activetab === "kyc_upload"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
                id="nav-kyc_upload-tab"
                data-toggle="tab"
                role="tab"
                aria-controls="nav-kyc_upload"
                aria-selected={activetab === "kyc_upload" ? "true" : "false"}
              >
                KYC Re-upload
              </button>

              {/* the below button is not needed at the moment, hence why it was disabled */}
              {false && (
                <button
                  type="button"
                  onClick={() => {
                    setActiveTab("liveliness_upload");
                  }}
                  className={
                    activetab === "liveliness_upload"
                      ? "nav-item nav-link active"
                      : "nav-item nav-link"
                  }
                  id="nav-liveliness_upload"
                  data-toggle="tab"
                  role="tab"
                  aria-controls="liveliness_upload"
                  aria-selected={
                    activetab === "liveliness_upload" ? "true" : "false"
                  }
                >
                  Liveliness videos upload
                </button>
              )}
            </div>
          </nav>

          <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
            <div
              className={
                activetab === "id_card"
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
              id="nav-id_card"
              role="tabpanel"
              aria-labelledby="nav-id_card-tab"
            >
              <div className="id_doc">
                {active_id_image ? (
                  <div>
                    <img
                      src={changeImageExt(active_id_image, "jpg")}
                      alt="Nature"
                      className="responsive_image"
                    />
                  </div>
                ) : (
                  <div className="id_doc">
                    <Empty description="Merchant hasn't uploaded an ID card." />
                  </div>
                )}
              </div>
              {active_id_image && (
                <Button
                  disabled={logged_in_admin.role_level < ROLES.verifier.level}
                  loading={rejection_ongoing && activetab === "id_card"}
                  onClick={() => {
                    setTargetModal({
                      target: "id_card",
                      modal: "reject_message",
                    });
                  }}
                  className="mt-3"
                  danger
                >
                  Reject ID Card
                </Button>
              )}
            </div>

            {/* <div
              className={
                activetab === "password-reset"
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
              id="nav-password-reset"
              role="tabpanel"
              aria-labelledby="nav-password-reset-tab"
            >
              <div className="id_doc">
                {passwordResetVideo?.videoUrl &&
                passwordResetVideo?.type === "password" ? (
                  <div>
                    <p className="question">
                      Question: {passwordResetVideo?.question}
                    </p>
                    <video
                      src={passwordResetVideo?.videoUrl}
                      controls={true}
                      className="responsive_video"
                    />
                  </div>
                ) : (
                  <div className="id_doc">
                    <Empty description="Merchant hasn't uploaded a password reset video yet." />
                  </div>
                )}
              </div>

              {passwordResetVideo?.videoUrl && (
                <Button
                  disabled={logged_in_admin.role_level < ROLES.verifier.level}
                  loading={rejection_ongoing && activetab === "password-reset"}
                  onClick={() => {
                    setTargetModal({
                      target: "password-reset",
                      modal: "reject_message",
                    });
                  }}
                  className="mt-3"
                  danger
                >
                  Reject Password Reset Video
                </Button>
              )}
            </div> */}

            {/* <div
              className={
                activetab === "increase_limit"
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
              id="nav-id_card"
              role="tabpanel"
              aria-labelledby="nav-id_card-tab"
            >
              <div className="id_doc">

                <VideoDisplay
                  videoSrc={limitIncreaseVideo}
                  emptyDescription="Merchant hasn't uploaded their liveliness video yet."
                />
              </div>
              {limitIncreaseVideo?.videoUrl && (
                <Button
                  disabled={logged_in_admin.role_level < ROLES.verifier.level}
                  loading={rejection_ongoing && activetab === "id_card"}
                  onClick={() => {
                    setTargetModal({
                      target: "id_card",
                      modal: "reject_message",
                    });
                  }}
                  className="mt-3"
                  type="primary"
                >
                  Accept Limit Increase Video
                </Button>
              )}
              {limitIncreaseVideo?.videoUrl && <div className="ml-20"></div>}
              {limitIncreaseVideo?.videoUrl && (
                <Button
                  disabled={logged_in_admin.role_level < ROLES.verifier.level}
                  loading={rejection_ongoing && activetab === "id_card"}
                  onClick={() => {
                    setTargetModal({
                      target: "id_card",
                      modal: "reject_message",
                    });
                  }}
                  className="mt-3"
                  danger
                >
                  Reject Limit Increase Video
                </Button>
              )}
            </div> */}

            {/* <div
              className={
                activetab === "pin-reset"
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
              id="nav-pin-reset"
              role="tabpanel"
              aria-labelledby="nav-pin-reset-tab"
            >
              <div className="id_doc">
                {passwordResetVideo?.videoUrl &&
                passwordResetVideo?.type === "pin" ? (
                  <div>
                    <p className="question">
                      Question: {passwordResetVideo?.question}
                    </p>
                    <video
                      src={passwordResetVideo?.videoUrl}
                      controls={true}
                      className="responsive_video"
                    />
                  </div>
                ) : (
                  <div className="id_doc">
                    <Empty description="Merchant hasn't uploaded a pin reset video yet." />
                  </div>
                )}
              </div>

              {passwordResetVideo?.videoUrl &&
                passwordResetVideo?.type === "pin" && (
                  <Button
                    disabled={logged_in_admin.role_level < ROLES.verifier.level}
                    loading={rejection_ongoing && activetab === "pin-reset"}
                    onClick={() => {
                      setTargetModal({
                        target: "pin-reset",
                        modal: "reject_message",
                      });
                    }}
                    className="mt-3"
                    danger
                  >
                    Reject Pin Reset Video
                  </Button>
                )}
            </div> */}

            <div
              className={
                activetab === "proof_of_address"
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
              id="nav-proof_of_address"
              role="tabpanel"
              aria-labelledby="nav-proof_of_address-tab"
            >
              <div className="id_doc">
                {proofOfAddress && proofOfAddress.image_url ? (
                  <div>
                    <img
                      src={changeImageExt(proofOfAddress, "jpg")}
                      alt="Nature"
                      className="responsive_image"
                    />
                  </div>
                ) : (
                  <div className="id_doc">
                    <Empty description="Merchant hasn't uploaded a proof of address." />
                  </div>
                )}
              </div>
              {proofOfAddress && proofOfAddress.image_url && (
                <Button
                  disabled={logged_in_admin.role_level < ROLES.verifier.level}
                  loading={
                    rejection_ongoing && activetab === "proof_of_address"
                  }
                  //onClick={handleRejectAddressProof}
                  onClick={() => {
                    setTargetModal({
                      target: "address_proof",
                      modal: "reject_message",
                    });
                  }}
                  className="mt-3"
                  danger
                >
                  Reject Proof of Address
                </Button>
              )}
            </div>

            <div
              className={
                activetab === "passport"
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
              id="nav-passport"
              role="tabpanel"
              aria-labelledby="nav-passport-tab"
            >
              <div className="id_doc">
                {active_international_passport ? (
                  <div>
                    <img
                      src={active_international_passport}
                      alt="Nature"
                      className="responsive_image"
                    />
                  </div>
                ) : (
                  <div className="id_doc">
                    <Empty description="Merchant hasn't uploaded international passport." />
                  </div>
                )}
              </div>

              {active_international_passport && (
                <Button
                  disabled={logged_in_admin.role_level < ROLES.verifier.level}
                  loading={rejection_ongoing && activetab === "passport"}
                  //onClick={handleRejectPassport}
                  onClick={() => {
                    setTargetModal({
                      target: "intl_passport",
                      modal: "reject_message",
                    });
                  }}
                  className="mt-3"
                  danger
                >
                  Reject International Passport
                </Button>
              )}
            </div>

            <div
              className={
                activetab === "bvn"
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
              id="nav-bvn"
              role="tabpanel"
              aria-labelledby="nav-bvn-tab"
            >
              {bvnInfo && Object.keys(bvnInfo).length ? (
                <BVNInfo bvnInfo={bvnInfo} />
              ) : (
                <div className="id_doc">
                  <Empty description="Merchant hasn't provided their BVN." />
                </div>
              )}
            </div>

            <div
              className={
                activetab === "nin"
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
              id="nav-nin"
              role="tabpanel"
              aria-labelledby="nav-nin-tab"
            >
              {ninInfo && Object.keys(ninInfo).length ? (
                <NINInfo ninInfo={ninInfo} />
              ) : (
                <div className="id_doc">
                  <Empty description="Merchant hasn't provided their NIN." />
                </div>
              )}
            </div>

            <div
              className={
                activetab === "video"
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
              id="nav-video"
              role="tabpanel"
              aria-labelledby="nav-video-tab"
            >
              <div className="id_doc">
                {liveVideo?.video_url ? (
                  <div>
                    <p className="question">Question: {liveVideo.question}</p>
                    <video
                      src={liveVideo?.video_url}
                      controls={true}
                      className="responsive_video"
                    />
                  </div>
                ) : (
                  <div className="id_doc">
                    <Empty description="Merchant hasn't uploaded their liveliness video yet." />
                  </div>
                )}

                {liveVideo?.video_url && (
                  <Button
                    disabled={logged_in_admin.role_level < ROLES.verifier.level}
                    loading={rejection_ongoing && activetab === "video"}
                    onClick={() => {
                      setTargetModal({
                        target: "video",
                        modal: "reject_message",
                      });
                    }}
                    className="mt-3"
                    danger
                  >
                    Reject Liveliness Video
                  </Button>
                )}
              </div>
            </div>

            <div
              className={
                activetab === "ammban"
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
              id="nav-ammban"
              role="tabpanel"
              aria-labelledby="nav-ammban-tab"
            >
              <div className="id_doc">
                {ammban?.image_url ? (
                  <div>
                    <img
                      src={ammban?.image_url}
                      alt="Nature"
                      className="responsive_image"
                    />
                  </div>
                ) : (
                  <div className="id_doc">
                    <Empty description="Merchant hasn't uploaded the AMMBAN Certificate." />
                  </div>
                )}
              </div>
              {ammban?.image_url && (
                <Button
                  disabled={logged_in_admin.role_level < ROLES.verifier.level}
                  loading={rejection_ongoing && activetab === "ammban"}
                  onClick={() => {
                    setTargetModal({
                      target: "ammban",
                      modal: "reject_message",
                    });
                  }}
                  className="mt-3"
                  danger
                >
                  Reject AMMABN Certificate
                </Button>
              )}
            </div>

            <div
              className={
                activetab === "cac"
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
              id="nav-cac"
              role="tabpanel"
              aria-labelledby="nav-cac-tab"
            >
              <div className="id_doc">
                {cac && cac?.image_url ? (
                  <div>
                    <img
                      //src={cac.image_url}
                      src={changeImageExt(cac, "jpg")}
                      alt="Nature"
                      className="responsive_image"
                    />
                  </div>
                ) : (
                  <div className="id_doc">
                    <Empty description="Merchant hasn't uploaded CAC document" />
                  </div>
                )}
              </div>
              {cac && cac?.image_url && (
                <Button
                  disabled={logged_in_admin.role_level < ROLES.verifier.level}
                  loading={rejection_ongoing && activetab === "cac"}
                  onClick={() => {
                    setTargetModal({ target: "cac", modal: "reject_message" });
                  }}
                  className="mt-3"
                  danger
                >
                  Reject CAC
                </Button>
              )}
            </div>

            <div
              className={
                activetab === "otherDocs"
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
              id="nav-otherDocs"
              role="tabpanel"
              aria-labelledby="nav-otherDocs-tab"
            >
              <div className="flex-with-space">
                {otherDocs.length > 0 &&
                  otherDocs.map((doc, idx) => {
                    return (
                      <div key={idx} className="small_doc">
                        <img
                          src={changeImageExt(doc, "jpg")}
                          alt="Nature"
                          className="responsive_small_image"
                        />
                        <Button
                          disabled={
                            logged_in_admin.role_level < ROLES.verifier.level
                          }
                          loading={
                            rejection_ongoing && activetab === "otherDocs"
                          }
                          onClick={() => {
                            setOtherDocID(idx);
                            setTargetModal({
                              target: "otherDocs",
                              modal: "reject_message",
                            });
                          }}
                          className="mt-3"
                          danger
                        >
                          Reject{" "}
                          {splitTitleFromOtherDocument(doc.complience_type)}
                        </Button>
                      </div>
                    );
                  })}
                {otherDocs.length < 1 && (
                  <div style={{ width: "100%" }} className="id_doc">
                    <Empty description="No other Business Documents" />
                  </div>
                )}
              </div>
            </div>

            <div
              className={
                activetab === "kyc_upload"
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
              id="nav-kyc_upload"
              role="tabpanel"
              aria-labelledby="nav-kyc_upload-tab"
            >
              <div className="id_doc">
                <KycReupload />
              </div>
            </div>

            <div
              className={
                activetab === "liveliness_upload"
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
              id="nav-liveliness_upload"
              role="tabpanel"
              aria-labelledby="nav-liveliness_upload-tab"
            >
              <div className="id_doc">
                <LivelinessReupload />
              </div>
            </div>

            <div
              className={
                activetab === "comment"
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
              id="nav-comment"
              role="tabpanel"
              aria-labelledby="nav-comment-tab"
            >
              <VerificationLogs />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const MerchantCashPoints = () => {
    const [input, setInput] = useState("");

    const filteredProvidus = () => {
      let filteredAcc = merchant_cashpoints.cashpoints.filter((acc) => {
        if (input.length < 1) {
          return acc;
        }
        return acc?.nubans?.[0]?.nuban.includes(input);
      });
      return filteredAcc;
    };

    let cashpoint_columns = [
      {
        title: "Date Created",
        dataIndex: "createdon",
        render: (_, record) => (
          <span>{new Date(record.createdon).toDateString()}</span>
        ),
        key: "createdon",
      },
      {
        title: "First name",
        dataIndex: "contactfirstname",
        key: "contactfirstname",
      },
      {
        title: "Last name",
        dataIndex: "contactlastname",
        key: "contactlastname",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
      },
      {
        title: "Nuban",
        dataIndex: "nuban",
        key: "nuban",
      },
      {
        title: "Alternate Providus NUBAN",
        dataIndex: "nubans",
        key: "nubans",
        render: (_, record) => (
          <span>{record?.nubans?.[0]?.nuban ?? "NA"}</span>
        ),
      },
      // {
      //     title: 'Verified',
      //     dataIndex: 'certified',
      //     render: (_, record) => (
      //         <span>{record.certified ? <Tag title="TRUE" color="green" >TRUE</Tag> : <Tag color="red" >FALSE</Tag>}</span>
      //     ),
      //     key: 'certified'
      // },
      {
        title: "Actions",
        dataIndex: "",
        key: "x",
        render: (_, record) => (
          <div>
            <Button
              disabled={logged_in_admin.role_level < ROLES.super_admin.level}
              onClick={() => {
                handleEditCashpoint(record);
              }}
              type="link"
            >
              Edit
            </Button>
            {/* <Button loading={loading_target === "reset" && target_admin === record.email} danger disabled={logged_in_admin.role_level < ROLES.super_admin.level} onClick={() => {resetPassword(record.email)}}>Reset Password</Button> */}
          </div>
        ),
      },
    ];

    return (
      <div className="px-5">
        <div className="my-4 d-flex">
          <Button
            className="pt-0 pl-0"
            type="link"
            onClick={() => {
              setTargetModal({ drawer: "" });
            }}
          >
            <h6>
              <CloseOutlined />
            </h6>
          </Button>

          <h6>Merchant Cashpoints</h6>
        </div>
        <div className="display-flex justify-end mb-40">
          <div>
            <Search
              placeholder="Search by Providus Account Number"
              loading={searchAccount}
              size="middle"
              onPressEnter={() => filteredProvidus()}
              type="number"
              onChange={(e) => setInput(e.target.value)}
              onSearch={() => filteredProvidus()}
            />
          </div>
        </div>
        <Table
          columns={cashpoint_columns}
          // dataSource={merchant_cashpoints.cashpoints}
          dataSource={filteredProvidus()}
          bordered
          size="small"
          key={(_, idx) => idx}
          pagination={{}}
        />
      </div>
    );
  };

  const MerchantBiz = () => {
    let biz_columns = [
      {
        title: "Date",
        dataIndex: "createdon",
        render: (_, record) => (
          <span>{new Date(record.createdon).toDateString()}</span>
        ),
        key: "createdon",
      },
      {
        title: "Name",
        dataIndex: "businessname",
        render: (_, record) => (
          <Button
            type="link"
            onClick={() => {
              setTargetModal({ drawer: "" });
              scrollToTop();
              history.push("/merchant/" + record._id);
            }}
          >
            {record.businessname}
          </Button>
        ),
        key: "businessname",
      },
      {
        title: "Phone",
        dataIndex: "businessphone",
        key: "businessphone",
      },
      {
        title: "Upgraded",
        dataIndex: "verified",
        render: (_, record) => (
          <span>
            {record.verified ? (
              <Tag title="TRUE" color="green">
                TRUE
              </Tag>
            ) : (
              <Tag color="red">FALSE</Tag>
            )}
          </span>
        ),
        key: "verified",
      },
      {
        title: "Verified",
        dataIndex: "certified",
        render: (_, record) => (
          <span>
            {record.certified ? (
              <Tag title="TRUE" color="green">
                TRUE
              </Tag>
            ) : (
              <Tag color="red">FALSE</Tag>
            )}
          </span>
        ),
        key: "certified",
      },
    ];

    return (
      <div className="px-5">
        <div className="my-4 d-flex">
          <Button
            className="pt-0 pl-0"
            type="link"
            onClick={() => {
              setTargetModal({ drawer: "" });
            }}
          >
            <h6>
              <CloseOutlined />
            </h6>
          </Button>

          <h6>Merchant Businesses</h6>
        </div>

        <Table
          columns={biz_columns}
          dataSource={merchant_biz}
          bordered
          size="small"
          key={(_, idx) => idx}
          pagination={false}
        />
      </div>
    );
  };

  const MerchantTxns = () => {
    let handleTxnTable = (pagination, filters) => {
      let data = {
        page: pagination.current,
        limit: pagination.pageSize,
        filters:
          filters && filters.type && filters.type.length
            ? filters.type
            : store.get("txn_pagination").filters,
        all_time: store.get("txn_pagination").all_time,
        total: store.get("txn_pagination").total,
        single_date: store.get("txn_pagination").single_date,
        start_date: store.get("txn_pagination").start_date,
        end_date: store.get("txn_pagination").end_date,
      };

      store.set("txn_pagination", data);

      fetchMerchantWalletTxns(data);
    };

    let handleSingleDate = (event) => {
      let single_date = event.format(dateFormat);

      let data = {
        page: 1,
        limit: 10,
        total: store.get("txn_pagination").total,
        filters: store.get("txn_pagination").filters,
        single_date,
        end_date: null,
        start_date: null,
        all_time: false,
      };

      store.set("txn_pagination", data);

      fetchMerchantWalletTxns(data);
    };

    let handleDateRange = (event) => {
      let start_date = event[0].format(dateFormat);
      let end_date = event[1].format(dateFormat);

      let data = {
        page: 1,
        limit: 10,
        total: store.get("txn_pagination").total,
        filters: store.get("txn_pagination").filters,
        single_date: null,
        end_date,
        start_date,
        all_time: false,
      };

      store.set("txn_pagination", data);

      fetchMerchantWalletTxns(data);
    };

    let txns_columns = [
      {
        title: "Date",
        dataIndex: "createdon",
        render: (_, record) => (
          <span>{new Date(record.createdon).toLocaleString()}</span>
        ),
        key: "createdon",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        render: (_, record) => (
          <span>{numeral(record.amount).format("0,0.00")}</span>
        ),
        key: "address",
      },
      {
        title: "Type",
        dataIndex: "type",
        render: (type) => (
          <Tag color={type === "credit" ? "green" : "red"}>
            {type === "credit" ? "Credit" : "Debit"}
          </Tag>
        ),
        key: "type",
        filters: [
          { text: "Credit", value: "credit" },
          { text: "Debit", value: "debit" },
        ],
        width: "20%",
      },
      {
        title: "Narrative",
        dataIndex: "narrative",
        key: "narrative",
      },
    ];

    let FilterMenu = () => {
      return (
        <div className="">
          <Space direction="vertical">
            <Button
              type="link"
              key="1"
              className="px-0"
              onClick={() => {
                store.set("txn_pagination", {
                  page: 1,
                  limit: 10,
                  total: store.get("txn_pagination").total,
                  filters: ["credit", "debit"],
                  single_date: null,
                  end_date: null,
                  start_date: null,
                  all_time: true,
                });

                fetchMerchantWalletTxns({
                  page: 1,
                  limit: 10,
                  total: store.get("txn_pagination").total,
                  filters: ["credit", "debit"],
                  single_date: null,
                  end_date: null,
                  start_date: null,
                  all_time: true,
                });
              }}
            >
              All Time
            </Button>

            <div key="2" style={{ cursor: "pointer" }} className="pb-2">
              <DatePicker
                onChange={handleSingleDate}
                format={dateFormat}
                placeholder="Single Date"
              />
            </div>

            <div key="3">
              <RangePicker format={dateFormat} onChange={handleDateRange} />
            </div>

            <Input
              key="4"
              bordered={false}
              placeholder="Search by amount..."
              style={{
                width: 200,
                height: 35,
                textAlign: "right",
                border: `0.1px solid rgba(9, 66, 125, 0.123)`,
                backgroundColor: `#fff`,
                borderRadius: 5,
              }}
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              suffix={
                is_loading ? (
                  <LoadingOutlined />
                ) : (
                  <CloseCircleFilled
                    onClick={() => {
                      setSearchValue("");
                    }}
                  />
                )
              }
              autoFocus
              type="Number"
              controls={false}
              prefix="₦"
              onPressEnter={() =>
                fetchMerchantWalletTxns({
                  page: 1,
                  limit: 10,
                  total: store.get("txn_pagination").total,
                  filters: ["credit", "debit"],
                  single_date: null,
                  end_date: null,
                  start_date: null,
                  all_time: true,
                })
              }
            />
          </Space>
        </div>
      );
    };

    let generateTxnHistoryCSV = async () => {
      setTargetLoader("txn_history_csv");

      try {
        let res = await generateMerchantTxnHistoryCSV(
          {
            single_date: store.get("txn_pagination").single_date,
            start_date: store.get("txn_pagination").start_date,
            end_date: store.get("txn_pagination").end_date,
            all_time: store.get("txn_pagination").all_time,
          },
          merchant_id
        );

        notification.info({
          message: "SUCCESS",
          description: res.data.message,
        });

        setTargetLoader("");
      } catch (err) {
        console.log("err:", err);
        if (
          err &&
          err.response &&
          (err.response.status === 401 || err.response.status === 403)
        )
          handleLogout();

        notification.info({
          message: "SUCCESS",
          description: err.message,
        });

        setTargetLoader("");
      }
    };

    let downloadHistory = async (type) => {
      NProgress.inc();
      let filename;

      try {
        fetch(
          `${baseUrl}/service/demo/v1/query/admin/merchant/txn_history_pdf/` +
            merchant_id,
          {
            method: "POST",
            body: JSON.stringify({
              single_date: store.get("txn_pagination").single_date,
              start_date: store.get("txn_pagination").start_date,
              end_date: store.get("txn_pagination").end_date,
              all_time: store.get("txn_pagination").all_time,
              type: type,
            }),
            headers: {
              "Content-disposition": "attachment; filename=$filename.pdf",
              "b-access-token": sessionStorage.getItem("dartpay_token"),
              publicKey: publicKey,
              requestId: requestId,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then(async (response) => {
            let file__name = response.headers.get("file_name");

            if (file__name) filename = file__name;
            else {
              let fn =
                active_merchant.businessname + " Transaction History.pdf";
              filename = fn.replace(/\s+/g, "_");
            }

            if (response.status !== 200) {
              let res = await response.json();

              notification.info({
                message: "ERROR",
                description: res.message,
              });
            } else {
              return response.arrayBuffer();
            }
          })
          .then((result) => {
            if (result) {
              let pdf_file = new Blob([result], { type: "application/pdf" });

              let download_link = document.createElement("a");

              download_link.download = filename;
              download_link.href = window.URL.createObjectURL(pdf_file);

              download_link.style.display = "none";
              document.body.appendChild(download_link);
              download_link.click();
            }
          })
          .finally(() => {
            NProgress.done();
          });
      } catch (error) {
        console.log("error download history: ", error);
        NProgress.done();
      }
    };

    let generateTxnHistory = async (type) => {
      NProgress.inc();

      try {
        let res = await generateMerchantTxnHistory(
          {
            single_date: store.get("txn_pagination").single_date,
            start_date: store.get("txn_pagination").start_date,
            end_date: store.get("txn_pagination").end_date,
            all_time: store.get("txn_pagination").all_time,
            type: type,
          },
          merchant_id
        );

        NProgress.done();

        notification.info({
          message: "SUCCESS",
          description: res.data.message,
        });

        setTargetLoader("");
      } catch (err) {
        NProgress.done();
        console.log("err:", err);
        if (
          err &&
          err.response &&
          (err.response.status === 401 || err.response.status === 403)
        )
          handleLogout();

        notification.info({
          message: "ERROR",
          description: err.message,
        });

        setTargetLoader("");
      }
    };

    const menu = (
      <Menu>
        <Menu.Item key="0">
          <Button
            disabled={
              !merchant_txns.txns.length ||
              logged_in_admin.role_level < ROLES.super_admin.level
            }
            loading={target_loader === "txn_history_m"}
            onClick={() => {
              generateTxnHistory("mail");
            }}
          >
            Send to merchant
          </Button>
        </Menu.Item>

        <Menu.Item key="1">
          <Button
            disabled={
              !merchant_txns.txns.length ||
              logged_in_admin.role_level < ROLES.super_admin.level
            }
            loading={target_loader === "txn_history_d"}
            onClick={() => {
              downloadHistory("download");
            }}
          >
            Download
          </Button>
        </Menu.Item>
      </Menu>
    );

    return (
      <div className="px-5">
        <div className="my-4 d-flex">
          <Button
            className="pt-0 pl-0"
            type="link"
            onClick={() => {
              setTargetModal({ drawer: "" });
              store.set("txn_pagination", {
                page: 1,
                limit: 10,
                total: store.get("txn_pagination").total,
                filters: ["credit", "debit"],
                single_date: null,
                end_date: null,
                start_date: null,
                all_time: true,
              });
            }}
          >
            <h6>
              <CloseOutlined />
            </h6>
          </Button>

          <h6>Merchant Transactions</h6>
        </div>

        <div
          className="d-flex mb-4"
          style={{ justifyContent: "space-between" }}
        >
          <FilterMenu />

          <div style={{ margin: "auto 0 0" }}>
            <Dropdown overlay={menu} trigger={["click"]} className="mr-2">
              <Button
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                Export history as pdf
                <DownOutlined />
              </Button>
            </Dropdown>

            <Button
              disabled={!merchant_txns.txns.length}
              loading={target_loader === "txn_history_csv"}
              onClick={generateTxnHistoryCSV}
            >
              Export history as CSV
            </Button>
          </div>
        </div>

        <div className="mb-4">
          <div>
            <strong>Total Credit: </strong>
            {numeral(merchant_txns.total_credit).format("0,00")}
          </div>

          <div>
            <strong>Total Debit: </strong>
            {numeral(merchant_txns.total_debit).format("0,00")}
          </div>

          <div>
            <strong>Total Transactions: </strong>
            {numeral(
              Number(merchant_txns.total_credit.toFixed(2)) +
                Number(merchant_txns.total_debit)
            ).format("0,00")}
          </div>
        </div>

        <Table
          columns={txns_columns}
          dataSource={merchant_txns.txns}
          bordered
          size="small"
          //className="to_disable_empty"
          key={(_, idx) => idx}
          onChange={handleTxnTable}
          pagination={{
            total: store.get("txn_pagination").total,
            pageSize: store.get("txn_pagination").limit,
            current: store.get("txn_pagination").page,
          }}
          loading={is_loading && target_loader === "txns"}
        />
      </div>
    );
  };

  const MerchantBillings = () => {
    let handleTxnTable = (event) => {
      let data = {
        page: event.current,
        limit: event.pageSize,
        all_time: store.get("txn_pagination").all_time,
        total: store.get("txn_pagination").total,
        single_date: store.get("txn_pagination").single_date,
        start_date: store.get("txn_pagination").start_date,
        end_date: store.get("txn_pagination").end_date,
      };

      store.set("txn_pagination", data);

      fetchAirtimeBillings(data);
    };

    let handleSingleDate = (event) => {
      let single_date = event.format(dateFormat);

      let data = {
        page: 1,
        limit: 10,
        total: store.get("txn_pagination").total,
        single_date,
        end_date: null,
        start_date: null,
        all_time: false,
      };

      store.set("txn_pagination", data);

      fetchAirtimeBillings(data);
    };

    let handleDateRange = (event) => {
      let start_date = event[0].format(dateFormat);
      let end_date = event[1].format(dateFormat);

      let data = {
        page: 1,
        limit: 10,
        total: store.get("txn_pagination").total,
        single_date: null,
        end_date,
        start_date,
        all_time: false,
      };

      store.set("txn_pagination", data);

      fetchAirtimeBillings(data);
    };

    let billings_columns = [
      {
        title: "Date",
        dataIndex: "timestamp",
        render: (_, record) => (
          <span>{new Date(record.timestamp).toLocaleString()}</span>
        ),
        key: "timestamp",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        render: (_, record) => (
          <span>{numeral(record.amount).format("0,0.00")}</span>
        ),
        key: "address",
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (status) => (
          <Tag color={status === "success" ? "green" : "red"}>
            {status === "success" ? "SUCCESS" : "FAILED"}
          </Tag>
        ),
        key: "type",
      },
      {
        title: "Classification",
        dataIndex: "classification",
        render: (classification) => <span>{classification.toUpperCase()}</span>,
        key: "type",
      },
    ];

    let FilterMenu = () => {
      return (
        <div className="mb-4">
          <Space direction="vertical">
            <Button
              type="link"
              key="1"
              className="px-0"
              onClick={() => {
                store.set("txn_pagination", {
                  page: 1,
                  limit: 10,
                  total: store.get("txn_pagination").total,
                  single_date: null,
                  end_date: null,
                  start_date: null,
                  all_time: true,
                });

                fetchAirtimeBillings({
                  page: 1,
                  limit: 10,
                  total: store.get("txn_pagination").total,
                  single_date: null,
                  end_date: null,
                  start_date: null,
                  all_time: true,
                });
              }}
            >
              All Time
            </Button>

            <div key="2" style={{ cursor: "pointer" }} className="pb-2">
              <DatePicker
                onChange={handleSingleDate}
                format={dateFormat}
                placeholder="Single Date"
              />
            </div>

            <div key="3">
              <RangePicker format={dateFormat} onChange={handleDateRange} />
            </div>
          </Space>
        </div>
      );
    };

    return (
      <div className="px-5">
        <div className="my-4 d-flex">
          <Button
            className="pt-0 pl-0"
            type="link"
            onClick={() => {
              setTargetModal({ drawer: "" });
            }}
          >
            <h6>
              <CloseOutlined />
            </h6>
          </Button>

          <h6>Merchant airtime billings</h6>
        </div>

        <FilterMenu />

        <Table
          columns={billings_columns}
          dataSource={merchant_billings.billings}
          bordered
          size="small"
          //className="to_disable_empty"
          key={(_, idx) => idx}
          onChange={handleTxnTable}
          pagination={{
            total: store.get("txn_pagination").total,
            pageSize: store.get("txn_pagination").limit,
            current: store.get("txn_pagination").page,
          }}
          loading={target_loader === "merchant_billings"}
        />
      </div>
    );
  };

  const AccountStatus = () => {
    return (
      <Col md={24}>
        <PageHeader className="site-page-header px-0" title="Account Status" />

        <Row>
          <Col span={12}>
            <DescriptionItem
              title="Upgraded "
              content={
                active_merchant.verified ? (
                  <Tag className="h-100" color="green">
                    TRUE
                  </Tag>
                ) : (
                  <Tag className="h-100" color="volcano">
                    FALSE
                  </Tag>
                )
              }
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title="Investigate "
              content={
                active_merchant.investigate ? (
                  <Tag className="h-100" color="green">
                    TRUE
                  </Tag>
                ) : (
                  <Tag className="h-100" color="volcano">
                    FALSE
                  </Tag>
                )
              }
            />
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <DescriptionItem
              title="Account Balance "
              content={
                merchant_details.wallet_info &&
                merchant_details.wallet_info.accountbalance ? (
                  <Tag className="h-100" color="green">
                    {numeral(
                      merchant_details.wallet_info.accountbalance
                    ).format("0,0.00")}
                  </Tag>
                ) : (
                  <Tag className="h-100" color="volcano">
                    0
                  </Tag>
                )
              }
            />
          </Col>

          <Col span={12}>
            <DescriptionItem
              title="Bank Status "
              content={
                merchant_details.bank &&
                merchant_details.bank.underreview === false ? (
                  <Tag className="h-100" color="green">
                    Verified
                  </Tag>
                ) : (
                  <Tag className="h-100" color="volcano">
                    Unverified
                  </Tag>
                )
              }
            />
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <DescriptionItem
              title="Lien Balance"
              content={
                merchant_details.wallet_info &&
                merchant_details.wallet_info.lienbalance ? (
                  <Tag className="h-100" color="green">
                    {numeral(merchant_details.wallet_info.lienbalance).format(
                      "0,0.00"
                    )}
                  </Tag>
                ) : (
                  <Tag className="h-100" color="volcano">
                    0
                  </Tag>
                )
              }
            />
          </Col>

          <Col span={12}>
            <DescriptionItem
              title="Lien Date"
              content={
                merchant_details.wallet_info &&
                merchant_details.wallet_info.liendate ? (
                  <span>
                    {new Date(
                      merchant_details.wallet_info.liendate
                    ).toDateString()}
                  </span>
                ) : (
                  <span>N/A</span>
                )
              }
            />
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <DescriptionItem
              title="Post No Credit Status "
              content={
                active_merchant.postnocredit ? (
                  <Tag className="h-100" color="green">
                    TRUE
                  </Tag>
                ) : (
                  <Tag className="h-100" color="volcano">
                    FALSE
                  </Tag>
                )
              }
            />
          </Col>

          <Col span={12}>
            <DescriptionItem
              title="Post No Debit Status "
              content={
                active_merchant.postnodebit ? (
                  <Tag className="h-100" color="green">
                    TRUE
                  </Tag>
                ) : (
                  <Tag className="h-100" color="volcano">
                    FALSE
                  </Tag>
                )
              }
            />
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <DescriptionItem
              title="BVN OTP Trials "
              content={
                <Tag className="h-100" color="green">
                  {merchant_details.bvnotptrials || 0}
                </Tag>
              }
            />
          </Col>

          <Col span={12}>
            <DescriptionItem
              title="Exempt From BVN OTP "
              content={
                merchant_details.exemptbvnotp ? (
                  <Tag className="h-100" color="green">
                    TRUE
                  </Tag>
                ) : (
                  <Tag className="h-100" color="volcano">
                    FALSE
                  </Tag>
                )
              }
            />
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <DescriptionItem
              title="BVN Submission Count "
              content={
                <Tag className="h-100" color="green">
                  {merchant_details.bvn_submission_count || 0}
                </Tag>
              }
            />
          </Col>

          <Col span={12}>
            <DescriptionItem
              title="NIN Submission Count "
              content={
                <Tag className="h-100" color="green">
                  {merchant_details.nin_submission_count || 0}
                </Tag>
              }
            />
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            {merchant_details.bvn_record_phone_match === "N/A" && (
              <DescriptionItem
                title="BVN Phone And Merchant Phone Match "
                content={
                  <Tag className="h-100" color="red">
                    N/A
                  </Tag>
                }
              />
            )}
            {merchant_details.bvn_record_phone_match === true && (
              <DescriptionItem
                title="BVN Phone And Merchant Phone Match "
                content={
                  <Tag className="h-100" color="green">
                    TRUE
                  </Tag>
                }
              />
            )}
            {merchant_details.bvn_record_phone_match === false && (
              <DescriptionItem
                title="BVN Phone And Merchant Phone Match "
                content={
                  <Tag className="h-100" color="red">
                    FALSE
                  </Tag>
                }
              />
            )}
          </Col>

          <Col span={12}>
            <DescriptionItem
              title="Last BVN OTP Submission Status "
              content={
                merchant_details.last_bvn_submission === "EXPIRED" ||
                merchant_details.last_bvn_submission === "N/A" ? (
                  <Tag className="h-100" color="red">
                    {merchant_details.last_bvn_submission}
                  </Tag>
                ) : (
                  <Tag className="h-100" color="green">
                    {(merchant_details.last_bvn_submission &&
                      merchant_details.last_bvn_submission.toFixed(2)) ||
                      0}{" "}
                    minutes ago.
                  </Tag>
                )
              }
            />
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <DescriptionItem
              title="Exempt Status "
              content={
                merchant_details.exempt ? (
                  <Tag className="h-100" color="green">
                    TRUE
                  </Tag>
                ) : (
                  <Tag className="h-100" color="volcano">
                    FALSE
                  </Tag>
                )
              }
            />
          </Col>

          <Col span={12}>
            <DescriptionItem
              title="Phone Number Verified "
              content={
                merchant_details.issetNIN_BVN ? (
                  <Tag className="h-100" color="green">
                    TRUE
                  </Tag>
                ) : (
                  <Tag className="h-100" color="volcano">
                    FALSE
                  </Tag>
                )
              }
            />
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <DescriptionItem
              title="Business Name In Black List "
              content={
                merchant_details._in_blacklist ? (
                  <Tag className="h-100" color="green">
                    TRUE
                  </Tag>
                ) : (
                  <Tag className="h-100" color="volcano">
                    FALSE
                  </Tag>
                )
              }
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title="Lien Narrative "
              content={
                merchant_details.wallet_info &&
                merchant_details.wallet_info.liennarrative ? (
                  <span>{merchant_details.wallet_info.liennarrative}</span>
                ) : (
                  "N/A"
                )
              }
            />
          </Col>
        </Row>

        <Divider />

        <Tooltip
          title={logged_in_admin.role_level < ROLES.super_admin.level ? "" : ""}
        >
          <Button
            className="mr-2"
            disabled={
              !merchant_details ||
              merchant_details.issetNIN_BVN ||
              logged_in_admin.role_level < ROLES.verifier.level
            }
            htmlType="button"
            type="primary"
            loading={is_loading && target_loader === "verify_phone"}
            onClick={handleVerifyBVNPhone}
          >
            Verify merchant phone
          </Button>

          <Button
            disabled={
              !merchant_details ||
              !merchant_details.bank ||
              !merchant_details.bank.code ||
              !merchant_details.bank.underreview ||
              logged_in_admin.role_level < ROLES.super_admin.level
            }
            htmlType="button"
            className="mr-2"
            loading={target_loader === "verify_bank"}
            onClick={activateBankHandler}
          >
            Update merchant bank status
          </Button>

          <Button
            disabled={
              !merchant_details ||
              !merchant_details.postnodebit ||
              logged_in_admin.role_level < ROLES.super_admin.level
            }
            htmlType="button"
            loading={target_loader === "pnd"}
            onClick={makePNDFalse}
          >
            Make PND false
          </Button>
        </Tooltip>
        <Divider />
      </Col>
    );
  };

  const AccountInformation = () => {
    const { confirm } = Modal;
    const [aggregatorCode, setAggregatorCode] = useState("");
    let aggregator = null;
    const [loading, setLoading] = useState(false);

    const handleAddMember = async () => {
      let data = {
        aggregatorcode: aggregatorCode,
        merchantid: merchant_details._id,
      };
      try {
        setLoading(true);
        await addMember(data);
        setLoading(false);
        notification.success({
          message: "Success",
          description: "Aggregator added successfully",
        });
        fetchMerchantInfo(merchant_id);
      } catch (error) {
        notification.error({
          message: "ERROR",
          description: error.response
            ? error.response.data?.message
            : "An error occured. Please try again.",
        });
        setLoading(false);
      }
    };

    function showPromiseConfirm() {
      confirm({
        title: "Are you sure you want to add this aggregator?",
        icon: <ExclamationCircleOutlined />,
        content: `Aggregator Business Name: ${
          aggregator?.businessname || "N/A"
        }`,
        async onOk() {
          try {
            return handleAddMember();
          } catch {
            return console.log("Oops errors!");
          }
        },
        onCancel() {},
      });
    }

    const fetchAggregator = async () => {
      if (!aggregatorCode) return;
      try {
        setLoading(true);
        const res = await getAggregator({ code: aggregatorCode });
        aggregator = res.data?.data;
        showPromiseConfirm();
        setLoading(false);
        setAggregatorCode("");
      } catch (error) {
        notification.error({
          message: "ERROR",
          description: error.response
            ? error.response.data?.message
            : "An error occured. Please try again.",
        });
        setLoading(false);
      }
    };

    return (
      <Col md={24}>
        <PageHeader
          className="site-page-header px-0"
          title="Account Information"
        />

        <Row>
          <Col span={12}>
            <DescriptionItem
              title="Personal name "
              content={
                <span style={{ fontSize: "13px" }}>
                  {merchant_details?.contactfirstname &&
                    merchant_details?.contactfirstname +
                      " " +
                      merchant_details.contactlastname}
                </span>
              }
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title="Industry "
              content={
                <span style={{ fontSize: "13px" }}>
                  {merchant_details?.industry &&
                    merchant_details?.industry.toLocaleUpperCase()}
                </span>
              }
            />
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <DescriptionItem
              title="Address "
              content={
                <span style={{ fontSize: "13px" }}>
                  {merchant_details?.address}
                </span>
              }
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title="Classification "
              content={
                <span style={{ fontSize: "13px" }}>
                  {merchant_details?.classification &&
                    merchant_details.classification.toLocaleUpperCase()}
                </span>
              }
            />
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <DescriptionItem
              title="Business Email "
              content={
                <span style={{ fontSize: "13px" }}>
                  {merchant_details?.businessemail}
                </span>
              }
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title="Business Phone "
              content={
                <span style={{ fontSize: "13px" }}>
                  {merchant_details?.businessphone}
                </span>
              }
            />
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <DescriptionItem
              title="Signup Date "
              content={
                <span style={{ fontSize: "13px" }}>
                  {merchant_details?.createdon
                    ? new Date(merchant_details.createdon).toDateString()
                    : "N/A"}
                </span>
              }
            />
          </Col>

          <Col span={12}>
            <DescriptionItem
              title="Date Upgraded "
              content={
                <span style={{ fontSize: "13px" }}>
                  {merchant_details?.verifiedon
                    ? new Date(merchant_details.verifiedon).toDateString()
                    : "N/A"}
                </span>
              }
            />
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <DescriptionItem
              title="Personal Bank Account "
              content={
                <span style={{ fontSize: "13px" }}>
                  {merchant_details?.bank &&
                  Object.keys(merchant_details?.bank).length
                    ? merchant_details.bank.code +
                      " | " +
                      merchant_details.bank.number +
                      " | " +
                      merchant_details.bank.name
                    : "N/A"}
                </span>
              }
            />
          </Col>
          {merchant_details?.aggregatorcode && (
            <Col span={12}>
              <DescriptionItem
                title="Aggregator Code "
                content={
                  <span style={{ fontSize: "13px" }}>
                    {merchant_details.aggregatorcode}
                  </span>
                }
              />
            </Col>
          )}
          {!merchant_details?.aggregatorcode &&
            merchant_details?.classification?.toLowerCase().includes("pos") && (
              <Col span={12}>
                <Input
                  className="aggregator-input"
                  placeholder="Enter Aggregator Code"
                  value={aggregatorCode}
                  onChange={(e) => setAggregatorCode(e.target.value)}
                />
                <Button
                  loading={loading}
                  type="primary"
                  onClick={() => fetchAggregator()}
                >
                  Add as Member
                </Button>
              </Col>
            )}
        </Row>
      </Col>
    );
  };

  return (
    <section className="dashboard">
      <div className={`side-wrap open`}>
        <Sidebar match={match} setName={setName} />
      </div>

      <div className="dash-contents" style={{ padding: 50 }}>
        <section id="tabs">
          <div className="text-right py-2">
            <div className="font-weight-bold">{logged_in_admin.username}</div>
            <small>
              {logged_in_admin.role && logged_in_admin.role.toUpperCase()}
            </small>
          </div>

          <div className="container">
            <h4 className="section-title">
              <Button
                style={{ fontSize: "20px" }}
                className="pl-0"
                type="link"
                onClick={() => {
                  history.goBack();
                }}
              >
                <ArrowLeftOutlined />
              </Button>
              Merchant Name:{" "}
              <span style={{ color: "darkgray" }}>
                {active_merchant.businessname}
              </span>
            </h4>
            <Divider />

            <Row gutter={30}>
              <AccountInformation />

              <AccountStatus />

              <Col md={24}>
                <PageHeader
                  className="site-page-header px-0"
                  title="Restriction Information"
                />

                <Row>
                  <Col span={12}>
                    <DescriptionItem
                      title="Confirmed Limit "
                      content={
                        merchant_details?.wallet_info &&
                        merchant_details?.wallet_info?.confirmedlimit ? (
                          <Tag className="h-100" color="green">
                            {numeral(
                              merchant_details?.wallet_info?.confirmedlimit
                            ).format("0,0.00")}
                          </Tag>
                        ) : (
                          <Tag className="h-100" color="volcano">
                            0
                          </Tag>
                        )
                      }
                    />
                  </Col>
                  <Col span={12}>
                    <DescriptionItem
                      title="Unconfirmed Limit "
                      content={
                        merchant_details?.wallet_info &&
                        merchant_details?.wallet_info?.unconfirmedlimit ? (
                          <Tag className="h-100" color="green">
                            {numeral(
                              merchant_details?.wallet_info?.unconfirmedlimit
                            ).format("0,0.00")}
                          </Tag>
                        ) : (
                          <Tag className="h-100" color="volcano">
                            0
                          </Tag>
                        )
                      }
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <DescriptionItem
                      title="Restriction "
                      content={
                        merchant_details?.wallet_info &&
                        merchant_details?.wallet_info.restrict === "daily" ? (
                          <Tag className="h-100" color="green">
                            DAILY
                          </Tag>
                        ) : (
                          <Tag className="h-100" color="green">
                            TOTAL
                          </Tag>
                        )
                      }
                    />
                  </Col>

                  <Col span={12}>
                    <DescriptionItem
                      title="Investigate Above "
                      content={
                        merchant_details?.wallet_info &&
                        merchant_details?.wallet_info.investigateabove ? (
                          <Tag className="h-100" color="green">
                            {numeral(
                              merchant_details?.wallet_info.investigateabove
                            ).format("0,0.00")}
                          </Tag>
                        ) : (
                          <Tag className="h-100" color="volcano">
                            0
                          </Tag>
                        )
                      }
                    />
                  </Col>
                </Row>

                <Divider />

                <Row>
                  <Col span={24}>
                    <Collapse accordion onChange={handleRestrictInfoPopulate}>
                      <Collapse.Panel
                        header="Update Restriction Information"
                        key="7"
                        showArrow={false}
                      >
                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item>
                              <p className="label mb-0">Restriction Level</p>
                              <Select
                                className="st_1"
                                placeholder="Select restriction level."
                                name="restriction_level"
                                value={user_inputs.restriction_level}
                                onChange={(e) => {
                                  setMetaDataInputs({
                                    ...user_inputs,
                                    restriction_level: e,
                                  });
                                }}
                                allowClear
                              >
                                <Select.Option disabled value="">
                                  Restriction Level
                                </Select.Option>
                                <Select.Option value="total">
                                  Total
                                </Select.Option>
                                <Select.Option value="daily">
                                  Daily
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col span={12}>
                            <Form.Item>
                              <p className="label mb-0">Investigate Above</p>
                              <Input
                                className="st_1"
                                placeholder="Investigate Above"
                                name="investigate_above"
                                value={user_inputs.investigate_above}
                                onChange={handleMetaDataInput}
                                allowClear
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item>
                              <p className="label mb-0">Confirmed Limit</p>
                              <Input
                                className="st_1"
                                placeholder="Confirmed Limit"
                                name="confirmed_limit"
                                value={user_inputs.confirmed_limit}
                                onChange={handleMetaDataInput}
                                allowClear
                              />
                            </Form.Item>
                          </Col>

                          <Col span={12}>
                            <Form.Item>
                              <p className="label mb-0">Unconfirmed Limit</p>
                              <Input
                                className="st_1"
                                placeholder="Unconfirmed Limit"
                                name="unconfirmed_limit"
                                value={user_inputs.unconfirmed_limit}
                                onChange={handleMetaDataInput}
                                allowClear
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row>
                          <Tooltip
                            title={
                              logged_in_admin.role_level <
                              ROLES.super_admin.level
                                ? "You need to be a super admin to perform this operation."
                                : ""
                            }
                          >
                            <Button
                              disabled={
                                logged_in_admin.role_level <
                                ROLES.super_admin.level
                              }
                              type="primary"
                              onClick={updateRestrictionInfo}
                              loading={
                                is_loading && target_loader === "restriction"
                              }
                            >
                              Submit
                            </Button>
                          </Tooltip>
                        </Row>
                      </Collapse.Panel>
                    </Collapse>
                  </Col>
                </Row>

                <Divider />

                <Tooltip
                  title={
                    logged_in_admin.role_level < ROLES.super_admin.level
                      ? ""
                      : ""
                  }
                >
                  <Button
                    disabled={
                      active_merchant.investigate ||
                      logged_in_admin.role_level < ROLES.super_admin.level
                    }
                    onClick={() => {
                      confirmModal("investigate");
                    }}
                    loading={is_loading && target_loader === "investigate"}
                    className="mr-2"
                    type="primary"
                    danger
                  >
                    Investigate Merchant
                  </Button>
                  <Button
                    disabled={
                      !active_merchant.verified ||
                      logged_in_admin.role_level < ROLES.super_admin.level
                    }
                    className="mr-2"
                    onClick={() => {
                      confirmModal("downgrade");
                    }}
                    loading={is_loading && target_loader === "unverify"}
                    danger
                  >
                    Downgrade Merchant
                  </Button>
                </Tooltip>

                <Button
                  disabled={
                    merchant_details?.exemptbvnotp ||
                    merchant_details?.bvn_record_phone_match === true ||
                    logged_in_admin.role_level < ROLES.verifier.level
                  }
                  className="mr-2"
                  onClick={() => {
                    confirmModal("exemptbvnotp");
                  }}
                  loading={is_loading && target_loader === "exemptbvnotp"}
                  type="primary"
                  danger
                >
                  Exempt from BVN OTP
                </Button>

                <Button
                  disabled={
                    logged_in_admin.role_level < ROLES.super_admin.level
                  }
                  className="mr-2"
                  onClick={() => {
                    confirmModal("postnocredit");
                  }}
                  loading={is_loading && target_loader === "postnocredit"}
                  danger
                >
                  Update Post No Credit
                </Button>
                <Button
                  disabled={
                    logged_in_admin.role_level < ROLES.super_admin.level
                  }
                  className="mr-2"
                  onClick={() => {
                    confirmModal("exempt_merchant");
                  }}
                  loading={is_loading && target_loader === "exempt_merchant"}
                  type="primary"
                  danger
                >
                  Update Exempt Status
                </Button>
                <Button
                  disabled={
                    logged_in_admin.role_level < ROLES.super_admin.level
                  }
                  className="mr-2 mt-3"
                  onClick={() => {
                    confirmModal("deactivate_merchant");
                  }}
                  loading={target_loader === "deactivate_merchant"}
                  danger={merchant_details?.postnocredit ? false : true}
                >
                  {merchant_details?.postnocredit
                    ? "Activate Merchant"
                    : "Deactivate Merchant"}
                </Button>

                <Divider />

                <Collapse accordion>
                  <Collapse.Panel
                    collapsible={
                      logged_in_admin.role_level < ROLES.verifier.level
                    }
                    header="Add Data"
                    key="1"
                    showArrow={false}
                  >
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item>
                          <p className="label mb-0">Type</p>
                          <Select
                            className="st_1"
                            placeholder="Select a type."
                            name="meta_type"
                            value={user_inputs.meta_type}
                            onChange={onSelectChange}
                            allowClear
                          >
                            <Select.Option disabled value="">
                              Select a type
                            </Select.Option>
                            <Select.Option value="Phone">Phone</Select.Option>
                            <Select.Option value="Email">Email</Select.Option>
                            <Select.Option value="Social">Social</Select.Option>
                            <Select.Option value="Address">
                              Address
                            </Select.Option>
                            <Select.Option value="Name">Name</Select.Option>
                            <Select.Option value="Website">
                              Website
                            </Select.Option>
                            <Select.Option value="NIN">NIN</Select.Option>
                            <Select.Option value="BVN">BVN</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item>
                          <p className="label mb-0">Data Source</p>
                          <Select
                            className="st_1"
                            placeholder="Select a type."
                            name="meta_source"
                            value={user_inputs.meta_source}
                            onChange={(value) => {
                              setMetaDataInputs((prev) => ({
                                ...prev,
                                meta_source: value,
                              }));
                            }}
                            allowClear
                          >
                            <Select.Option disabled value="">
                              Select source
                            </Select.Option>
                            <Select.Option value="Instagram">
                              Instagram
                            </Select.Option>
                            <Select.Option value="Facebook">
                              Facebook
                            </Select.Option>
                            <Select.Option value="Phone Call">
                              Phone Call
                            </Select.Option>
                            <Select.Option value="WhatsApp">
                              WhatsApp
                            </Select.Option>
                            <Select.Option value="DSA">DSA</Select.Option>
                            <Select.Option value="Email">Email</Select.Option>
                            <Select.Option value="Telegram">
                              Telegram
                            </Select.Option>
                            <Select.Option value="Slack">Slack</Select.Option>
                            <Select.Option value="LinkedIn">
                              LinkedIn
                            </Select.Option>
                            <Select.Option value="Others">Others</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    {user_inputs.meta_source === "Others" && (
                      <Row gutter={16} className="mb-3">
                        <Col span={12}>
                          <p className="label mb-0">Source</p>
                          <Input
                            className="st_1"
                            placeholder="Enter Source"
                            name="meta_source_other"
                            value={user_inputs.meta_source_other}
                            onChange={handleMetaDataInput}
                            allowClear
                          />
                        </Col>
                      </Row>
                    )}

                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item>
                          <p className="label mb-0">Data</p>
                          <Input
                            className="st_1"
                            placeholder="Enter Meta Data"
                            name="meta_data"
                            value={user_inputs.meta_data}
                            onChange={handleMetaDataInput}
                            allowClear
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Button
                        disabled={
                          !user_inputs.meta_data ||
                          !user_inputs.meta_source ||
                          !user_inputs.meta_type
                        }
                        type="primary"
                        onClick={submitMetaData}
                        loading={is_loading && target_loader === "meta_data"}
                      >
                        Submit
                      </Button>
                    </Row>
                  </Collapse.Panel>
                </Collapse>

                <Divider />

                <Collapse accordion>
                  <Collapse.Panel
                    header="View Meta Data"
                    key="2"
                    showArrow={false}
                  >
                    <Row className="mb-3">
                      <Col span={18}>
                        <Table
                          columns={meta_columns}
                          dataSource={merchant_details?.meta}
                          bordered
                          size="small"
                          pagination={false}
                          title={() => <h6>Meta data</h6>}
                          className="to_disable_empty"
                          key={(idx) => idx._id}
                        />
                      </Col>
                    </Row>
                  </Collapse.Panel>
                </Collapse>

                <Divider />

                <Row>
                  <Col span={24}>
                    <Collapse accordion onChange={handleMerchantInfoPopulate}>
                      <Collapse.Panel
                        header="Update Merchant Information"
                        key="7"
                        showArrow={false}
                      >
                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item>
                              <p className="label mb-0">Industry</p>
                              <Select
                                className="st_1"
                                placeholder="Select industry."
                                name="industry"
                                value={user_inputs.industry}
                                onChange={(e) => {
                                  setMetaDataInputs({
                                    ...user_inputs,
                                    industry: e,
                                  });
                                }}
                                allowClear
                              >
                                <Select.Option disabled value="">
                                  Industry
                                </Select.Option>

                                {INDUSTRIES.map((ind, idx) => {
                                  return (
                                    <Select.Option value={ind} key={idx}>
                                      {ind}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col span={12}>
                            <Form.Item>
                              <p className="label mb-0">Address</p>
                              <Input
                                className="st_1"
                                placeholder="Merchant Address"
                                name="address"
                                value={user_inputs.address}
                                onChange={handleMetaDataInput}
                                allowClear
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item>
                              <p className="label mb-0">Business Name</p>
                              <Input
                                className="st_1"
                                placeholder="Business Name"
                                name="business_name"
                                value={user_inputs.business_name}
                                onChange={handleMetaDataInput}
                                allowClear
                              />
                            </Form.Item>
                          </Col>

                          <Col span={12}>
                            <Form.Item>
                              <p className="label mb-0">Business Email</p>
                              <Input
                                className="st_1"
                                placeholder="Business Email"
                                name="business_email"
                                value={user_inputs.business_email}
                                onChange={handleMetaDataInput}
                                allowClear
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item>
                              <p className="label mb-0">Referral Code</p>
                              <Input
                                className="st_1"
                                placeholder="Referral Code"
                                name="referral_code"
                                value={user_inputs.referral_code}
                                onChange={handleMetaDataInput}
                                allowClear
                              />
                            </Form.Item>
                          </Col>

                          <Col span={12}>
                            <Form.Item>
                              <p className="label mb-0">Business Phone</p>
                              <Input
                                className="st_1"
                                placeholder="Business Phone"
                                name="business_phone"
                                value={user_inputs.business_phone}
                                onChange={handleMetaDataInput}
                                allowClear
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item>
                              <p className="label mb-0">Contact firstname</p>
                              <Input
                                className="st_1"
                                placeholder="Contact firstname"
                                name="contactfirstname"
                                value={user_inputs.contactfirstname}
                                onChange={handleMetaDataInput}
                                allowClear
                              />
                            </Form.Item>
                          </Col>

                          <Col span={12}>
                            <Form.Item>
                              <p className="label mb-0">Contact lastname</p>
                              <Input
                                className="st_1"
                                placeholder="Contact lastname"
                                name="contactlastname"
                                value={user_inputs.contactlastname}
                                onChange={handleMetaDataInput}
                                allowClear
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row>
                          <Col span={12}>
                            <Form.Item>
                              <p className="label mb-0">Classification</p>
                              <Select
                                className="st_1"
                                placeholder="Select restriction level."
                                name="merchant_class"
                                value={user_inputs.merchant_class}
                                onChange={(e) => {
                                  setMetaDataInputs({
                                    ...user_inputs,
                                    merchant_class: e,
                                  });
                                }}
                                allowClear
                              >
                                <Select.Option disabled value="">
                                  Clasification
                                </Select.Option>

                                <Select.Option value="web">Web</Select.Option>
                                <Select.Option value="retail">
                                  Retail
                                </Select.Option>
                                <Select.Option value="api">API</Select.Option>
                                <Select.Option value="pos">POS</Select.Option>
                                <Select.Option value="telegram">
                                  Telegram
                                </Select.Option>
                                <Select.Option value="whatsapp">
                                  Whatsapp
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row>
                          <Tooltip
                            title={
                              logged_in_admin.role_level <
                              ROLES.super_admin.level
                                ? "You need to be a super admin to perform this operation."
                                : ""
                            }
                          >
                            <Button
                              disabled={
                                logged_in_admin.role_level <
                                ROLES.super_admin.level
                              }
                              type="primary"
                              onClick={updateMerchantInfoHandler}
                              loading={
                                is_loading &&
                                target_loader === "updatemerchantinfo"
                              }
                            >
                              Submit
                            </Button>
                          </Tooltip>
                        </Row>
                      </Collapse.Panel>
                    </Collapse>
                  </Col>
                </Row>

                <Divider />

                <Button
                  onClick={() => {
                    fetchMerchantWalletTxns();
                  }}
                  loading={is_loading && target_loader === "txns"}
                  className="mr-2"
                >
                  View merchant transactions
                </Button>
                <Button
                  onClick={fetchMerchantBiz}
                  loading={is_loading && target_loader === "merchant_biz"}
                  type="primary"
                  className="mr-2"
                >
                  View user businesses
                </Button>
                <Button
                  onClick={() => {
                    fetchCashpoints(1);
                  }}
                  loading={target_loader === "merchant_cashpoints"}
                  className="mr-2"
                >
                  View user cashpoints
                </Button>
                <Button
                  onClick={() => {
                    fetchAirtimeBillings();
                  }}
                  loading={target_loader === "merchant_billings"}
                  type="primary"
                  className="mr-2"
                >
                  View airtime billings
                </Button>
                <Button
                  onClick={() => {
                    setTargetModal({ drawer: "merchant_txn_search" });
                  }}
                  className="mr-2"
                >
                  Search transaction
                </Button>

                <Button
                  type="primary"
                  onClick={() => {
                    setTargetModal({ drawer: "user_live_video" });
                  }}
                  className="mr-2"
                >
                  View user live videos
                </Button>
                {/* new row */}
                <Divider />
                <Row>
                  <Button
                    type="primary"
                    onClick={() => {
                      setTargetModal({ drawer: "user_pnd_log" });
                    }}
                    className="mr-2"
                  >
                    View PND LOGs
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      setTargetModal({ drawer: "user_roles_live_video" });
                    }}
                    className="mr-2"
                  >
                    View User Roles Videos
                  </Button>
                </Row>
              </Col>
            </Row>

            <Divider />

            <div>
              <Radio.Group
                disabled={activetab !== "passport" && activetab !== "id_card"}
                onChange={onRadioChange}
                value={radio_value}
              >
                <Radio value={"front"}>Front</Radio>
                <Radio value={"back"}>Back</Radio>
                <Radio value={"selfie"}>Selfie</Radio>
              </Radio.Group>
            </div>

            <Divider />

            <MerchantDocumentTabs />
          </div>
        </section>

        <Drawer
          width="500px"
          title={
            target_modal.target === "passport"
              ? "International Passport"
              : target_modal.target === "id_card"
              ? "Identity Card"
              : target_modal.target === "video"
              ? "Liveliness Video"
              : target_modal.target === "pin_reset_video_reupload"
              ? "Pin Reset Liveliness Video Reupload"
              : target_modal.target === "pin_change_reupload"
              ? "PIN Change Liveliness Video Reupload"
              : target_modal.target === "password_reset_reupload"
              ? "Password Reset Liveliness Video Reupload"
              : target_modal.target === "password_change_reupload"
              ? "Password Change Liveliness Video Reupload"
              : target_modal.target === "limit_increase_reupload"
              ? "Limit Increase Liveliness Video Reupload"
              : target_modal.target === "roles_video_reupload"
              ? "Roles Video Re-upload"
              : target_modal.target === "roles_image_reupload"
              ? "Roles Image Re-upload"
              : "Proof of Address"
          }
          placement="right"
          open={target_modal.drawer === "kyc_documents"}
          closable={false}
        >
          {target_modal.target === "passport" && <PassportForm />}
          {target_modal.target === "id_card" && <IDCardForm />}
          {target_modal.target === "address_proof" && <AddressProofForm />}
          {target_modal.target === "video" && <LivelinessVideoForm />}
          {target_modal.target === "ammban" && <AMMBANCertificateForm />}
          {target_modal.target === "password-reset" && <PasswordReset />}
          {target_modal?.target === "cac_docs" && <CacDocForm />}
          {target_modal?.target === "pin_reset_video_reupload" && (
            <PasswordLivelinessVideoForm />
          )}
          {target_modal.target === "pin_change_reupload" && (
            <PasswordLivelinessChangeVideoForm />
          )}
          {target_modal?.target === "roles_video_reupload" && (
            <RolesLivelinessVideoForm />
          )}
          {target_modal?.target === "roles_image_reupload" && (
            <RolesLiveImage />
          )}
        </Drawer>

        <Drawer
          width="1200px"
          placement="right"
          visible={target_modal.drawer === "merchant_txn_search"}
          closable={false}
        >
          <div className="px-5">
            <div className="my-4 d-flex">
              <Button
                className="pt-0 pl-0"
                type="link"
                onClick={() => {
                  setTargetModal({ drawer: "" });
                }}
              >
                <h6>
                  <CloseOutlined />
                </h6>
              </Button>

              <h6>Verify Transaction</h6>
            </div>

            <Radio.Group
              name="outbound_inbound"
              defaultValue="outbound"
              buttonStyle="solid"
              onChange={handleRadioInput}
              value={_txn_type}
            >
              <Radio.Button value="outbound">Oubound</Radio.Button>
              <Radio.Button value="inbound">Inbound</Radio.Button>
              <Radio.Button value="sessionid">Session ID</Radio.Button>
            </Radio.Group>

            <Divider />

            {_txn_type === "outbound" && (
              <div>
                <Row gutter={16} className="">
                  <Col span={12}>
                    <p className="label mb-0">Receiver account number</p>
                    <Input
                      className="st_1"
                      placeholder="Enter receiver account number"
                      type="number"
                      name="r_account_number"
                      value={user_inputs.r_account_number}
                      onChange={handleMetaDataInput}
                      allowClear
                    />
                  </Col>

                  <Col span={12}>
                    <Form.Item>
                      <p className="label mb-0">Amount</p>
                      <Input
                        className="st_1"
                        placeholder="Enter amount"
                        type="number"
                        name="t_amount"
                        value={user_inputs.t_amount}
                        onChange={handleMetaDataInput}
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <p className="label mb-0">Transaction date</p>
                    <DatePicker
                      className="w-100"
                      name="t_date"
                      onChange={(event) => {
                        let _date = event.format("YYYY-MM-DD HH:mm");
                        setMetaDataInputs((prev) => ({
                          ...prev,
                          _date,
                          [user_inputs.t_date]: _date,
                        }));
                      }}
                      showTime={{ defaultValue: moment("00:00", "HH:mm") }}
                      format={"YYYY-MM-DD HH:mm"}
                      placeholder="Transaction date"
                    />
                  </Col>
                </Row>

                <div className="mt-3">
                  <Button
                    disabled={
                      !user_inputs._date ||
                      !user_inputs.t_amount ||
                      !user_inputs.r_account_number
                    }
                    type="primary"
                    onClick={searchOutboundTxn}
                    loading={target_loader === "outbound_txn"}
                  >
                    Submit
                  </Button>
                </div>

                <Divider />

                <Descriptions
                  title="Transaction details"
                  layout="vertical"
                  size="small"
                  labelStyle={{ fontWeight: "bold" }}
                >
                  <Descriptions.Item
                    className=""
                    label="Destination Account number"
                  >
                    {txn.outbound?.reciever_bank_number}
                  </Descriptions.Item>
                  <Descriptions.Item className="" label="Destination Bank">
                    {txn.outbound?.reciever_bank_code}
                  </Descriptions.Item>
                  <Descriptions.Item className="" label="Amount">
                    {txn.outbound?.amount}
                  </Descriptions.Item>

                  <Descriptions.Item className="" label="Session ID">
                    {txn.outbound?.session_id}
                  </Descriptions.Item>

                  <Descriptions.Item className="" label="Transaction Date">
                    {txn.outbound?.timestamp
                      ? new Date(txn.outbound.timestamp).toLocaleString()
                      : ""}
                  </Descriptions.Item>
                  {txn.outbound.status && (
                    <Descriptions.Item
                      className="font-weight-bold"
                      contentStyle={
                        txn.outbound.status === "00"
                          ? { color: "forestgreen" }
                          : { color: "orangered" }
                      }
                      label="Status"
                    >
                      {txn.outbound?.status === "00" ? "Successful" : "Failed"}
                    </Descriptions.Item>
                  )}
                </Descriptions>
              </div>
            )}

            {_txn_type === "inbound" && (
              <div>
                <Row gutter={16} className="">
                  <Col span={12}>
                    <p className="label mb-0">Sender account number</p>
                    <Input
                      className="st_1"
                      placeholder="Enter receiver account number"
                      type="number"
                      name="s_account_number"
                      value={user_inputs.s_account_number}
                      onChange={handleMetaDataInput}
                      allowClear
                    />
                  </Col>

                  <Col span={12}>
                    <p className="label mb-0">Merchant account number</p>
                    <Input
                      className="st_1"
                      placeholder="Enter merchant account number"
                      type="number"
                      name="r_account_number"
                      value={user_inputs.r_account_number}
                      onChange={handleMetaDataInput}
                      allowClear
                    />
                  </Col>
                </Row>

                <Row gutter={16} className="my-3">
                  <Col span={12}>
                    <p className="label mb-0">Amount</p>
                    <Input
                      className="st_1"
                      placeholder="Enter amount"
                      type="number"
                      name="t_amount"
                      value={user_inputs.t_amount}
                      onChange={handleMetaDataInput}
                      allowClear
                    />
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <p className="label mb-0">Transaction date</p>
                    <DatePicker
                      className="w-100"
                      name="t_date"
                      onChange={(event) => {
                        let _date = event.format("YYYY-MM-DD HH:mm");
                        setMetaDataInputs((prev) => ({
                          ...prev,
                          _date,
                          [user_inputs.t_date]: _date,
                        }));
                      }}
                      showTime={{ defaultValue: moment("00:00", "HH:mm") }}
                      format={"YYYY-MM-DD HH:mm"}
                      placeholder="Transaction date"
                    />
                  </Col>
                </Row>

                <div className="mt-3">
                  <Button
                    disabled={
                      !user_inputs._date ||
                      !user_inputs.t_amount ||
                      !user_inputs.r_account_number
                    }
                    type="primary"
                    onClick={searchInboundTxnFn}
                    loading={target_loader === "inbound_txn"}
                  >
                    Submit
                  </Button>
                </div>

                <Divider />

                <Descriptions
                  title="Transaction details"
                  layout="vertical"
                  size="small"
                  labelStyle={{ fontWeight: "bold" }}
                >
                  <Descriptions.Item
                    className=""
                    label="Destination Account number"
                  >
                    {txn.inbound?.account_number}
                  </Descriptions.Item>
                  <Descriptions.Item className="" label="Sender account name">
                    {txn.inbound?.originator_account_name}
                  </Descriptions.Item>
                  <Descriptions.Item className="" label="Sender account number">
                    {txn.inbound?.originator_account_number}
                  </Descriptions.Item>
                  <Descriptions.Item className="" label="Sender bank">
                    {txn.inbound?.originator_bank}
                  </Descriptions.Item>
                  <Descriptions.Item className="" label="Amount">
                    {txn.inbound?.amount}
                  </Descriptions.Item>
                  <Descriptions.Item className="" label="Transaction Date">
                    {txn.inbound?.timestamp
                      ? new Date(txn.inbound.timestamp).toLocaleString()
                      : ""}
                  </Descriptions.Item>
                </Descriptions>
              </div>
            )}

            {_txn_type === "sessionid" && (
              <div>
                <Row gutter={16} className="">
                  <Col span={12}>
                    <p className="label mb-0">Enter Session Id</p>
                    <Input
                      className="st_1"
                      placeholder="Enter transaction session id"
                      type="text"
                      name="sessionid"
                      value={user_inputs.sessionid}
                      onChange={handleMetaDataInput}
                      allowClear
                    />
                  </Col>
                </Row>
                <div className="mt-3">
                  <Button
                    disabled={!user_inputs.sessionid}
                    type="primary"
                    onClick={searchTxnWithSessionIdFn}
                    loading={target_loader === "sessionid"}
                  >
                    Submit
                  </Button>
                  <Divider />

                  <Descriptions
                    title="Transaction details"
                    layout="vertical"
                    size="small"
                    labelStyle={{ fontWeight: "bold" }}
                  >
                    <Descriptions.Item
                      className=""
                      label="Destination Account number"
                    >
                      {txn.inbound?.account_number}
                    </Descriptions.Item>
                    <Descriptions.Item className="" label="Sender account name">
                      {txn.inbound?.originator_account_name}
                    </Descriptions.Item>
                    <Descriptions.Item
                      className=""
                      label="Sender account number"
                    >
                      {txn.inbound?.originator_account_number}
                    </Descriptions.Item>
                    <Descriptions.Item className="" label="Sender bank">
                      {txn.inbound?.originator_bank}
                    </Descriptions.Item>
                    <Descriptions.Item className="" label="Amount">
                      {txn.inbound?.amount}
                    </Descriptions.Item>
                    <Descriptions.Item className="" label="Transaction Date">
                      {txn.inbound?.timestamp
                        ? new Date(txn.inbound.timestamp).toLocaleString()
                        : ""}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              </div>
            )}
          </div>
        </Drawer>

        <Drawer
          width="1200px"
          placement="right"
          open={target_modal.drawer === "merchant_txns"}
          closable={false}
        >
          <MerchantTxns />
        </Drawer>

        <Drawer
          width="1200px"
          placement="right"
          open={target_modal.drawer === "merchant_billings"}
          closable={false}
        >
          <MerchantBillings />
        </Drawer>

        <Drawer
          width="1200px"
          placement="right"
          open={target_modal.drawer === "merchant_biz"}
          closable={false}
        >
          <MerchantBiz />
        </Drawer>

        <Drawer
          width="1200px"
          placement="right"
          open={target_modal.drawer === "merchant_cashpoints"}
          closable={false}
        >
          <MerchantCashPoints />
        </Drawer>
        <Drawer
          width="1400px"
          placement="right"
          open={target_modal.drawer === "user_live_video"}
          closable={false}
        >
          <MediaNavigation
            setTargetModal={setTargetModal}
            merchant_id={merchant_id}
          />
        </Drawer>
        <Drawer
          width="1400px"
          placement="right"
          open={target_modal.drawer === "user_roles_live_video"}
          closable={false}
        >
          <RolesVideos
            setTargetModal={setTargetModal}
            merchant_id={merchant_id}
          />
        </Drawer>
        <Drawer
          width="1400px"
          placement="right"
          open={target_modal.drawer === "user_pnd_log"}
          closable={false}
        >
          <UserPndLog
            setTargetModal={setTargetModal}
            merchant_id={merchant_id}
          />
        </Drawer>

        <Modal
          title="Explain why you are rejecting this document."
          confirmLoading={rejection_ongoing}
          visible={target_modal.modal === "reject_message"}
          okButtonProps={{ disabled: !user_inputs.reject_message }}
          onOk={
            target_modal.target === "id_card"
              ? handleRejectIdCard
              : target_modal.target === "address_proof"
              ? handleRejectAddressProof
              : target_modal.target === "video"
              ? handleRejectLiveVideo
              : target_modal.target === "ammban"
              ? handleRejectAmmban
              : target_modal.target === "cac"
              ? handleRejectCAC
              : target_modal.target === "otherDocs"
              ? handleRejectOtherDoc
              : handleRejectPassport
          }
          onCancel={() => setTargetModal("")}
        >
          <Form.Item className="mb-0">
            <textarea
              className="w-100 rounded"
              id="stubborn_input1"
              rows={4}
              onChange={handleMetaDataInput}
              name="reject_message"
            />
          </Form.Item>
        </Modal>

        <Modal
          title="Edit Cashier"
          confirmLoading={target_loader === "edit_cashier"}
          visible={target_modal.modal === "edit_cashier"}
          okButtonProps={{
            disabled: !user_inputs.cashier_phone || !user_inputs.cashier_email,
          }}
          onOk={cashPointEditHandler}
          onCancel={() =>
            setTargetModal({ drawer: "merchant_cashpoints", modal: "" })
          }
        >
          <Form.Item>
            <p className="label mb-0">Phone Number</p>
            <Input
              className="st_1"
              placeholder="Phone Number"
              name="cashier_phone"
              value={user_inputs.cashier_phone}
              onChange={handleMetaDataInput}
              allowClear
            />
          </Form.Item>

          <Form.Item>
            <p className="label mb-0">Email Address</p>
            <Input
              className="st_1"
              placeholder="Email Address"
              name="cashier_email"
              value={user_inputs.cashier_email}
              onChange={handleMetaDataInput}
              allowClear
            />
          </Form.Item>

          <Form.Item>
            <p className="label mb-0">First name</p>
            <Input
              className="st_1"
              placeholder="First name"
              name="cashier_firstname"
              value={user_inputs.cashier_firstname}
              onChange={handleMetaDataInput}
              allowClear
            />
          </Form.Item>

          <Form.Item>
            <p className="label mb-0">Last name</p>
            <Input
              className="st_1"
              placeholder="Last name"
              name="cashier_lastname"
              value={user_inputs.cashier_lastname}
              onChange={handleMetaDataInput}
              allowClear
            />
          </Form.Item>
        </Modal>
      </div>
    </section>
  );
}

export default MerchantDocuments;
