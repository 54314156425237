import React, { useState, useEffect }  from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import store from "store";
import { Button, Input } from "antd";
import axios from "axios";

import logo from "../assets/images/logo.svg";
import "../assets/css/login.scss";
import { sign_in } from "../services/http_service";

const SignIn = (props) => {
    let history = useHistory();
    let location = useLocation();

    let [user_inputs, setInputs] = useState({username: "", password: "" });
    let [is_loading, setLoading] = useState(false);
    let [request_error, setRequestError] = useState("");

    useEffect(() => {
        if (sessionStorage.getItem("dartpay_token")) history.push("/dashboard") //redirect if there's token
        store.clearAll();
    },[history]);

    let handleInput = e => {
        let {name, value} = e.target;
        setRequestError("");

        setInputs(prev => ({
            ...prev,
            [name]: value
        }));
    };

    let handleSignIn = async () => {
        setLoading(true);

        setRequestError("");

        try {

            let result = await sign_in(user_inputs);
            sessionStorage.setItem("dartpay_token", result.data.token);
            axios.defaults.headers["b-access-token"] = result.data.token;
            
            setTimeout(() => {
                setLoading(false);

                let prev_destination = new URLSearchParams(location.search).get('return_url');

                prev_destination && prev_destination !== undefined && prev_destination !== null ? history.push(prev_destination) : history.push("/dashboard");

            }, 500);
            
        } catch (error) {
          setLoading(false);
          setRequestError(error.message);
        }
    };

    return (
        <div className="login">
      
            <img src={logo} alt="" />
            <div className="box">
                <p className="label">Email address</p>
                <Input
                    defaultValue={user_inputs.username}
                    name="username"
                    onChange={handleInput}
                    placeholder="Email Address"
                />

                <p className="label">Password</p>
                <Input.Password
                    defaultValue={user_inputs.password}
                    name="password"
                    placeholder="Password"
                    onChange={handleInput}
                />

                <div className="request_error my-3 text-danger text-center">{request_error}</div>

                {/* <p className='forgot'>Fogot password</p> */}
                <Button
                    type="primary"
                    className="main-btn lg-btn"
                    onClick={handleSignIn}
                    loading={is_loading}
                    disabled={!user_inputs.username || !user_inputs.password}
                >
                    Login
                </Button>
            </div>
        </div>
    )
};

export default SignIn;