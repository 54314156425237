import { notification } from "antd";

export const sortObjectByAmountOfUploadedKYCs = (array) => {
  function countKYC(obj) {
    let count = 0;
    for (const [_, value] of Object.entries(obj)) {
      if (value === true) {
        count++;
      }
    }

    return count;
  }
  return array
    .map((obj) => {
      return {
        ...obj,
        count: countKYC(obj.user),
      };
    })
    .sort((a, b) => b.count - a.count);
};

export const splitTitleFromOtherDocument = (text = "") => {
  return text.split("other_document_")[1];
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatMoney = (amount) => {
  return new Intl.NumberFormat("en", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
};

export const changeImageExt = (fileName, newExt) => {
  if (fileName?.image_url) {
    let ext = fileName?.image_url.substring(
      fileName?.image_url.lastIndexOf(".")
    );
    if (ext === ".pdf") {
      let pos = fileName?.image_url.includes(".")
        ? fileName?.image_url.lastIndexOf(".")
        : fileName?.image_url.length;
      let fileRoot = fileName?.image_url.substr(0, pos);
      let output = `${fileRoot}.${newExt}`;
      return output;
    }
    return fileName?.image_url;
  } else {
    let ext = fileName?.substring(fileName?.lastIndexOf("."));
    if (ext === ".pdf") {
      let pos = fileName.includes(".")
        ? fileName.lastIndexOf(".")
        : fileName.length;
      let fileRoot = fileName?.substr(0, pos);
      let output = `${fileRoot}.${newExt}`;
      return output;
    }
    return fileName;
  }
};

export const changeVideoExt = (fileName, newExt) => {
  if (fileName?.video_url) {
    let ext = fileName?.video_url.substring(
      fileName?.video_url.lastIndexOf(".")
    );
    if (ext.includes(".mp4")) {
      // let pos = fileName?.video_url.includes(".")
      //   ? fileName?.video_url.lastIndexOf(".")
      //   : fileName?.video_url.length;
      // let fileRoot = fileName?.video_url.substr(0, pos);
      // let output = `${fileRoot}.${newExt}`;
      // return output;
      return fileName?.video_url;
    }
    return fileName?.video_url;
  } else if (fileName?.videoUrl) {
    return fileName?.videoUrl;
  }
};

export const openNotificationWithIcon = (type, message, desc) => {
  notification[type]({
    message: message,
    description: desc,
  });
};
