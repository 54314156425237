import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { Pagination } from "antd";
import React, {} from "react";
import caretleft from "../../../assets/images/caretleft.svg";

import "./Merchants.scss";
export default function ReferredMarketers({
  setVisible,
  heading,
  pageData,
  emptyText,
  current_page,
  pages,
  updateData,
}) {
  return (
    <div className="merchants" style={{ padding: 50 }}>
      <p
        onClick={() => setVisible(false)}
        style={{
          margin: "30px 0",
          fontSize: 22,
          color: "#09427D",
          fontWeight: "bold",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <img style={{ marginRight: 10 }} src={caretleft} alt="" />
        {heading}
      </p>
      <div className="trow thead bordered">
        <div className="tdata">Name</div>
        <div className="tdata email">Email</div>
        <div className="tdata">Phone</div>
        <div className="tdata ">Verified</div>
        <div className="tdata ">Active</div>
      </div>
      {!pageData ||
        (!pageData.length && (
          <p style={{ fontSize: 16, marginTop: 12 }}>{emptyText}</p>
        ))}
      {pageData.map((itm, i) => (
        <div className="single-item" key={itm._id}>
          <div className="trow titem bordered">
            <div className="tdata">
              {itm.referralcode
                ? `${itm.businessname}`
                : `${itm.contactfirstname} ${itm.contactlastname}`}
            </div>
            <div className="tdata email">
              {itm.referralcode ? itm.businessemail : itm.email}
            </div>
            <div className="tdata">
              {itm.referralcode ? itm.businessphone : itm.phone}
            </div>
            <div className="tdata">
              {itm.verified ? (
                <CheckCircleFilled style={{ color: "green" }} />
              ) : (
                <CloseCircleFilled style={{ color: "red" }} />
              )}
            </div>
            <div className="tdata">
              <span>
                {itm.active ? (
                  <CheckCircleFilled style={{ color: "green" }} />
                ) : (
                  <CloseCircleFilled style={{ color: "red" }} />
                )}
              </span>
            </div>
          </div>
        </div>
      ))}

      <div style={{ paddingTop: 30, textAlign: "left" }}>
        <Pagination
          defaultCurrent={current_page}
          current={current_page}
          total={10 * pages}
          onChange={(e) => updateData(e)}
        />
      </div>
    </div>
  );
}
