import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";

import React, { useMemo } from "react";
import { PageHeader } from "antd";
import { numberWithCommas } from "../../helpers";

export const CustomLineChart = React.memo(({ chartData }) => {
  const labels = Array.from(new Set([...chartData.debit.map((item) => item?.label), ...chartData.credit.map((item) => item?.label)]));

  const debitOnAMonth = () => {
    let debit = chartData.debit.map((item) => item?.total_amount);
    let credit = chartData.credit.map((item) => item?.total_amount);
    let result = [];
    labels.forEach((label, index) => {
      result.push({
        name: label,
        debit: debit[index] || 0,
        credit: credit[index] || 0,
      });
    });
    return result;
  };

  let data = useMemo(debitOnAMonth, [chartData]);

  return (
    <div className="chart-container">
      <PageHeader className="site-page-header px-0" title={data.length > 0 ? `Monthly Cummulative Debit and Credit Transactions` : "No Transactions"} />
      <ResponsiveContainer className="res" width="95%" height={250}>
        <LineChart
          data={debitOnAMonth() || []}
          margin={{
            top: 5,
            right: 30,
            left: -10,
            bottom: 5,
          }}
        >
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip
            labelStyle={{ color: "rgba(255, 255, 255, 0.5)" }}
            formatter={function (value, name) {
              return `₦${numberWithCommas(String(value))}`;
            }}
          />
          {data.length > 0 && (
            <>
              <Line type="monotone" dataKey="credit" dot={false} stroke="lightgreen" />
              <Line type="monotone" dot={false} dataKey="debit" stroke="red" />
            </>
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
});
