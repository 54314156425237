import { useEffect, useState } from "react";
import "./Loan.scss";
import { Table } from "antd";
import dayjs from "dayjs";
import nProgress from "nprogress";
import {
  getLoanRepaymentService,
  getLoanRepayments,
} from "../../../services/http_service";
import { formatMoney } from "../../../helpers";

export const Repayment = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const table_columns = [
    {
      title: "Merchant Name",
      dataIndex: "merchant",
      key: "merchant",
      render: (_, record) => {
        return <span className="loan-table-item">{record?.merchant?.contactfirstname + " " + record?.merchant?.contactlastname}</span>;
      },
    },
    {
      title: "Total Repayment Amount",
      dataIndex: "loan_taken",
      key: "loan_taken",
      render: (_, record) => {
        return (
          <span className="loan-table-item">
            ₦{formatMoney(record?.loan?.repayment_amount)}
          </span>
        );
      },
    },
    {
      title: "Interest Rate",
      dataIndex: "interest_rate",
      key: "interest_rate",
      render: (_, record) => {
        return (
          <span className="loan-table-item">
            {record?.loan?.interest_rate}%
          </span>
        );
      },
    },
    {
      title: "Amount Paid",
      dataIndex: "amount_paid",
      key: "amount_paid",
      render: (_, record) => {
        return (
          <span className="loan-table-item">
            ₦{formatMoney(record?.amount)}
          </span>
        );
      },
    },
    {
      title: "Loan Apply Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, record) => {
        return (
          <span className="loan-table-item">
            {dayjs(record?.loan?.createdAt).format("MMMM D, YYYY h:mm A")}
          </span>
        );
      },
    },
    {
      title: "Due Payment Date",
      dataIndex: "payment_date",
      key: "payment_date",
      render: (_, record) => {
        return (
          <span className="loan-table-item">
            {dayjs(record?.loan?.loan_due_date).format("MMMM D, YYYY h:mm A")}
          </span>
        );
      },
    },
  ];

  const today = dayjs().format("YYYY-MM-DD");

  const getLoanRepayment = async () => {
    setLoading(true);

    try {
      nProgress.inc();
      const res = await getLoanRepaymentService("2023-01-01", today, 1);
      setData(res?.data?.data);
      setLoading(false);
      nProgress.done();
    } catch (error) {
      nProgress.done();
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    getLoanRepayment();
    return () => null;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="loan-container">
      <div className="loan-header">Repayments</div>
      <section>
        <Table
          columns={table_columns}
          dataSource={data}
          rowKey={(record) => record._id}
          loading={loading}
        />
      </section>
    </div>
  );
};
