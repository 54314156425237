import "./live_video.scss";
import "../Merchants.scss";
import { LeftOutlined } from "@ant-design/icons";
import { Drawer, Tabs } from "antd";
import { CashierRolesTable, RolesTable } from "../../../Table/Table";
import { useQuery } from "react-query";
import { getTeamsRolesVideos } from "../../../../services/http_service";

export const RolesVideos = ({ setTargetModal, merchant_id }) => {
  const cashierHeader = ["Date Created","Name", "Email Address", "Role", "Action"];
  const otherUsersHeader = ["Name", "Email Address", "Role", "Action"];
  const { isLoading, data } = useQuery(
    ["getMerchantRolesVideos"],
    () => getTeamsRolesVideos(merchant_id),
    { refetchOnWindowFocus: true, refetchOnMount: true }
  );
  const usersRecord = data?.data?.merchantLookUp[0]?.otherusers;

  const otherRolesRecord =
    usersRecord &&
    usersRecord.filter((record) => record?.isscashierotheruserrole === false);
  const cashierAccountants =
    usersRecord &&
    usersRecord.filter((record) => record?.isscashierotheruserrole === true);

  const items = [
    {
      label: "Other members ",
      key: "other_members",
      children: (
        <div className="mt50">
          <RolesTable
            headers={otherUsersHeader}
            data={otherRolesRecord}
            isLoading={isLoading}
            merchant_id={merchant_id}
          />
        </div>
      ),
    },
    {
      label: "Cashier Accountants",
      key: "cashier_accountants",
      children: (
        <div className="mt50">
          <CashierRolesTable
            headers={cashierHeader}
            data={cashierAccountants}
            isLoading={isLoading}
            merchant_id={merchant_id}
          />
        </div>
      ),
    },
  ];
  return (
    <div>
      <div>
        <div className="display-flex p10 align-center">
          <div
            onClick={() => {
              setTargetModal({ drawer: "" });
            }}
            className="c-pointer"
          >
            <LeftOutlined />
          </div>
          <div className="ml10 fontw600 fSize12 colorBlue">
            Roles Live Videos
          </div>
        </div>
      </div>
      <div className="px40">
        <Tabs defaultActiveKey="other_members" items={items} />
      </div>
    </div>
  );
};
