import "./live_video.scss";
import "../Merchants.scss";
import { LeftOutlined } from "@ant-design/icons";
import { useState } from "react";
import { Modal, Tabs } from "antd";
import { useQuery } from "react-query";
import {
  getLimitIncreaseVideo,
  getPasswordResetVideo,
  respondToLimitVideo,
  respondToPinPasswordVideos,
} from "../../../../services/http_service";
import { ButtonRow, RenderVideo } from "../../../VideoDisplay/VideoDisplay";
import { formatMoney, openNotificationWithIcon } from "../../../../helpers";
import { useQueryClient } from "react-query";
import { RolesVideos } from "./roles_videos";

export const MediaNavigation = ({ setTargetModal, merchant_id }) => {
  const items = [
    {
      label: "Password Change Video",
      key: "password_reset",
      children: (
        <div className="mt50">
          <PasswordChangeLiveVideo merchant_id={merchant_id} />
        </div>
      ),
    },
    {
      label: "Forgot Password Video",
      key: "forgot_password",
      children: (
        <div>
          <PasswordResetLiveVideo merchant_id={merchant_id} />
        </div>
      ),
    },
    {
      label: "Forgot Pin Video",
      key: "pin_reset_video",
      children: (
        <div>
          <PinChangeLiveVideo merchant_id={merchant_id} />
        </div>
      ),
    },
    {
      label: "Pin Change Video",
      key: "pin_change_video",
      children: (
        <div>
          <PinResetLiveVideo merchant_id={merchant_id} />
        </div>
      ),
    },
    {
      label: "Limit Increase Video",
      key: "limit_increase_video",
      children: (
        <div>
          <LimitIncreaseVideo merchant_id={merchant_id} />
        </div>
      ),
    },
  ];
  return (
    <div>
      <div>
        <div className="display-flex p10 align-center">
          <div
            onClick={() => {
              setTargetModal({ drawer: "" });
            }}
            className="c-pointer"
          >
            <LeftOutlined />
          </div>
          <div className="ml10 fontw600 fSize12 colorBlue">Live Videos</div>
        </div>
      </div>
      <div className="px40">
        <Tabs defaultActiveKey="password_reset" items={items} />
        <LiveVideoModal />
      </div>
    </div>
  );
};

export const PasswordResetLiveVideo = ({ merchant_id }) => {
  const queryClient = useQueryClient();
  const [confirmLoading, setConfirmLoading] = useState(false);
  // get the merchant password forget video
  const { isLoading, data } = useQuery(
    ["getPasswordResetVideo"],
    () => getPasswordResetVideo(merchant_id, "password_reset"),
    { refetchOnWindowFocus: true, refetchOnMount: true }
  );
  const info = data?.data?.password_log;
  // accept or reject the video
  const handleVideo = async (id, status, reason) => {
    const data = {
      requestId: id,
      status: status,
      reason: reason,
    };
    setConfirmLoading(true);
    try {
      const res = await respondToPinPasswordVideos(data);
      if (res?.data?.status) {
        //invalidate the query
        queryClient.invalidateQueries({ queryKey: ["getPasswordResetVideo"] });
      }
      setConfirmLoading(false);
    } catch (error) {
      console.log("error", error?.response);
      if (error?.response?.data?.message) {
        openNotificationWithIcon(
          "error",
          "Error",
          error?.response?.data?.message
        );
      }
      setConfirmLoading(false);
    }
  };

  return (
    <div className="full-width display-flex justify-align-center">
      <div>
        <RenderVideo
          videoSrc={info}
          emptyDescription="Merchant has not uploaded any forget password video"
          isLoading={isLoading}
        />
        {info?.video_url && info?.status === "pending" && (
          <ButtonRow
            handleVideo={handleVideo}
            vidInfo={info}
            confirmLoading={confirmLoading}
          />
        )}
        {info?.status === "approved" && (
          <div className="fontw600 fSize12">Video has been accepted</div>
        )}
      </div>
    </div>
  );
};

export const PasswordChangeLiveVideo = ({ merchant_id }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const queryClient = useQueryClient();
  // get the merchant password change video
  const { isLoading, data } = useQuery(
    ["getPasswordChangeVideo"],
    () => getPasswordResetVideo(merchant_id, "password"),
    { refetchOnWindowFocus: true, refetchOnMount: true }
  );

  const info = data?.data?.password_log;

  const handleVideo = async (id, status, reason) => {
    const data = {
      requestId: id,
      status: status,
      reason: reason,
    };
    setConfirmLoading(true);
    try {
      const res = await respondToPinPasswordVideos(data);

      if (res?.data?.status) {
        //invalidate the query
        if (res?.data?.data?.status === "declined") {
          openNotificationWithIcon(
            "success",
            "Success",
            "Video Declined Successfully."
          );
        } else {
          openNotificationWithIcon(
            "success",
            "Success",
            "Video Approved Successfully."
          );
        }
        queryClient.invalidateQueries({ queryKey: ["getPasswordChangeVideo"] });
      }
      setConfirmLoading(false);
    } catch (error) {
      console.log("error", error?.response);
      openNotificationWithIcon("error", "Error", error?.response?.message);
      setConfirmLoading(false);
    }
  };
  return (
    <div className="full-width display-flex justify-align-center">
      <div>
        <RenderVideo
          videoSrc={info}
          emptyDescription="Merchant has not uploaded any password change video"
          isLoading={isLoading}
        />
        {info?.video_url && info?.status === "pending" && (
          <ButtonRow
            handleVideo={handleVideo}
            vidInfo={info}
            confirmLoading={confirmLoading}
          />
        )}
        {info?.status === "approved" && (
          <div className="fontw600 fSize12">Video has been accepted</div>
        )}
      </div>
    </div>
  );
};

export const PinChangeLiveVideo = ({ merchant_id }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const queryClient = useQueryClient();
  // get the merchant password change video
  const { isLoading, data } = useQuery(
    ["getPinChangeVideo"],
    () => getPasswordResetVideo(merchant_id, "pin"),
    { refetchOnWindowFocus: false, refetchOnMount: true }
  );

  const info = data?.data?.password_log;
  const handleVideo = async (id, status, reason) => {
    const data = {
      requestId: id,
      status: status,
      reason: reason,
    };
    setConfirmLoading(true);
    try {
      const res = await respondToPinPasswordVideos(data);
      if (res?.data?.status) {
        //invalidate the query
        if (res?.data?.data?.status === "declined") {
          openNotificationWithIcon(
            "success",
            "Success",
            "Video Declined Successfully."
          );
        } else {
          openNotificationWithIcon(
            "success",
            "Success",
            "Video Approved Successfully."
          );
        }
        queryClient.invalidateQueries({ queryKey: ["getPinChangeVideo"] });
      }
      setConfirmLoading(false);
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Error",
        error?.response?.data?.message
      );
      setConfirmLoading(false);
    }
  };

  return (
    <div className="full-width display-flex justify-align-center">
      <div>
        <RenderVideo
          videoSrc={info}
          emptyDescription="Merchant has not uploaded a pin change video"
          isLoading={isLoading}
        />
        {info?.video_url && info?.status === "pending" && (
          <ButtonRow
            handleVideo={handleVideo}
            vidInfo={info}
            confirmLoading={confirmLoading}
          />
        )}
        {info?.status === "approved" && (
          <div className="fontw600 fSize12">Video has been accepted</div>
        )}
      </div>
    </div>
  );
};

export const PinResetLiveVideo = ({ merchant_id }) => {
  const queryClient = useQueryClient();
  const [confirmLoading, setConfirmLoading] = useState(false);
  // get the merchant password change video
  const { isLoading, data } = useQuery(
    ["getPinResetVideo"],
    () => getPasswordResetVideo(merchant_id, "pin_reset"),
    { refetchOnWindowFocus: true, refetchOnMount: true }
  );

  const info = data?.data?.password_log;

  const handleVideo = async (id, status, reason) => {
    const data = {
      requestId: id,
      status: status,
      reason: reason,
    };
    setConfirmLoading(true);
    try {
      const res = await respondToPinPasswordVideos(data);
      if (res?.data?.status) {
        //invalidate the query
        if (res?.data?.data?.status === "declined") {
          openNotificationWithIcon(
            "success",
            "Success",
            "Video Declined Successfully."
          );
        } else {
          openNotificationWithIcon(
            "success",
            "Success",
            "Video Approved Successfully."
          );
        }
        queryClient.invalidateQueries({ queryKey: ["getPinResetVideo"] });
      }
      setConfirmLoading(false);
    } catch (error) {
      console.log("error", error?.response);
      openNotificationWithIcon("error", "Error", error?.response?.message);
      setConfirmLoading(false);
    }
  };
  return (
    <div className="full-width display-flex justify-align-center">
      <div>
        <RenderVideo
          videoSrc={info}
          emptyDescription="Merchant has not uploaded a pin reset video"
          isLoading={isLoading}
        />
        {info?.video_url && info?.status === "pending" && (
          <ButtonRow
            handleVideo={handleVideo}
            vidInfo={info}
            confirmLoading={confirmLoading}
          />
        )}
        {info?.status === "approved" && (
          <div className="fontw600 fSize12">Video has been accepted</div>
        )}
      </div>
    </div>
  );
};

export const LimitIncreaseVideo = ({ merchant_id }) => {
  const queryClient = useQueryClient();
  const [confirmLoading, setConfirmLoading] = useState(false);
  // get the merchant password change video
  const { isLoading, data } = useQuery(
    ["getLimitIncreaseVideo"],
    () => getLimitIncreaseVideo(merchant_id),
    { refetchOnWindowFocus: false, refetchOnMount: true }
  );

  const info = data?.data?.limit_request;

  const handleVideo = async (id, status, reason) => {
    const data = {
      requestId: id,
      status: status,
      reason: reason,
    };
    setConfirmLoading(true);
    try {
      const res = await respondToLimitVideo(data);
      // console.log(res, "RES HERE");
      if (res?.data?.status) {
        //invalidate the query
        queryClient.invalidateQueries({ queryKey: ["getLimitIncreaseVideo"] });
      }
      setConfirmLoading(false);
    } catch (error) {
      console.log("error", error?.response);
      openNotificationWithIcon("error", "Error", error?.response?.message);
      setConfirmLoading(false);
    }
  };

  return (
    <div className="full-width display-flex justify-align-center">
      <div>
        <div>
          {info?.previousAmount && (
            <div className="fontw600 fSize12">
              Previous Account Limit: ₦{formatMoney(info?.previousAmount)}
            </div>
          )}
          {info?.amount && (
            <div className="fontw600 fSize12">
              New Requested Account Limit: ₦{formatMoney(info?.amount)}
            </div>
          )}
        </div>
        <RenderVideo
          videoSrc={info}
          emptyDescription="Merchant has not uploaded a limit increase video yet"
          isLoading={isLoading}
        />
        {(info?.video_url || info?.videoUrl) && info?.status === "pending" && (
          <ButtonRow
            handleVideo={handleVideo}
            vidInfo={info}
            confirmLoading={confirmLoading}
          />
        )}
        {info?.status === "approved" && (
          <div className="fontw600 fSize12">Video has been accepted</div>
        )}
      </div>
    </div>
  );
};

export const LiveVideoModal = () => {
  return (
    <Modal title="This is a modal">
      <p> This is a modal</p>
    </Modal>
  );
};
