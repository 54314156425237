import { useHistory, useLocation } from "react-router-dom";

import {CloseCircleFilled, LoadingOutlined, } from "@ant-design/icons";
import { Button, Col, Drawer, Input, Pagination, Row } from "antd";
import React, { useContext, useEffect, useState, useCallback } from "react";

import AppChart from "../../../components/Chart/Chart";
import { marketersReferrals, getAllMarketers, getMerchantsGrowthGraph, searchMarketersData, signOut } from "../../../services/http_service";
import { appContext } from "../../../store/appContext";
import * as dayjs from "dayjs";
import "./Merchants.scss";
import ReferredMarketers from "./ReferredMarketers";
import NProgress from 'nprogress';

export default function Marketers() {
	let history = useHistory();
	let location = useLocation();

	let context = useContext(appContext);
	let { allMarketers, globalFilter, updateStateValue } = context;
	let [selectedItem, setselectedItem] = useState(null);

	let [visible, setVisible] = useState(false);
	let [visible_, setVisible_] = useState(false);
	let [referredMarketers, setReferredMarketers] = useState({});
	let [growthGraph, setGrowthGraph] = useState({});
	let [currentCode, setcurrentCode] = useState("");

	let [searchVal, setSearchVal] = useState("");
	let [searching, setSearching] = useState(false);
	let [dataToUse, setdataToUse] = useState({pages: 0, page: 1, merketers:[]});

	let handleLogout = useCallback(() => {
		NProgress.inc();

		signOut()
			.then((res) => {
				localStorage.clear();
				sessionStorage.clear();
				history.push("/?return_url=" + location.pathname);
			})
			.catch((err) => {
				localStorage.clear();
				sessionStorage.clear();
				history.push("/?return_url=" + location.pathname);
			}).finally(() => {
				NProgress.done();
			});
		
	}, [history, location.pathname]);

	let fetchAllMarketers = useCallback((page) => {
		NProgress.inc();

		getAllMarketers({
			page,
			location: "lekki",
			...globalFilter,
			limit: 10,
		})
		.then((res) => {
			setselectedItem(null);
			updateStateValue({ allMarketers: res.data });
			setdataToUse(res.data);
		})
		.catch((err) => {
			if(err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();
			console.log({ err });
		})
		.finally(() => {
			NProgress.done();
		});
		
	}, [globalFilter, handleLogout, updateStateValue]);

	let fetchReferrals = (code, i, page) => {
		if (i && selectedItem === i) {
			setselectedItem(null);
			return;
		}
		if (code) {
			setcurrentCode(code);
		}
		marketersReferrals(code || currentCode, page)
			.then((res) => {
				setReferredMarketers(res.data);
				setselectedItem(i);
				setVisible_(true);
			})
			.catch((err) => {
				console.log(err)
				if(err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();
			})
			.finally(() => { NProgress.done(); });
	};

	let fetchGraph = (code) => {
		getMerchantsGrowthGraph(code)
			.then((res) => {
				let graphInfo = res.data
				.map((itm) => {
					let shapedDate = `${itm._id.month}-${itm._id.day}-${itm._id.year}`;
					return {
					...itm,
					label: dayjs(shapedDate).format("MMM YYYY"),
					date: dayjs(shapedDate),
					};
				})
				.sort((a, b) => {
					if (a.date.isBefore(b.date)) return 1;
					if (!a.date.isBefore(b.date)) return -1;
					return 0;
				})
				.reverse()
				.reduce((agg, curr) => {
					agg[curr.label] = !agg[curr.label]
					? curr.merchant_count
					: agg[curr.label] + curr.merchant_count;
					return agg;
				}, {});

				setGrowthGraph(graphInfo);
				setVisible(true);
			})
			.catch((err) => {
				
				console.log(err);
				if(err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();
			})
			.finally(() => { NProgress.done(); });
	};

	let searchMerchant = (page) => {
		if (searchVal) {
		setSearching(true);
		let data = {
			page: page || 1,
			name: searchVal,
		};

		searchMarketersData(data)
			.then((res) => {
			setdataToUse({ ...res.data, merketers: res.data.merchants });
			})
			.catch((err) => {
				console.log(err);
				if(err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();
			})
			.finally(() => setSearching(false));
		}
	};

	useEffect(() => {
		fetchAllMarketers(1);
	}, [fetchAllMarketers]);

	return (
    <div className="merchants">
		<Input
			style={{
				width: 200,
				height: 40,
				float: "right",
				marginTop: -113,
				marginRight: 115,
				border: `0.1px solid rgba(9, 66, 125, 0.123)`,
				backgroundColor: `#fff`,
				borderRadius: 5,
			}}
			// onBlur={() => searchMerchant(null)}
			onPressEnter={() => searchMerchant(null)}
			value={searchVal}
			suffix={
				searching ? (
					<LoadingOutlined />
				) : (
					<CloseCircleFilled
					onClick={() => {
						setSearchVal("");
						setdataToUse(allMarketers);
					}}
					/>
				)
			}
			onChange={(e) => setSearchVal(e.target.value)}
			bordered={false}
			placeholder="Search marketers name"
		/>

		<div className="trow thead">
			<div className="tdata">Name</div>
			<div className="tdata email">Email</div>
			<div className="tdata">Phone</div>
			<div className="tdata">Referral Code</div>
			<div className="tdata address">No of Referrals</div>
		</div>

		{dataToUse && dataToUse.merketers && dataToUse.merketers.length  && dataToUse.merketers
			.filter((it) => it)
			.map((itm, i) => (

			<div className="single-item" key={itm._id} onClick={() => setselectedItem(selectedItem === i ? null : i)} >

				<div className="trow titem">
				<div className="tdata">{itm.name}</div>
				<div className="tdata email">{itm.email}</div>
				<div className="tdata">{itm.phone}</div>
				<div className="tdata">{itm.referralcode}</div>

				<div className="tdata address">
					<div>
					<span>{itm.no_referrals}</span>
					{itm.no_referrals ? (
						<span
						className="green--text"
						style={{
							fontWeight: 200,
							fontSize: 11,
							marginLeft: 10,
							cursor: "pointer",
						}}
						onClick={() => fetchReferrals(itm.referralcode, i, 1)}
						>
						view
						</span>
					) : null}
					</div>
					{/* <div className='ellipsis'>
					<EllipsisOutlined />
				</div> */}
				</div>

				</div>

				{i === selectedItem && (
					<div className="sub-row" style={{ padding: "20px 100px" }}>
						<Row>
							<Col span={20}>
								<Row>
								<Col span={8}>
									<p className="label">Total Revenue Earned</p>
									<p className="value">0</p>
								</Col>
								<Col span={8}>
									<p className="label">Account Ledger Balance</p>
									<p className="value">0</p>
								</Col>
								<Col span={8}>
									<p className="label">Date of Last Referral</p>
									<p className="value">
									{itm.last_referred_date
										? dayjs(itm.last_referred_date).format(
											"DD MMM, YYYY"
										)
										: "---"}
									</p>
								</Col>
								</Row>
							</Col>
							<Col span={4}>
								<Button
								className="main-btn md-btn"
								type="primary"
								onClick={() => fetchGraph(itm.referralcode)}
								>
								View Chart
								</Button>
							</Col>
						</Row>
					</div>
				)}
			</div>
			))}

			<div style={{ paddingTop: 30, textAlign: "left" }}>
				<Pagination
				defaultCurrent={dataToUse.page}
				current={dataToUse.page}
				total={10 * dataToUse.pages}
				onChange={(e) => {
					searchVal ? searchMerchant(e) : fetchAllMarketers(e);
				}}
				/>
			</div>

			<Drawer visible={visible} width={900} closable={false}>
				<AppChart
				setVisible={setVisible}
				chartData={growthGraph}
				title="Merchant Referral Growth"
				showKey={false}
				/>
			</Drawer>

			<Drawer visible={visible_} width={1100} closable={false}>
				<ReferredMarketers
				heading="Referred Marketers"
				setVisible={setVisible_}
				pageData={referredMarketers.merchants}
				emptyText="This Marketer has no referrals"
				current_page={parseInt(referredMarketers.page)}
				pages={parseInt(referredMarketers.pages)}
				updateData={(e) => fetchReferrals(null, null, e)}
				/>
			</Drawer>
		</div>
	);
}
