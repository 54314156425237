import axios from "axios";
import * as urls from "./urls";

let d = new Date();
let requestId = d.getTime();
//let publicKey = "SGFuZGxpbmdMb25lQ3J1c2FkZXJzSW5BRGFuZ2Vyb3VzbHlEYW5nZXJvdXNXb3JsZCM="; //dev
let publicKey =
  "SXZhbkFsZXJnYXBJc05vdE9ubHlBV2l0dHlPbmVIYW5uYWhCYW5la3VJc1RoZVNlY29uZCNOdW4="; //prod
//let publicKey = process.env.PUBLIC_KEY;

axios.defaults.headers["publicKey"] = publicKey;
axios.defaults.headers["requestId"] = requestId;
let accessToken = sessionStorage.getItem("dartpay_token");

if (accessToken) {
  axios.defaults.headers["b-access-token"] = accessToken;
}

// export const adminLogin = (data) => {
//   return axios({
//     method: "post",
//     url: urls.loginUrl,
//     data,
//   });
// };

export const sign_in = (data) => {
  return axios({
    method: "post",
    url: urls.login_url,
    data,
  });
};

export const getbalance = () => {
  return axios({
    method: "get",
    url: urls.balanceUrl,
  });
};

export const getRoles = () => {
  return axios({
    method: "get",
    url: urls.rolesUrl,
  });
};

export const fetchAdmins = (data) => {
  return axios({
    method: "post",
    url: urls.allAdminsUrl,
    data,
  });
};

export const addAdmin = (data) => {
  return axios({
    method: "post",
    url: urls.addAdminUrl,
    data,
  });
};

export const updateAdmin = (data, id) => {
  return axios({
    method: "put",
    url: `${urls.addAdminUrl}/${id}`,
    data,
  });
};

export const getMerchantsTxn = (data) => {
  return axios({
    method: "post",
    url: urls.merchantTnxUrl,
    data,
  });
};

export const getActiveMerchantsList = (data) => {
  return axios({
    method: "post",
    url: urls.activeMerchantsListUrl,
    data,
  });
};

export const getCashpointSignups = (data) => {
  return axios({
    method: "post",
    url: urls.cashpointsignupsUrl,
    data,
  });
};

export const getMerchantsGraph = (data) => {
  return axios({
    method: "get",
    url: urls.merchantGraphUrl,
  });
};

export const getMerchantsTxnGraph = (id) => {
  return axios({
    method: "get",
    url: `${urls.merchantTnxGraphUrl}/${id}`,
  });
};

export const getAggregatorTxnGraph = (id, type) => {
  return axios({
    method: "get",
    url: `${urls.aggregatorTnxGraphUrl}/${id}/${type}`,
  });
};

export const getChannelOptions = () => {
  return axios({
    method: "get",
    url: `${urls.channelOptionsUrl}`,
  });
};

export const getTnxPerMerchant = (params) => {
  return axios({
    method: "get",
    url: `${urls.tnxperMerchantUrl}`,
    params,
  });
};

export const searchMerchantForRevenue = (params) => {
  return axios({
    method: "get",
    url: `${urls.searchMerchantForRevUrl}`,
    params,
  });
};

export const getMerchantsGrowthGraph = (code) => {
  return axios({
    method: "get",
    url: `${urls.merchantGrowthGraphUrl}/${code}/merchant/chart`,
  });
};

export const getTopMerchants = async (data) => {
  let response = await axios({
    method: "post",
    url: urls.topMerchantsUrl,
    data,
  });
  return response;
};

export const getCashiers = async (data) => {
  let response = await axios({
    method: "post",
    url: urls.cashiersUrl,
    data,
  });
  return response;
};

export const getActiveMerchants = (data) => {
  return axios({
    method: "post",
    url: urls.activeMerchantsUrl,
    data,
  });
};

export const searchMerchantData = (params) => {
  return axios({
    method: "get",
    url: urls.searchMerchantsUrl,
    params,
  });
};

export const searchMarketersData = (params) => {
  return axios({
    method: "get",
    url: urls.searchpMarketerUrl,
    params,
  });
};

export const searchCashiersData = (params) => {
  return axios({
    method: "get",
    url: urls.searchCashierUrl,
    params,
  });
};

export const getMerchantCashpoints = (data) => {
  return axios({
    method: "post",
    url: urls.merchantCashpointsUrl,
    data,
  });
};

export const getAllMerchants = (data) => {
  return axios({
    method: "post",
    url: urls.allMerchantsUrl,
    data,
  });
};

export const filterAllMerchants = (data) => {
  return axios({
    method: "post",
    url: urls.filterAllMerchantsUrl,
    data,
  });
};

export const filterMerchantSystemStats = () => {
  return axios({
    method: "post",
    url: urls.merchant_system_stats_url,
  });
};

export const getMerchantInfo = (id) => {
  return axios({
    method: "get",
    url: `${urls.merchantInfoUrl}/${id}`,
  });
};

export const getMerchantSignups = (data) => {
  return axios({
    method: "post",
    url: urls.merchantSignupsUrl,
    data,
  });
};

export const getFailedSettlements = (data) => {
  return axios({
    method: "post",
    url: urls.failedSettlementsUrl,
    data,
  });
};

export const getAllMarketers = (data) => {
  return axios({
    method: "post",
    url: urls.allMarketersUrl,
    data,
  });
};

export const marketersReferrals = (code, page) => {
  return axios({
    method: "get",
    url: `${urls.marketersReferralsUrls}/${code}/merchant/page/${page}/show`,
  });
};

export const getLoginAudit = (data) => {
  return axios({
    method: "post",
    url: urls.loginAuditUrl,
    data,
  });
};

export const activateBank = (id) => {
  return axios({
    method: "put",
    url: `${urls.merchantUrl}/${id}/activatebank`,
  });
};

export const getEndpointsAudit = (data) => {
  return axios({
    method: "post",
    url: urls.endpointsUrl,
    data,
  });
};

export const getTnxValue = (data) => {
  return axios({
    method: "post",
    url: urls.tnxValueUrl,
    data,
  });
};

export const getLocationMerchantsCount = (data) => {
  return axios({
    method: "post",
    url: urls.locationUrl,
    data,
  });
};

export const generateMerchantTxnHistory = (data, id) => {
  if (data.type === "download") {
    axios.defaults.headers["Content-disposition"] =
      "attachment; filename=$filename.pdf";
  }

  return axios({
    method: "post",
    url: `${urls.merchantUrl}/txn_history_pdf/${id}`,
    data,
  });
};

export const generateMerchantTxnHistoryCSV = (data, id) => {
  return axios({
    method: "post",
    url: `${urls.merchantUrl}/txn_history_csv/${id}`,
    data,
  });
};

export const getIndustryMerchantsCount = (data) => {
  return axios({
    method: "post",
    url: urls.industryUrl,
    data,
  });
};

export const getChannelMerchantsCount = (data) => {
  return axios({
    method: "post",
    url: urls.channelUrl,
    data,
  });
};

export const getTnxByBank = (params) => {
  return axios({
    method: "get",
    url: urls.tnxByBankUrl,
    params,
  });
};

export const deactivateMerchant = (id) => {
  return axios({
    method: "put",
    url: `${urls.merchantUrl}/${id}/deactivate`,
  });
};

export const verifyMerchant = (id) => {
  return axios({
    method: "put",
    url: `${urls.merchantUrl}/${id}/verify`,
  });
};

export const deactivateCashier = (id) => {
  return axios({
    method: "put",
    url: `${urls.cashierUrl}/${id}/deactivate`,
  });
};

export const getMerchantProofOfAddress = (id) => {
  return axios({
    method: "get",
    url: `${urls.proofOfAddressUrl}/${id}`,
  });
};

export const getMerchantInternationalPassport = (id) => {
  return axios({
    method: "get",
    url: `${urls.internationalPassportUrl}/${id}`,
  });
};

export const getMerchantIdCard = (id) => {
  return axios({
    method: "get",
    url: `${urls.idCardUrl}/${id}`,
  });
};

export const getMerchantBvnInfo = (id) => {
  return axios({
    method: "get",
    url: `${urls.bvnInfoUrl}/${id}`,
  });
};

export const getMerchantNinInfo = (id) => {
  return axios({
    method: "get",
    url: `${urls.ninInfoUrl}/${id}`,
  });
};

export const getMerchantBVNAggregate = (page, limit) => {
  return axios({
    method: "get",
    url: `${urls.bvnAggregateUrl}/?page=${page}&limit=${limit}`,
  });
};

export const getCAC = (id) => {
  return axios({
    method: "get",
    url: `${urls.getCACUrl}/${id}`,
  });
};

export const getOtherDoc = (id) => {
  return axios({
    method: "get",
    url: `${urls.otherDocUrl}/${id}`,
  });
};

export const getMerchantBVNAggregateUsers = (bvn) => {
  return axios({
    method: "get",
    url: `${urls.bvnAggregateUrl}/${bvn}`,
  });
};

export const getMerchantNINAggregate = (page, limit) => {
  return axios({
    method: "get",
    url: `${urls.ninAggregateUrl}/?page=${page}&limit=${limit}`,
  });
};

export const getMerchantNINAggregateUsers = (nin) => {
  return axios({
    method: "get",
    url: `${urls.ninAggregateUrl}/${nin}`,
  });
};

export const createVerificationComment = (id, data) => {
  return axios({
    method: "post",
    url: `${urls.commentUrl}/${id}`,
    data: data,
  });
};

export const getVerificationComments = (id) => {
  return axios({
    method: "get",
    url: `${urls.commentUrl}/${id}`,
  });
};

export const rejectAddressProof = (data) => {
  return axios({
    method: "post",
    url: `${urls.rejectAddressProofUrl}`,
    data: data,
  });
};

export const rejectCAC = (data) => {
  return axios({
    method: "post",
    url: `${urls.rejectCACUrl}`,
    data: data,
  });
};

export const rejectOtherDocument = (data) => {
  return axios({
    method: "post",
    url: `${urls.rejectOtherDocumentUrl}`,
    data: data,
  });
};

export const rejectPassport = (data) => {
  return axios({
    method: "post",
    url: `${urls.rejectPassportUrl}`,
    data: data,
  });
};

export const rejectIdCard = (data) => {
  return axios({
    method: "post",
    url: `${urls.rejectIdCardUrl}`,
    data: data,
  });
};

export const rejectVideo = (data) => {
  return axios({
    method: "post",
    url: `${urls.rejectVideoUrl}`,
    data: data,
  });
};

export const rejectAmmban = (data) => {
  return axios({
    method: "post",
    url: `${urls.rejectAmmbanUrl}`,
    data: data,
  });
};

export const saveMetaData = (id, data) => {
  return axios({
    method: "post",
    url: `${urls.metaDataUrl}/${id}`,
    data: data,
  });
};

export const getLiveVideo = (id) => {
  return axios({
    method: "get",
    url: `${urls.liveVideoUrl}/${id}`,
  });
};

export const getMerchantTxns = (id, data, params) => {
  return axios({
    method: "post",
    url: `${urls.merchantUrl}/txns/${id}`,
    data: data,
    params,
  });
};

export const getMerchantBillings = (id, data) => {
  return axios({
    method: "post",
    url: `${urls.merchantUrl}/billings/${id}`,
    data: data,
  });
};

export const getMerchantBusinesses = (user_id) => {
  return axios({
    method: "post",
    url: `${urls.merchantUrl}/biz/${user_id}`,
  });
};

export const unVerifyMerchant = (id) => {
  return axios({
    method: "put",
    url: `${urls.merchantUrl}/${id}/unverify`,
  });
};

export const investigateMerchant = (id) => {
  return axios({
    method: "put",
    url: `${urls.merchantUrl}/${id}/investigate`,
  });
};

export const certifyForUpgrade = (id) => {
  return axios({
    method: "put",
    url: `${urls.merchantUrl}/${id}/certify`,
  });
};

export const updatePostNoCredit = (id) => {
  return axios({
    method: "put",
    url: `${urls.merchantUrl}/${id}/postnocredit`,
  });
};

export const updateExemptStatus = (id) => {
  return axios({
    method: "put",
    url: `${urls.merchantUrl}/${id}/exempt`,
  });
};

export const verifyBVNPhone = (id) => {
  return axios({
    method: "put",
    url: `${urls.merchantUrl}/${id}/verify_bvn_phone`,
  });
};

export const updateMerchantInfo = (id, data) => {
  return axios({
    method: "put",
    url: `${urls.merchantUrl}/${id}/update`,
    data: data,
  });
};

export const updateMerchantPndFalse = (id) => {
  return axios({
    method: "put",
    url: `${urls.merchantUrl}/${id}/pndfalse`,
  });
};

export const updateCashier = (id, data) => {
  return axios({
    method: "put",
    url: `${urls.merchantUrl}/${id}/update_cashier`,
    data: data,
  });
};

export const searchOuboundTxn = (id, data) => {
  return axios({
    method: "post",
    url: `${urls.merchantUrl}/out_txn_search/${id}`,
    data: data,
  });
};

export const searchInboundTxn = (id, data) => {
  return axios({
    method: "post",
    url: `${urls.merchantUrl}/in_txn_search/${id}`,
    data: data,
  });
};

export const updateRestrictionInfomation = (id, data) => {
  return axios({
    method: "put",
    url: `${urls.merchantUrl}/${id}/update_restriction`,
    data: data,
  });
};

export const sendLimitedUpgrade = (data) => {
  return axios({
    method: "post",
    url: `${urls.merchantUrl}/send_limited_upgrade`,
    data,
  });
};
export const sandboxLimitedUpgrade = (data) => {
  return axios({
    method: "post",
    url: `${urls.merchantUrl}/sandbox_limited_upgrade`,
    data,
  });
};

export const trashAccountService = (id) => {
  return axios({
    method: "put",
    url: `${urls.merchantUrl}/trash_acount/${id}`,
  });
};

export const removetrashAccountService = (id) => {
  return axios({
    method: "put",
    url: `${urls.merchantUrl}/untrash_acount/${id}`,
  });
};

export const resetAdminPassword = (data) => {
  return axios({
    method: "put",
    url: `${urls.merchantUrl}/reset_password`,
    data: data,
  });
};

export const saveNewRole = (data) => {
  return axios({
    method: "post",
    url: `${urls.addNewRoleUrl}`,
    data: data,
  });
};

export const editRole = (data) => {
  return axios({
    method: "put",
    url: `${urls.editRoleUrl}`,
    data: data,
  });
};

export const signOut = (data) => {
  return axios({
    method: "post",
    url: `${urls.signOutUrl}`,
  });
};

export const uploadAddressProof = (data, user_id) => {
  return axios({
    method: "post",
    url: `${urls.proofOfAddressUploaderUrl}/${user_id}`,
    data: data,
  });
};

export const uploadLiveVideo = (data, user_id) => {
  return axios({
    method: "post",
    url: `${urls.liveVideoUploadUrl}/${user_id}`,
    data: data,
  });
};

export const uploadIntlPassport = (data, user_id) => {
  return axios({
    method: "post",
    url: `${urls.passportPhotographUploaderUrl}/${user_id}`,
    data: data,
  });
};

export const uploadIDCard = (data, user_id) => {
  return axios({
    method: "post",
    url: `${urls.idCardUploadUrl}/${user_id}`,
    data: data,
  });
};

export const ammbanUpload = (data, id) => {
  return axios({
    method: "POST",
    url: `${urls.ammbanUrl}/${id}`,
    data,
  });
};

export const getAmmbanCertificate = (id) => {
  return axios({
    method: "get",
    url: `${urls.getAmmbanUrl}/${id}`,
  });
};

export const exemptBVNOTP = (user_id) => {
  return axios({
    method: "get",
    url: `${urls.exemptBVNOTPUrl}/${user_id}`,
  });
};

export const getDisputes = (data, page) => {
  return axios({
    method: "post",
    url: `${urls.disputesUrl}?page=${page}`,
    data: data,
  });
};

export const resolveDisputes = (data) => {
  return axios({
    method: "post",
    url: `${urls.resolveDisputesUrl}`,
    data: data,
  });
};

export const allAggregators = (data) => {
  return axios({
    method: "post",
    url: `${urls.aggregatorsUrl}`,
    data: data,
  });
};

export const aggregatorMembers = (data) => {
  return axios({
    method: "post",
    url: `${urls.aggregatorMembersUrl}`,
    data: data,
  });
};

export const getAggregator = (data) => {
  return axios({
    method: "post",
    url: `${urls.getAggregatorUrl}`,
    data: data,
  });
};
export const addMember = (data) => {
  return axios({
    method: "post",
    url: `${urls.addMemberUrl}`,
    data: data,
  });
};

export const sendNotificationService = (data) => {
  return axios({
    method: "POST",
    url: `${urls.sendNotificationUrl}`,
    data,
  });
};

export const stopNotificationService = () => {
  return axios({
    method: "GET",
    url: `${urls.stopNotificationUrl}`,
  });
};

export const getLoanApplicants = (startDate, endDate, pageNumber) => {
  return axios({
    method: "GET",
    url: `${urls.getLoanApplicantsUrl}`,
    params: {
      startdate: startDate,
      enddate: endDate,
      page: pageNumber ?? 1,
    },
  });
};

export const getLoanRepaymentService = (startDate, endDate, pageNumber) => {
  return axios({
    method: "GET",
    url: `${urls.getLoanRepaymentsUrl}`,
    params: {
      startdate: startDate,
      enddate: endDate,
      page: pageNumber ?? 1,
    },
  });
};
export const getLoanStats = (startDate, endDate, pageNumber) => {
  return axios({
    method: "GET",
    url: `${urls.getLoanStatsUrl}`,
    params: {
      startdate: startDate,
      enddate: endDate,
      page: pageNumber ?? 1,
    },
  });
};

export const getPasswordResetVideo = (merchantId, type) => {
  return axios({
    method: "GET",
    url: `${urls.getPasswordResetVideoUrl}/${merchantId}`,
    params: {
      type: type,
    },
  });
};
export const getLimitIncreaseVideo = (merchantId) => {
  return axios({
    method: "GET",
    url: `${urls.getIncreaseLimitVideoUrl}/${merchantId}`,
  });
};

export const respondToPinPasswordVideos = (data) => {
  return axios({
    method: "POST",
    url: `${urls.respondToMerchantPinPasswordVideosUrl}`,
    data,
  });
};

export const getMerchantPndLog = (merchantId) => {
  return axios({
    method: "GET",
    url: `${urls.getMerchantPndLogUrl}/${merchantId}`,
  });
};

export const respondToLimitVideo = (data) => {
  return axios({
    method: "POST",
    url: `${urls.respondToLimitVideoUrl}`,
    data,
  });
};

export const getTeamsRolesVideos = (merchantId) => {
  return axios({
    method: "GET",
    url: `${urls.getRolesTeamsVideosUrl}${merchantId}`,
  });
};
export const respondToTeamVideo = (userid, merchantId, data) => {
  return axios({
    method: "POST",
    url: `${urls.respondToTeamRolesVideoUrl}${userid}/${merchantId}`,
    data,
  });
};

export const reuploadRolesLiveVideo = (data, user_id) => {
  return axios({
    method: "PUT",
    url: `${urls.reuploadRolesVideoUrl}/${user_id}`,
    data: data,
  });
};

export const reuploadRolesLiveImage = (data, user_id) => {
  return axios({
    method: "PUT",
    url: `${urls.reuploadRolesImageUrl}/${user_id}`,
    data: data,
  });
};

export const liftRestriction = (id) => {
  return axios({
    method: "POST",
    url: `${urls.merchantUrl}/lift-user-restriction/${id}`,
  });
};
