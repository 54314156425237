import {Table, Divider, Button, Drawer, Tag, Pagination} from "antd";
import { CloseOutlined } from '@ant-design/icons';

import React, { useContext, useEffect, useState, useCallback } from "react";
import { appContext } from "../../../store/appContext";

import { getMerchantBVNAggregate, getMerchantBVNAggregateUsers, getMerchantNINAggregate, getMerchantNINAggregateUsers, signOut } from "../../../services/http_service";

import "./Merchants.scss";
import { useHistory, useLocation, Link } from "react-router-dom";
import NProgress from 'nprogress';

export default function BVN_NIN() {
	let history = useHistory();
	let location = useLocation();

    let context = useContext(appContext);
	let { globalFilter } = context;
	let [bvn_data, setBVNData] = useState([]);
    let [data_users, setDataUsers] = useState([]);
    let [nin_data, setNINData] = useState([]);
    let [target_drawer, setTargetDrawer] = useState("");
    let [bvn_pagination, setBvnPagination] = useState({current: 1, pageSize: 0, limit: 10});
    let [nin_pagination, setNinPagination] = useState({current: 1, pageSize: 0, limit: 10});
    let [data_users_pagination, setDataUsersPagination] = useState({current: 1, pageSize: 0, limit: 10});

	let handleLogout = useCallback(() => {
        NProgress.inc();

		signOut()
			.then((res) => {
				localStorage.clear();
				sessionStorage.clear();
				history.push("/?return_url=" + location.pathname);
			})
			.catch((err) => {
				localStorage.clear();
				sessionStorage.clear();
				history.push("/?return_url=" + location.pathname);
			}).finally(() => {
				NProgress.done();
			});

    }, [history, location.pathname]);

    let fetchBVNData = useCallback((page, limit) => {

        NProgress.inc();
		getMerchantBVNAggregate(page, limit)
			.then((res) => {
				setBVNData(res.data.data_aggregate);
                setBvnPagination({...bvn_pagination, pageSize: Number(res.data.total)});
			})
			.catch((err) => {
				if(err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();
				console.log(err);
			}).finally(() => {
                NProgress.done();
            });
    }, [handleLogout]);

	let fetchNINData = useCallback((page, limit) => {
        NProgress.inc();
        getMerchantNINAggregate(page, limit)
			.then((res) => {
				setNINData(res.data.data_aggregate);
                setNinPagination({...nin_pagination, pageSize: res.data.total});
			})
			.catch((err) => {
				if(err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();
				console.log(err);
			}).finally(() => {
                NProgress.done();
            });
	}, [handleLogout]);

    let fetchBVNUsers = useCallback((bvn) => {
        NProgress.inc();
        getMerchantBVNAggregateUsers(bvn)
			.then((res) => {
				setDataUsers(res.data.bvn_users_aggregate);
                setTargetDrawer("BVN");
                //setDataUsersPagination({...data_users_pagination, pageSize: res.data.total})
			})
			.catch((err) => {
				if(err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();
				console.log(err);
			})
            .finally(() => {
                NProgress.done();
            });
    }, [handleLogout]);

    let fetchNINUsers = (nin) => {
        NProgress.inc();
        getMerchantNINAggregateUsers(nin)
			.then((res) => {
				setDataUsers(res.data.nin_users_aggregate);
                setTargetDrawer("NIN");
			})
			.catch((err) => {
				if(err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();
				console.log(err);
			})
            .finally(() => {
                NProgress.done();
            });
    };

	useEffect(() => {
		fetchBVNData(1, 10);
        fetchNINData(1, 10);
	}, [fetchBVNData, fetchNINData]);

    let bvn_data_columns = [
        {
            title: 'BVN',
            dataIndex: 'bvn',
            render: (_, record) => (
                <Button onClick={() => {fetchBVNUsers(record.bvn)}} type="link" >{record.bvn}</Button>
            ),
            key:'bvn',
            width: "50%"
        },
        {
            title: 'Count',
            dataIndex: 'count',
            defaultSortOrder: 'descend',
            key: 'count',
            //sorter: (a, b) => Number(a.count) - Number(b.count),
            width: "50%"
        },
    ];
    
    let nin_data_columns = [
        {
            title: 'NIN',
            dataIndex: 'nin',
            render: (_, record) => (
                <Button onClick={() => {fetchNINUsers(record.nin)}} type="link" >{record.nin}</Button>
            ),
            key:'nin',
            width: "50%"
        },
        {
            title: 'Count',
            dataIndex: 'count',
            defaultSortOrder: 'descend',
            key: 'count',
            //sorter: (a, b) => Number(a.count) - Number(b.count),
            width: "50%"
        },
    ];

    const DataUsers = () => {
        let users_columns = [
            {
                title: 'Name',
                dataIndex: 'businessname',
                render: (_, record) => (
                    <Link to={"/merchant/" + record._id}>{record.businessname}</Link>
                ),
                key:'businessname'
            },
            {
                title: 'Email',
                dataIndex: 'businessemail',
                key:'businessemail'
            },
            {
                title: 'Address',
                dataIndex: 'address',
                key:'address'
            },
            {
                title: 'Industry',
                dataIndex: 'industry',
                key:'industry'
            },
            {
                title: 'Verified',
                dataIndex: 'verified',
                render: verified => (
                    <Tag color={verified ? "green" : "red"}>{verified ? "TRUE" : "FALSE"}</Tag>
                ),
                key:'verified'
            }
        ];

        return (
            <section className="merchants p-4">
                <p
                    onClick={() => setTargetDrawer("")}
                    style={{
                        margin: "30px 0",
                        fontSize: 22,
                        color: "#09427D",
                        fontWeight: "bold",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        cursor: "pointer",
                    }}
                    >
                    <CloseOutlined className="mr-3"/> {target_drawer}
                </p>
                <Table pagination={false} dataSource={data_users.length && data_users} columns={users_columns} />
                {/* <Pagination 
                    className="pt-4"
                    showSizeChanger
                    defaultCurrent={data_users_pagination.current} 
                    total={Number(data_users_pagination.pageSize)} 
                    onChange={(page, limit) => {
                        handleDataUsers(page, limit);
                    }}
                /> */}
                
            </section>
        )
    };

	return (
		<div className="merchants">
            <Table dataSource={bvn_data.length && bvn_data} pagination={false} columns={bvn_data_columns} />
            <Pagination 
                className="pt-4"
                showSizeChanger
                defaultCurrent={1} 
                total={Number(bvn_pagination.pageSize)} 
                onChange={(page, limit) => {
                    fetchBVNData(page, limit);
                }}
            />

            <Divider />

            <Table dataSource={nin_data.length && nin_data} pagination={false} columns={nin_data_columns} />

            <Pagination 
                className="pt-4"
                showSizeChanger
                defaultCurrent={1} 
                total={Number(nin_pagination.pageSize)} 
                onChange={(page, limit) => {
                    fetchNINData(page, limit);
                }}
            />

            <Divider />

            <Drawer closeIcon={<CloseOutlined />} visible={target_drawer === "BVN" || target_drawer === "NIN"} width={1300} closable={false}>
				<DataUsers />
			</Drawer>
		</div>
	);
};
