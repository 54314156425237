import { Empty } from "antd";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import caretleft from "../../assets/images/caretleft.svg";

export default function AppChart({ setVisible, chartData, showKey, title }) {
  const [pickedRange, setpickedRange] = useState("1M");

  const data = {
    labels: !chartData ? [] : Object.keys(chartData),
    datasets: [
      {
        label: "Merchants Transaction Totals",
        backgroundColor: "rgba(71, 160, 139, 0)",
        borderColor: "#219653",
        pointBackgroundColor: "#219653",
        pointBorderColor: "rgba(71, 160, 139, 0.1)",
        pointHoverBackgroundColor: "#55bae7",
        pointHoverBorderColor: "#55bae7",
        borderWidth: 2,
        // pointRadius: 0,
        //stack: 1,
        data: !chartData ? [] : Object.values(chartData),
      },
    ],
  };

  const options = {
    responsive: true,
    cornerRadius: 20,
    legend: {
      display: false,
    },
    type: "line",
    scales: {
      xAxes: [
        {
          barThickness: 16,
          maxBarThickness: 16,
          gridLines: {
            drawBorder: false,
            display: false,
            color: "#ffffff",
            zeroLineColor: "#ffffff",
          },
          //   stacked: true,
        },
      ],
      yAxes: [
        {
          ticks: {
            maxTicksLimit: 4,
            beginAtZero: true,
          },
          gridLines: {
            drawBorder: true,
            display: true,
            color: "#F2F2F2",
            zeroLineColor: "#F2F2F2",
          },
          //   stacked: true,
        },
      ],
    },
  };

  const ranges = ["12H", "1D", "1W", "1M", "3M", "6M", "1Y"];
  return (
    <div
      style={{
        background: "#FBFBFB",
        borderRadius: 10,
        padding: 50,
        height: "100%",
      }}
    >
      <p
        style={{
          margin: "30px 0",
        }}
      >
        <img
          onClick={() => setVisible(false)}
          style={{ cursor: "pointer" }}
          src={caretleft}
          alt=''
        />
      </p>
      <div
        className='white-bg'
        style={{
          borderRadius: 10,
          padding: 40,
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: 50,
          }}
        >
          <div
            style={{
              fontWeight: "600",
              fontSize: 22,
              color: "#09427D",
            }}
          >
            {title}
          </div>
          <div>
            <span
              style={{
                fontSize: 11,
                color: "rgba(9, 66, 125, 0.3)",
                fontWeight: "600",
                marginRight: 5,
              }}
            >
              Low
            </span>
            <span
              style={{
                fontSize: 15,
                color: "#09427D",
                fontWeight: "600",
              }}
            >
              {!Object.values(chartData).length
                ? "0"
                : numeral(Math.min(...Object.values(chartData))).format(
                    "0,0.00"
                  )}
            </span>

            <span
              style={{
                fontSize: 11,
                color: "rgba(9, 66, 125, 0.3)",
                fontWeight: "600",
                marginLeft: 30,
                marginRight: 5,
              }}
            >
              High
            </span>
            <span
              style={{
                fontSize: 15,
                color: "#09427D",
                fontWeight: "600",
              }}
            >
              {!Object.values(chartData).length
                ? "0"
                : numeral(Math.max(...Object.values(chartData))).format(
                    "0,0.00"
                  )}
            </span>
          </div>
        </div>
        {Object.values(chartData).length ? (
          <Line data={data} width={null} height={null} options={options} />
        ) : (
          <Empty />
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: 30,
          }}
        >
          {showKey &&
            ranges.map((range) => {
              return (
                <div
                  onClick={() => setpickedRange(range)}
                  key={range}
                  style={{
                    width: 42,
                    height: 32,
                    border: "0.5px solid rgba(71, 160, 139, 0.2)",
                    borderRadius: 5,
                    color: range === pickedRange ? "#fff" : "#09427D",
                    fontSize: 15,
                    fontWeight: "600",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    borderColor:
                      range === pickedRange
                        ? "#47A08B"
                        : "rgba(71, 160, 139, 0.2)",
                    backgroundColor: range === pickedRange ? "#47A08B" : "#fff",
                  }}
                >
                  {range}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
