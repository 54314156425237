import { CloseCircleFilled, EllipsisOutlined, LoadingOutlined } from "@ant-design/icons";
import {Empty, Input, notification, Pagination, Popover } from "antd";

import React, { useContext, useEffect, useState, useCallback } from "react";
import { appContext } from "../../../store/appContext";

import { deactivateCashier, getCashiers, searchCashiersData, signOut } from "../../../services/http_service";

import "./Merchants.scss";
import AppAlert from "../../../components/Alert/AppAlert";
import { useHistory, useLocation } from "react-router-dom";
import * as dayjs from "dayjs";
import NProgress from 'nprogress';

export default function Cashiers() {
	let history = useHistory();
	let location = useLocation();

	let context = useContext(appContext);
	let { allCashiers, globalFilter } = context;
	let [showAlert, setshowAlert] = useState({ show: false, type: "", description: "", });
	let [searchVal, setSearchVal] = useState("");
	let [searching, setSearching] = useState(false);
	let [dataToUse, setdataToUse] = useState({page: 1, pages: 0});
	//let [filter_options, setFilterOptions] = useState({ all_time: true, single_date: null, start_date: dayjs().subtract(7, "days").format("YYYY-MM-DD"), end_date: dayjs().format("YYYY-MM-DD") });

	let deactivateHandler = (id, active) => {
		
		deactivateCashier(id, active)
			.then((res) => {
				fetchAllCashiers(allCashiers.page);
				openAlert(
					true,
					"success",
					`Cashier successfully ${active ? "de" : ""}activated`
				);
			})
			.catch((err) => {
				openAlert(true, "error", err.response.data.message);
			});
	};

	let handleLogout = useCallback(() => {

        NProgress.inc();

		signOut()
			.then((res) => {
				localStorage.clear();
				sessionStorage.clear();
				history.push("/?return_url=" + location.pathname);
			})
			.catch((err) => {
				localStorage.clear();
				sessionStorage.clear();
				history.push("/?return_url=" + location.pathname);
			}).finally(() => {
				NProgress.done();
			});

    }, [history, location.pathname]);

	let fetchAllCashiers = useCallback((page, limit) => {
		NProgress.inc();

		getCashiers({limit: limit || 10, ...globalFilter, page: page || 1 })
			.then((res) => {
				setdataToUse(res.data);
			})
			.catch((err) => {
				if(err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();
				console.log(err);
			})
			.finally(() => {
				NProgress.done();
			})
	}, [globalFilter, handleLogout]);

	useEffect(() => {
		fetchAllCashiers();
	}, [fetchAllCashiers]);

	let openAlert = (show, type, description) => {
		setshowAlert({
			show,
			type,
			description,
		});
		setTimeout(() => {
			setshowAlert({
				...showAlert,
				show: false,
			});
		}, 6000);
	};

	let searchCashiers = (page, limit) => {
		if (searchVal) {
			setSearching(true);
			let data = {
				page: page || 1,
				name: searchVal,
				limit: limit
			};

			searchCashiersData(data)
				.then((res) => {
					console.log("res2: ", res.data);
					setdataToUse(res.data);
				})
				.catch((err) => {
					if(err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();

					notification.error({
						message: "ERROR",
						description: err.response
						? err.response.data.message
						: "An error occured. Please try again.",
					});
				})
				.finally(() => setSearching(false));
		}
	};

	return (
		<div className="merchants">
			<AppAlert
				show={showAlert.show}
				type={showAlert.type}
				description={showAlert.description}
			/>

			<Input
				style={{
					width: 200,
					height: 40,
					float: "right",
					marginTop: -113,
					marginRight: 115,
					border: `0.1px solid rgba(9, 66, 125, 0.123)`,
					backgroundColor: `#fff`,
					borderRadius: 5,
				}}
				onPressEnter={() => searchCashiers(null)}
				value={searchVal}
				suffix={
					searching ? (
						<LoadingOutlined />
					) : (
						<CloseCircleFilled
						onClick={() => {
							setSearchVal("");
							setdataToUse(allCashiers);
						}}
						/>
					)
				}
				onChange={(e) => setSearchVal(e.target.value)}
				bordered={false}
				placeholder="Search cashier name"
			/>

			<div className="trow thead bordered">
				<div className="tdata">Name</div>
				<div className="tdata email">Email</div>
				<div className="tdata">Phone</div>
				<div className="tdata address">Address</div>
				<div className="tdata email">Incentives</div>
			</div>

			{(!dataToUse.cashpoints || !dataToUse.cashpoints.length) && <Empty />}
			{dataToUse.cashpoints &&
				dataToUse.cashpoints.map((itm, i) => (
				<div className="single-item" key={itm.cashpoint[0]._id}>
					<div className="trow titem bordered">
						<div className="tdata">
							{itm.cashpoint[0].contactfirstname}{" "}
							{itm.cashpoint[0].contactlastname}
						</div>

						<div className="tdata email">{itm.cashpoint[0].email}</div>
						<div className="tdata">{itm.cashpoint[0].phone}</div>
						<div className="tdata address">
							{itm.cashpoint[0].address || "---"}
						</div>
						
						<div className="tdata email">
							<span>{itm.incentive}</span>
							<div className="ellipsis">
								<Popover
									placement="rightBottom"
									className="merchant-popover"
									content={
									<div className="inner-options">
										<div
										className="inner-option"
										onClick={() =>
											deactivateHandler(itm.cashpoint[0]._id, itm.active)
										}
										>
										{itm.cashpoint[0].active ? "De-activate" : "Activate"}{" "}
										Cashpoint
										</div>
									</div>
									}
									title={null}
									trigger="hover"
								>
									<EllipsisOutlined />
								</Popover>
							</div>
						</div>
					</div>
				</div>
			))}

			<div style={{ paddingTop: 30, textAlign: "left" }}>
				<Pagination
					defaultCurrent={dataToUse.page}
					defaultPageSize={10}
					total={10 * dataToUse.pages}
					onChange={(page, limit) => {
						searchVal ? searchCashiers(page, limit) : fetchAllCashiers(page, limit);
					}}
				/>
			</div>
		</div>
	);
}
