//export const baseUrl = `https://dart-merchant-web-staging.herokuapp.com`;
// export const baseUrl = `http://localhost:8075`;
export const baseUrl = process.env.REACT_APP_CORE_API;
export const baseUrl2 = process.env.REACT_APP_CORE_API_PLUTO;
//export const baseUrl = `https://pluto.vpay.africa`;
//export const baseUrl = `https://venus.vpay.africa`;

export const loginUrl = `${baseUrl}/service/demo/v1/query/user/login`;
export const login_url = `${baseUrl}/service/demo/v1/query/admin/user/login`;
export const topMerchantsUrl = `${baseUrl}/service/demo/v1/query/admin/topmerchants`;

export const merchantTnxUrl = `${baseUrl}/service/demo/v1/query/admin/merchant_transaction`;

export const sendNotificationUrl = `${baseUrl}/service/demo/v1/query/admin/send_pushnotification_broadcast`;
export const stopNotificationUrl = `${baseUrl}/service/demo/v1/query/admin/stop_pushnotification_broadcast`;

export const merchantSignupsUrl = `${baseUrl}/service/demo/v1/query/admin/merchantsignups`;
export const balanceUrl = `${baseUrl}/service/demo/v1/query/admin/balance`;
export const merchantGraphUrl = `${baseUrl}/service/demo/v1/query/admin/merchants_graph`;
export const merchantGrowthGraphUrl = `${baseUrl}/service/demo/v1/query/admin/marketer`;
export const failedSettlementsUrl = `${baseUrl}/service/demo/v1/query/admin/failedsettlements`;
export const activeMerchantsUrl = `${baseUrl}/service/demo/v1/query/admin/active_merchants`;
export const allMerchantsUrl = `${baseUrl}/service/demo/v1/query/admin/allmerchants`;
export const merchant_system_stats_url = `${baseUrl}/service/demo/v1/query/admin/merchant_system_stats`;
export const filterAllMerchantsUrl = `${baseUrl}/service/demo/v1/query/admin/filter_allmerchants`;
export const merchantInfoUrl = `${baseUrl}/service/demo/v1/query/admin/merchantdetails`;
export const allMarketersUrl = `${baseUrl}/service/demo/v1/query/admin/allmarketers`;
export const marketersReferralsUrls = `${baseUrl}/service/demo/v1/query/admin/marketer`;
export const merchantTnxGraphUrl = `${baseUrl}/service/demo/v1/query/admin/transactiontotalsgraph`;
export const aggregatorTnxGraphUrl = `${baseUrl}/service/demo/v1/query/admin/aggregatortransactiontotal`;
export const merchantCashpointsUrl = `${baseUrl}/service/demo/v1/query/admin/showCaspointByMerchant`;
export const endpointsUrl = `${baseUrl}/service/demo/v1/query/admin/endpointauditlog`;
export const loginAuditUrl = `${baseUrl}/service/demo/v1/query/admin/loginauditlog`;
export const tnxValueUrl = `${baseUrl}/service/demo/v1/query/admin/merchant_transaction_value`;
export const locationUrl = `${baseUrl}/service/demo/v1/query/admin/merchantbylocation`;
export const industryUrl = `${baseUrl}/service/demo/v1/query/admin/merchantbyindustry`;
export const channelUrl = `${baseUrl}/service/demo/v1/query/admin/merchantbychannel`;
export const cashiersUrl = `${baseUrl}/service/demo/v1/query/admin/cashier/allcashpoints`;
export const merchantUrl = `${baseUrl}/service/demo/v1/query/admin/merchant`;
export const cashierUrl = `${baseUrl}/service/demo/v1/query/admin/cashier`;
export const activeMerchantsListUrl = `${baseUrl}/service/demo/v1/query/admin/active_merchants_list`;
export const cashpointsignupsUrl = `${baseUrl}/service/demo/v1/query/admin/cashpointsignups`;
export const channelOptionsUrl = `${baseUrl}/service/demo/v1/query/admin/channeloptions`;
export const searchMerchantsUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/search`;
export const searchpMarketerUrl = `${baseUrl}/service/demo/v1/query/admin/marketer/search`;
export const tnxperMerchantUrl = `${baseUrl}/service/demo/v1/query/admin/revenuepermerchant`;
export const searchMerchantForRevUrl = `${baseUrl}/service/demo/v1/query/admin/rerevenuepermerchant/dropdown`;
export const tnxByBankUrl = `${baseUrl}/service/demo/v1/query/admin/transactionsbybank`;
export const rolesUrl = `${baseUrl}/service/demo/v1/query/admin/user/allroles`;
export const addAdminUrl = `${baseUrl}/service/demo/v1/query/admin/user/add/admin`;
export const allAdminsUrl = `${baseUrl}/service/demo/v1/query/admin/user/all/admin`;
export const searchCashierUrl = `${baseUrl}/service/demo/v1/query/admin/cashier/searchAllCashierByName`;

export const proofOfAddressUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/getProofOfAddress`;
export const internationalPassportUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/getInternationalPassport`;
export const idCardUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/getIdCard`;
export const bvnInfoUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/getBvnInfo`;
export const ninInfoUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/getNinInfo`;
export const bvnAggregateUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/get_bvn_aggregate`;
export const ninAggregateUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/get_nin_aggregate`;
export const commentUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/verificationComment`;
export const signOutUrl = `${baseUrl}/service/demo/v1/query/user/logout`;
export const liveVideoUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/getMerchantLivelinessVideo`;

export const rejectVideoUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/rejectLivelinessVideo`;
export const rejectPassportUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/rejectPassport`;
export const rejectCACUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/rejectCAC`;
export const rejectOtherDocumentUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/rejectOtherDocument`;
export const rejectIdCardUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/rejectIdCard`;
export const rejectAddressProofUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/rejectAddressProof`;
export const metaDataUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/meta_data`;
export const addNewRoleUrl = `${baseUrl}/service/demo/v1/query/admin/user/add/adminrole`;
export const editRoleUrl = `${baseUrl}/service/demo/v1/query/admin/user/edit/adminrole`;

export const idCardUploadUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/kyc/reupload_idcard`;
export const passportPhotographUploaderUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/kyc/reupload_passport`;
export const proofOfAddressUploaderUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/kyc/reupload_addressproof`;
export const liveVideoUploadUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/kyc/reupload_livevideo`;

export const getCACUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/get_cac_document`;
export const otherDocUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/get_other_docs`;

export const ammbanUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/kyc/reupload_ammban_certificate`;
export const getAmmbanUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/get_ammban_cert`;
export const rejectAmmbanUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/rejectAmmban`;

export const exemptBVNOTPUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/exempt_bvn_otp`;
export const txnUploadUrl = `${baseUrl}/service/demo/v1/query/admin/txn/upload`;

export const disputesUrl = `${baseUrl}/service/demo/v1/query/admin/allmerchanttransactiondisputes`;

export const resolveDisputesUrl = `${baseUrl}/service/demo/v1/query/admin/resolvedisputes`;

export const aggregatorsUrl = `${baseUrl}/service/demo/v1/query/admin/getallaggregators`;
export const aggregatorMembersUrl = `${baseUrl}/service/demo/v1/query/admin/aggregator_members`;

export const getAggregatorUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/getaggregator`;

export const addMemberUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/updateMerchantWithAggregatorCode`;

export const getLoanApplicantsUrl = `${baseUrl}/service/demo/v1/query/admin/loan-applicants`;
export const getLoanRepaymentsUrl = `${baseUrl}/service/demo/v1/query/admin/pending-loan-repayments`;
export const getLoanStatsUrl = `${baseUrl}/service/demo/v1/query/admin/loan-stats`;

export const getPasswordResetVideoUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/getMerchantPinReset`;
export const getIncreaseLimitVideoUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/getMerchantAccountLimitIncrease`;

export const getMerchantResetVideoUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/getMerchantPinReset`;
export const respondToMerchantPinPasswordVideosUrl = `${baseUrl}/service/demo/v1/query/admin/customer/customer/pin-password-reset-requests`;

export const getMerchantPndLogUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/getMerchantDndLogs`;
export const respondToLimitVideoUrl = `${baseUrl}/service/demo/v1/query/admin/customer/respond-to-limit-requests`;
export const getRolesTeamsVideosUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/role-accounts/`;
// export const respondToTeamRolesVideoUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/response-to-account-photo/`;
export const respondToTeamRolesVideoUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/dnd-logs-response/`;
export const reuploadRolesVideoUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/update-merchant-account-video`;
export const reuploadRolesImageUrl = `${baseUrl}/service/demo/v1/query/admin/merchant/update-merchant-account-photo`;
