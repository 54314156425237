import React from 'react';
import {Link} from "react-router-dom";

const NotFound = () => {
    return (
        <article className="animated fadeIn delay-0.5s pt-5">
    
            <section className="mx-auto w-75 pt-5">
                <div className="w-100 text-center mt-5 mb-3 font-weight-bold pt-5">
                    This page is not available.
                </div>

                <div className="text-center">
                    The link you followed may have been broken, or the page may have been removed. <Link to="/">Go back to Vpay Admin.</Link>
                </div>
            </section>

        </article>
    );
}

export default NotFound;