import { LeftOutlined, LoadingOutlined } from "@ant-design/icons";
import "./Merchants.scss";
import "./Live Videos/live_video.scss";
import { useState } from "react";
import { useQuery } from "react-query";
import { getMerchantPndLog } from "../../../services/http_service";
import { Empty, Table } from "antd";
import dayjs from "dayjs";

export const UserPndLog = ({ setTargetModal, merchant_id }) => {
  const { isLoading, data } = useQuery(
    ["getPasswordResetVideo"],
    () => getMerchantPndLog(merchant_id),
    { refetchOnWindowFocus: false, refetchOnMount: true }
  );

  const info = data?.data?.data;

  const columns = [
    {
      title: "Reason for PND change",
      dataIndex: "reason",
      key: "reason",
    },
    {
      title: "Change Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, record) => {
        return (
          <span>{dayjs(record?.createdAt).format("MMMM D, YYYY h:mm A")}</span>
        );
      },
    },
  ];

  return (
    <div>
      <div className="display-flex p10 align-center mb50">
        <div
          onClick={() => {
            setTargetModal({ drawer: "" });
          }}
          className="c-pointer"
        >
          <LeftOutlined />
        </div>
        <div className="ml10 fontw600 fSize12 colorBlue">User PND Log</div>
      </div>
      <div className="px40 mb50">
        {isLoading && (
          <div className="full-width display-flex justify-align-center">
            <LoadingOutlined height={50} />
          </div>
        )}
        {info && (
          <Table columns={columns} dataSource={info} pagination={false} />
        )}
      </div>
    </div>
  );
};
