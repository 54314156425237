import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AppContextProvider from "./store/appContext";
import flagsmith from "flagsmith";
import { FlagsmithProvider } from "flagsmith/react";
import "./index.css";
import "antd/dist/antd.css";
import "./App.scss";

//import App from './App';

import { QueryClient, QueryClientProvider } from "react-query";

import NotFound from "./components/not_found";
import SignIn from "./components/sign_in";
import Pages from "./components/pages_routes";
import MerchantDetail from "./components/pages/Merchants/MerchantDocs";

const queryClient = new QueryClient();
const {
  REACT_APP_FLAGSMITH_PRODUCTION_ENVIRONMENT,
  REACT_APP_FLAGSMITH_STAGING_ENVIRONMENT,
  REACT_APP_CORE_API,
} = process.env;

const flagOptions = {
  environmentID:
    REACT_APP_CORE_API === "https://venus.vpay.africa"
      ? REACT_APP_FLAGSMITH_STAGING_ENVIRONMENT
      : REACT_APP_FLAGSMITH_PRODUCTION_ENVIRONMENT,
};

const App = () => {
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <FlagsmithProvider options={flagOptions} flagsmith={flagsmith}>
          <AppContextProvider>
            <Switch>
              <Route exact path={["/", "/sign_in"]} component={SignIn} />
              <Route
                exact
                path="/merchant/:merchant_id"
                component={MerchantDetail}
              />
              <Route
                exact
                path={[
                  "/dashboard",
                  "/dashboard/bvn_nin",
                  "/dashboard/disputes",
                  "/dashboard/aggregators",
                  "/dashboard/merchants",
                  "/dashboard/roles",
                  "/dashboard/marketers",
                  "/dashboard/cashier",
                  "/dashboard/manager",
                  "/dashboard/endpoint-audit-log",
                  "/dashboard/user-audit-log",
                  "/dashboard/notifications",
                  "/dashboard/loans",
                ]}
                component={Pages}
              />
              <Route path="" component={NotFound} />
            </Switch>
          </AppContextProvider>
        </FlagsmithProvider>
      </QueryClientProvider>
    </Router>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
