import "./Table.scss";
import "../pages/Merchants/Merchants.scss";
import { useState } from "react";
import {
  Button,
  Drawer,
  Empty,
  Modal,
  notification,
  Spin,
  Tabs,
  Upload,
} from "antd";
import {
  RenderImageSmallerWidth,
  RenderVideoSmallerWidth,
} from "../VideoDisplay/VideoDisplay";
import {
  liftRestriction,
  respondToTeamVideo,
  reuploadRolesLiveImage,
  reuploadRolesLiveVideo,
} from "../../services/http_service";
import { useQueryClient } from "react-query";
import { openNotificationWithIcon } from "../../helpers";
import { RolesButtonRow } from "../VideoDisplay/RolesVideoDisplay";
import dayjs from "dayjs";
import {
  CloseOutlined,
  UploadOutlined,
  LoadingOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons";
import {
  cloudinary_api_key,
  cloudinary_cloud_name,
  cloudinary_upload_preset,
  cloudinary_url,
  cloudinary_video_url,
} from "../../store/utilities";

export const LoanTable = ({ headers }) => {
  return (
    <div>
      <div className="loantable-header">
        {headers.map((header) => {
          return <p>{header}</p>;
        })}
      </div>
    </div>
  );
};

export const RolesTable = ({ headers, data, isLoading, merchant_id }) => {
  const queryClient = useQueryClient();
  const [currentRecord, setCurrentRecord] = useState({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [target_modal, setTargetModal] = useState(false);
  const [videoDrawer, setVideoDrawer] = useState(false);
  const [is_loading, setIsLoading] = useState(false);

  const [fileList, setFileList] = useState({
    rolesVideo: [],
    rolesImage: [],
  });

  const updateRecord = (record) => {
    // open the drawer first
    setOpenDrawer(true);
    setCurrentRecord(record);
  };

  const closeDrawer = () => {
    setOpenDrawer(false);
  };

  const handleVideo = async (userid, status, reason) => {
    const data = {
      status: status,
      reason: reason,
    };
    setConfirmLoading(true);
    try {
      const res = await respondToTeamVideo(userid, merchant_id, data);
      // console.log(" the res is here ", res);
      if (res?.data?.status) {
        //invalidate the query
        queryClient.invalidateQueries({ queryKey: ["getMerchantRolesVideos"] });
      }
      openNotificationWithIcon("success", "Success", res?.data?.message);
      closeDrawer();
      setConfirmLoading(false);
    } catch (error) {
      console.log("error", error?.response);
      if (error?.response?.data?.message) {
        openNotificationWithIcon(
          "error",
          "Error",
          error?.response?.data?.message
        );
      }
      closeDrawer();
      setConfirmLoading(false);
    }
  };

  const closeReUploadDrawer = (drawerFunction) => {
    setFileList({
      rolesVideo: [],
      rolesImage: [],
    });
    drawerFunction(false);
  };

  //roles image
  let submitRolesImage = async () => {
    setIsLoading(true);

    let doc = new FormData();
    doc.append("file", fileList.rolesImage[0].originFileObj);
    doc.append("cloud_name", cloudinary_cloud_name);
    doc.append("upload_preset", cloudinary_upload_preset);
    doc.append("api_key", cloudinary_api_key);
    doc.append("folder", "RolesImage");

    try {
      let [res1] = await Promise.all([
        fetch(cloudinary_url, { method: "POST", body: doc }),
      ]);

      let doc_image = await res1.json();

      let result = await reuploadRolesLiveImage(
        { otheruser_image_url: doc_image.secure_url },
        currentRecord?._id
      );

      notification.info({
        message: "SUCCESS",
        description: result.data.message,
      });

      closeReUploadDrawer(setTargetModal);

      setIsLoading(false);
      setTargetModal(false);
      //invalidate the existing data and query the fresh data
      queryClient.invalidateQueries({ queryKey: ["getMerchantRolesVideos"] });
    } catch (error) {
      notification.error({
        message: "ERROR",
        description: error.response
          ? error.response.data?.error
          : "An error occured. Please try again.",
      });
      console.log(error);
      setIsLoading(false);
      setTargetModal(false);
    }
  };

  const handleItemChange = async (doc_name, { fileList: newFileList }) => {
    let errant_obj = newFileList.find((obj) => {
      return obj.status !== "done";
    });

    newFileList = newFileList.filter((obj) => {
      return obj.status === "done";
    });

    errant_obj.status = "done";
    newFileList.push(errant_obj);

    setFileList({
      ...fileList,
      [doc_name]: newFileList,
    });
  };

  const handleImageUploading = (record) => {
    setTargetModal(true);
    setCurrentRecord(record);
  };

  const handleVideoUploading = (record) => {
    setVideoDrawer(true);
    setCurrentRecord(record);
  };

  // roles video
  let submitRolesVideo = async () => {
    setIsLoading(true);

    let video = new FormData();
    video.append("file", fileList?.rolesVideo[0].originFileObj);
    video.append("cloud_name", cloudinary_cloud_name);
    video.append("upload_preset", cloudinary_upload_preset);
    video.append("api_key", cloudinary_api_key);
    // video.append("resource_type", "video");
    video.append("folder", "Roles Video");

    try {
      let res = await fetch(cloudinary_video_url, {
        method: "POST",
        body: video,
      });

      let cloud_video = await res.json();

      let info = {
        video_url: cloud_video.secure_url,
      };

      let result = await reuploadRolesLiveVideo(info, currentRecord?._id);

      notification.info({
        message: "SUCCESS",
        description: result.data.message,
      });

      closeReUploadDrawer(setVideoDrawer);
      queryClient.invalidateQueries({ queryKey: ["getMerchantRolesVideos"] });

      setIsLoading(false);
    } catch (error) {
      // console.log(error?.response, " error here ");
      notification.error({
        message: "ERROR",
        description: error.response
          ? error.response.data.error
          : "An error occured. Please try again.",
      });
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="display-flex justify-space-between align-center table-ptb-10">
        {headers.map((header, idx) => {
          return (
            <div className="full-width table-header-item" key={idx}>
              {header}
            </div>
          );
        })}
      </div>
      {data && data.length > 0 ? (
        data?.map((record, idx) => {
          return (
            <div
              className="display-flex justify-space-between align-center full-width table-item-wrapper"
              key={idx}
            >
              <div className="table-record-item">{record?.otheruser_name}</div>
              <div className="table-record-item">{record?.email}</div>
              <div className="table-record-item capitalize">{record?.role}</div>
              <div className="table-record-item red-color cursor">
                <span
                  className=""
                  onClick={() => updateRecord(record)}
                  style={{ marginRight: "1.2rem" }}
                >
                  View Live Video
                </span>
                <span
                  onClick={() => handleImageUploading(record)}
                  style={{ marginRight: "1.2rem" }}
                >
                  Update Photo
                </span>
                <span onClick={() => handleVideoUploading(record)}>
                  Update Video
                </span>
              </div>
            </div>
          );
        })
      ) : (
        <div>
          <Empty description="No Records" />
        </div>
      )}
      {isLoading && (
        <div className="d-flex justify-center align-center pt-50">
          <Spin size="large" />
        </div>
      )}
      {
        <Drawer
          width="500px"
          placement="right"
          open={target_modal}
          closable={false}
        >
          <div className="px-3">
            <CloseOutlined
              style={{ fontSize: "22px" }}
              className="py-3"
              onClick={() => closeReUploadDrawer(setTargetModal)}
            />

            <p>Upload merchant's roles image </p>
            <Upload
              listType="picture-card"
              fileList={fileList.rolesImage}
              onChange={(fileList) => {
                handleItemChange("rolesImage", fileList);
              }}
              showUploadList={{ showRemoveIcon: false, showPreviewIcon: false }}
              accept="image/png, image/jpeg, image/jpg"
            >
              {fileList?.rolesImage?.length < 1 && "+ Upload"}
            </Upload>

            {fileList?.rolesImage?.length === 1 && "+ Upload" && (
              <Button
                loading={is_loading}
                onClick={() => submitRolesImage()}
                className="mt-4"
                type="primary"
              >
                Submit
              </Button>
            )}
          </div>
        </Drawer>
      }
      {
        <Drawer
          width="500px"
          placement="right"
          open={videoDrawer}
          closable={false}
        >
          <div className="px-3">
            <CloseOutlined
              style={{ fontSize: "22px" }}
              className="py-3"
              onClick={() => closeReUploadDrawer(setVideoDrawer)}
            />

            <p>Upload merchant's roles liveliness video </p>
            <Upload
              fileList={fileList.rolesVideo}
              name="file"
              accept="mp4, video/mp4, video/quicktime, video/x-m4v, video/*"
              onChange={(fileList) => handleItemChange("rolesVideo", fileList)}
              showUploadList={{ showRemoveIcon: false, showPreviewIcon: false }}
              progress={{
                strokeColor: {
                  "0%": "#108ee9",
                  "100%": "#87d068",
                },
                strokeWidth: 3,
                format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
              }}
            >
              <Button icon={<UploadOutlined />}>
                {" "}
                {fileList.rolesVideo?.length < 1
                  ? "Click to Upload roles video"
                  : "Re-upload video"}
              </Button>
            </Upload>

            {fileList?.rolesVideo?.length === 1 && "+ Upload" && (
              <Button
                loading={is_loading}
                onClick={() => submitRolesVideo()}
                className="mt-4"
                type="primary"
              >
                Submit
              </Button>
            )}
          </div>
          ;
        </Drawer>
      }
      {
        <Drawer
          title="Roles Live Video"
          placement="right"
          width={720}
          open={openDrawer}
          onClose={() => closeDrawer()}
        >
          {currentRecord?.otheruser_image_url ? (
            <>
              <div className="pt-50 p-20 fontw600 fSize12">
                <div>
                  Team Member Name:
                  <span className="capitalize">
                    {" "}
                    {currentRecord?.otheruser_name}
                  </span>
                </div>
                <div>
                  Assigned Role:
                  <span className="capitalize"> {currentRecord?.role}</span>
                </div>
              </div>

              <Tabs
                defaultActiveKey="view_image"
                items={[
                  {
                    label: "View Image",
                    key: "view_image",
                    children: (
                      <div className="p-20">
                        <ImageTab
                          imageSource={currentRecord?.otheruser_image_url}
                        />
                        <div className="p-20">
                          {currentRecord?.otheruser_image_url && (
                            <RolesButtonRow
                              handleVideo={handleVideo}
                              vidInfo={currentRecord}
                              confirmLoading={confirmLoading}
                              acceptBtnText="Accept Image"
                              rejectBtnText="Reject Image"
                            />
                          )}
                        </div>
                      </div>
                    ),
                  },
                  {
                    label: "View Video",
                    key: "view_video",
                    children: (
                      <div className="p-20">
                        <VideoTab
                          videoSource={
                            currentRecord?.userLivelinessSubmissionLogs[
                              currentRecord?.userLivelinessSubmissionLogs
                                .length - 1
                            ]
                          }
                        />
                        <div className="p-20">
                          {currentRecord?.userLivelinessSubmissionLogs[
                            currentRecord?.userLivelinessSubmissionLogs.length -
                              1
                          ]?.video_url && (
                            <RolesButtonRow
                              handleVideo={handleVideo}
                              vidInfo={currentRecord}
                              confirmLoading={confirmLoading}
                            />
                          )}
                        </div>
                      </div>
                    ),
                  },
                ]}
              ></Tabs>
            </>
          ) : (
            <div className="pt-50 p-20 fontw600 fSize12">
              <div>
                NA:
                <span className="capitalize">No Video Available</span>
              </div>
            </div>
          )}
        </Drawer>
      }
    </div>
  );
};

const ImageTab = ({ imageSource }) => {
  return (
    <div>
      {imageSource !== undefined || imageSource !== "" ? (
        <RenderImageSmallerWidth imgSrc={imageSource} />
      ) : (
        <Empty description="Merchant did not upload a member image" />
      )}
    </div>
  );
};

const VideoTab = ({ videoSource }) => {
  return (
    <div>
      {videoSource !== undefined || videoSource !== "" ? (
        <RenderVideoSmallerWidth videoSrc={videoSource} />
      ) : (
        <Empty description="Merchant did not upload a liveliness video" />
      )}
    </div>
  );
};

//  CASHIER TABLE HERE
export const CashierRolesTable = ({
  headers,
  data,
  isLoading,
  merchant_id,
}) => {
  const queryClient = useQueryClient();
  const [currentRecord, setCurrentRecord] = useState({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [target_modal, setTargetModal] = useState(false);
  const [videoDrawer, setVideoDrawer] = useState(false);
  const [is_loading, setIsLoading] = useState(false);
  const [restrictionLoading, setRestrictionLoading] = useState(false);
  const [fileList, setFileList] = useState({
    rolesVideo: [],
    rolesImage: [],
  });

  const updateRecord = (record) => {
    // open the drawer first
    setOpenDrawer(true);
    setCurrentRecord(record);
  };
  const handleVideoUploading = (record) => {
    setVideoDrawer(true);
    setCurrentRecord(record);
  };

  const closeDrawer = () => {
    setOpenDrawer(false);
  };

  const handleImageUploading = (record) => {
    setTargetModal(true);
    setCurrentRecord(record);
  };

  const handleVideo = async (userid, status, reason) => {
    const data = {
      status: status,
      reason: reason,
    };
    setConfirmLoading(true);
    try {
      const res = await respondToTeamVideo(userid, merchant_id, data);
      if (res?.data?.status) {
        //invalidate the query
        queryClient.invalidateQueries({ queryKey: ["getMerchantRolesVideos"] });
      }
      openNotificationWithIcon("success", "Success", res?.data?.message);
      closeDrawer();
      setConfirmLoading(false);
    } catch (error) {
      console.log("error", error?.response);
      if (error?.response?.data?.message) {
        openNotificationWithIcon(
          "error",
          "Error",
          error?.response?.data?.message
        );
      }
      closeDrawer();
      setConfirmLoading(false);
    }
  };
  const closeReUploadDrawer = (drawerFunction) => {
    setFileList({
      rolesVideo: [],
      rolesImage: [],
    });
    drawerFunction(false);
  };

  const handleItemChange = async (doc_name, { fileList: newFileList }) => {
    let errant_obj = newFileList.find((obj) => {
      return obj.status !== "done";
    });

    newFileList = newFileList.filter((obj) => {
      return obj.status === "done";
    });

    errant_obj.status = "done";
    newFileList.push(errant_obj);

    setFileList({
      ...fileList,
      [doc_name]: newFileList,
    });
  };

  //roles image
  let submitRolesImage = async () => {
    setIsLoading(true);

    let doc = new FormData();
    doc.append("file", fileList.rolesImage[0].originFileObj);
    doc.append("cloud_name", cloudinary_cloud_name);
    doc.append("upload_preset", cloudinary_upload_preset);
    doc.append("api_key", cloudinary_api_key);
    doc.append("folder", "RolesImage");

    try {
      let [res1] = await Promise.all([
        fetch(cloudinary_url, { method: "POST", body: doc }),
      ]);

      let doc_image = await res1.json();

      let result = await reuploadRolesLiveImage(
        { otheruser_image_url: doc_image.secure_url },
        currentRecord?._id
      );

      notification.info({
        message: "SUCCESS",
        description: result.data.message,
      });

      closeReUploadDrawer(setTargetModal);
      queryClient.invalidateQueries({ queryKey: ["getMerchantRolesVideos"] });

      setIsLoading(false);
      setTargetModal(false);
    } catch (error) {
      notification.error({
        message: "ERROR",
        description: error.response
          ? error.response.data?.error
          : "An error occured. Please try again.",
      });
      console.log(error);
      setIsLoading(false);
      setTargetModal(false);
    }
  };

  // roles video
  let submitRolesVideo = async () => {
    setIsLoading(true);

    let video = new FormData();
    video.append("file", fileList?.rolesVideo[0].originFileObj);
    video.append("cloud_name", cloudinary_cloud_name);
    video.append("upload_preset", cloudinary_upload_preset);
    video.append("api_key", cloudinary_api_key);
    // video.append("resource_type", "video");
    video.append("folder", "Roles Video");

    try {
      let res = await fetch(cloudinary_video_url, {
        method: "POST",
        body: video,
      });

      let cloud_video = await res.json();

      let info = {
        video_url: cloud_video.secure_url,
      };

      let result = await reuploadRolesLiveVideo(info, currentRecord?._id);

      notification.info({
        message: "SUCCESS",
        description: result.data.message,
      });

      closeReUploadDrawer(setVideoDrawer);

      queryClient.invalidateQueries({ queryKey: ["getMerchantRolesVideos"] });
      setIsLoading(false);
    } catch (error) {
      // console.log(error?.response, " error here ");
      notification.error({
        message: "ERROR",
        description: error.response
          ? error.response.data.error
          : "An error occured. Please try again.",
      });
      setIsLoading(false);
    }
  };

  const handleLiftRestrictions = async (record) => {
    openNotificationWithIcon("info", "Info", "Removing Restrictions");
    try {
      let res = await liftRestriction(record?._id);
      // console.log(" res is this ", res?.data?.message);
      openNotificationWithIcon("success", "Success", res?.data?.message);
      queryClient.invalidateQueries({ queryKey: ["getMerchantRolesVideos"] });
    } catch (error) {
      // console.log(error?.response?.data?.message, " there is an error");
      openNotificationWithIcon(
        "error",
        "Error",
        error?.response?.data?.message.toUpperCase()
      );
    }
  };

  return (
    <div>
      <div className="display-flex justify-space-between align-center table-ptb-10">
        {headers.map((header, idx) => {
          return (
            <div className="full-width table-header-item" key={idx}>
              {header}
            </div>
          );
        })}
      </div>
      {data && data.length > 0 ? (
        data?.map((record, idx) => {
          return (
            <div
              className="display-flex justify-space-between align-center full-width table-item-wrapper"
              key={idx}
            >
              <div className="table-record-item">
                {dayjs(record?.createdon).format("MMMM D, YYYY h:mm A")}
              </div>
              <div className="table-record-item capitalize">
                {record?.otheruser_name}
              </div>
              <div className="table-record-item">{record?.email}</div>
              <div className="table-record-item capitalize">{record?.role}</div>
              <div className="table-record-item red-color">
                <span
                  style={{ marginRight: "1.2rem" }}
                  className={`${
                    record?.otheruser_postnodebit === false
                      ? "disabled-span button-4"
                      : "button-4"
                  }`}
                  onClick={() => handleLiftRestrictions(record)}
                >
                  Remove Restrictions
                </span>

                <span
                  onClick={() => updateRecord(record)}
                  style={{ marginRight: "1.2rem" }}
                  className="button-4"
                >
                  View Live Video
                </span>
                <span
                  onClick={() => handleImageUploading(record)}
                  style={{ marginRight: "1.2rem" }}
                  className="button-4"
                >
                  Update Photo
                </span>
                <span
                  onClick={() => handleVideoUploading(record)}
                  className="button-4"
                >
                  Update Video
                </span>
              </div>
            </div>
          );
        })
      ) : (
        <div>
          <Empty description="No Records" />
        </div>
      )}
      {isLoading && (
        <div className="d-flex justify-center align-center pt-50">
          <Spin size="large" />
        </div>
      )}

      <Drawer
        width="500px"
        placement="right"
        open={target_modal}
        closable={false}
      >
        <div className="px-3">
          <CloseOutlined
            style={{ fontSize: "22px" }}
            className="py-3"
            onClick={() => closeReUploadDrawer(setTargetModal)}
          />

          <p>Upload merchant's roles image </p>
          <Upload
            listType="picture-card"
            fileList={fileList.rolesImage}
            onChange={(fileList) => {
              handleItemChange("rolesImage", fileList);
            }}
            showUploadList={{ showRemoveIcon: false, showPreviewIcon: false }}
            accept="image/png, image/jpeg, image/jpg"
          >
            {fileList?.rolesImage?.length < 1 && "+ Upload"}
          </Upload>

          {fileList?.rolesImage?.length === 1 && "+ Upload" && (
            <Button
              loading={is_loading}
              onClick={() => submitRolesImage()}
              className="mt-4"
              type="primary"
            >
              Submit
            </Button>
          )}
        </div>
      </Drawer>
      <Drawer
        width="500px"
        placement="right"
        open={videoDrawer}
        closable={false}
      >
        <div className="px-3">
          <CloseOutlined
            style={{ fontSize: "22px" }}
            className="py-3"
            onClick={() => closeReUploadDrawer(setVideoDrawer)}
          />

          <p>Upload merchant's roles liveliness video </p>
          <Upload
            fileList={fileList.rolesVideo}
            name="file"
            accept="mp4, video/mp4, video/quicktime, video/x-m4v, video/*"
            onChange={(fileList) => handleItemChange("rolesVideo", fileList)}
            showUploadList={{ showRemoveIcon: false, showPreviewIcon: false }}
            progress={{
              strokeColor: {
                "0%": "#108ee9",
                "100%": "#87d068",
              },
              strokeWidth: 3,
              format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
            }}
          >
            <Button icon={<UploadOutlined />}>
              {" "}
              {fileList.rolesVideo?.length < 1
                ? "Click to Upload roles video"
                : "Re-upload video"}
            </Button>
          </Upload>

          {fileList?.rolesVideo?.length === 1 && "+ Upload" && (
            <Button
              loading={is_loading}
              onClick={() => submitRolesVideo()}
              className="mt-4"
              type="primary"
            >
              Submit
            </Button>
          )}
        </div>
        ;
      </Drawer>
      {
        <Drawer
          title="Roles Live Video"
          placement="right"
          width={720}
          open={openDrawer}
          onClose={() => closeDrawer()}
        >
          {currentRecord?.otheruser_image_url ? (
            <>
              <div className="pt-50 p-20 fontw600 fSize12">
                <div>
                  Team Member Name:
                  <span className="capitalize">
                    {" "}
                    {currentRecord?.otheruser_name}
                  </span>
                </div>
                <div>
                  Assigned Role:
                  <span className="capitalize"> {currentRecord?.role}</span>
                </div>
              </div>
              <Tabs
                defaultActiveKey="view_image"
                items={[
                  {
                    label: "View Image",
                    key: "view_image",
                    children: (
                      <div className="p-20">
                        <ImageTab
                          imageSource={currentRecord?.otheruser_image_url}
                        />
                        <div className="p-20">
                          {currentRecord?.otheruser_image_url && (
                            <RolesButtonRow
                              handleVideo={handleVideo}
                              vidInfo={currentRecord}
                              confirmLoading={confirmLoading}
                              acceptBtnText="Accept Image"
                              rejectBtnText="Reject Image"
                            />
                          )}
                        </div>
                      </div>
                    ),
                  },
                  {
                    label: "View Video",
                    key: "view_video",
                    children: (
                      <div className="p-20">
                        <VideoTab
                          videoSource={
                            currentRecord?.userLivelinessSubmissionLogs[
                              currentRecord?.userLivelinessSubmissionLogs
                                .length - 1
                            ]
                          }
                        />
                        <div className="p-20">
                          {currentRecord?.userLivelinessSubmissionLogs[
                            currentRecord?.userLivelinessSubmissionLogs.length -
                              1
                          ]?.video_url && (
                            <RolesButtonRow
                              handleVideo={handleVideo}
                              vidInfo={currentRecord}
                              confirmLoading={confirmLoading}
                            />
                          )}
                        </div>
                      </div>
                    ),
                  },
                ]}
              ></Tabs>
            </>
          ) : (
            <div className="pt-50 p-20 fontw600 fSize12">
              <div>
                NA:
                <span className="capitalize">No Video Available</span>
              </div>
            </div>
          )}
        </Drawer>
      }
    </div>
  );
};
