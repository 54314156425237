import React, { createContext, Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

import * as dayjs from "dayjs";
export const appContext = createContext();

export default withRouter(
  // withSnackbar(
  class AppContextProvider extends Component {
    state = localStorage.getItem("dartpayStore")
      ? JSON.parse(localStorage.getItem("dartpayStore"))
      : {
          userInfo: {},
          merchantSignups: 0,
          activeMerchants: 0,
          cashpointSignups: 0,
          merchantTnxProcessed: 0,
          failedSettlements: 0,
          merchantTxnCount: 0,
          merchantTxnValue: 0,
          globalFilter: {
            all_time: true,
            single_date: null,
            start_date: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
            end_date: dayjs().format("YYYY-MM-DD"),
          },
          boundaryType: "inbound",
          topMerchants: [],
          ledgerBalance: 0,
          graphData: [],
          growthGraphData: [],
          allMerchants: [],
          allMarketers: [],
          allCashiers: {},
          loginAudit: { log: [] },
          endpointsAudit: { auditlogs: [] },
          activeMerchantsList: [],
          // cashpointSignups: 0,
          allRoles: [],
          allAdmins: {},
        };

    componentDidMount() {
      let state = JSON.parse(localStorage.getItem("dartpayStore"));
      this.setState(state);
    }

    componentDidUpdate(prevProps, prevState) {
      if (this.state !== prevState) {
        // Whatever storage mechanism you end up deciding to use.
        localStorage.setItem("dartpayStore", JSON.stringify(this.state));
      } else {
        localStorage.setItem("dartpayStore", JSON.stringify(prevState));
      }
    }

    updateUserInfo = (userInfo) => this.setState({ userInfo });
    updateStateValue = (dashboardMetrics) => {
      this.setState(dashboardMetrics);
    };

    updateGlobalFilter = (globalFilter) => this.setState({ globalFilter });
    updateTopMerchants = (topMerchants) => this.setState({ topMerchants });
    updateLedgerBalance = (ledgerBalance) => this.setState({ ledgerBalance });
    updateGraphData = (graphData) => this.setState({ graphData });
    updateGrowthGraphData = (growthGraphData) => this.setState({ growthGraphData });

    logout = () => {
      // this.setState({
      // });
      // sessionStorage.clear();
    };

    render() {
      const { updateUserInfo, updateStateValue, updateGlobalFilter, updateTopMerchants, updateLedgerBalance, updateGraphData, updateGrowthGraphData } = this;
      return (
        <appContext.Provider
          value={{
            ...this.state,
            updateUserInfo,
            updateStateValue,
            updateGlobalFilter,
            updateTopMerchants,
            updateLedgerBalance,
            updateGraphData,
            updateGrowthGraphData,
          }}
        >
          {this.props.children}
        </appContext.Provider>
      );
    }
  },
  {
    position: "top-right",
  }
  // )
);
