import { Button, Empty, Modal } from "antd";
import "./VideoDisplay.scss";
import {
  changeVideoExt,
  formatMoney,
  openNotificationWithIcon,
} from "../../helpers";
import { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { useQueryClient } from "react-query";

export const RolesButtonRow = ({
  className,
  handleVideo,
  vidInfo,
  confirmLoading,
  acceptBtnText,
  rejectBtnText,
}) => {
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [type, setType] = useState("");

  const handleAccept = () => {
    setType("accept");
    setIsAcceptModalOpen(true);
  };
  const handleReject = () => {
    setType("reject");
    setIsRejectModalOpen(true);
  };

  return (
    <div className={`d-flex full-width j-space-between pt20 ${className}`}>
      {vidInfo.isotheruseractive === undefined || !vidInfo.isotheruseractive ? (
        <>
          <div>
            <Button
              size="large"
              style={{ backgroundColor: "#33D067", color: "white" }}
              onClick={() => handleAccept()}
            >
              {acceptBtnText ?? "Accept Live Video"}
            </Button>
          </div>
          <div>
            <Button size="large" danger onClick={() => handleReject()}>
              {rejectBtnText ?? "Reject Live Video"}
            </Button>
          </div>
        </>
      ) : (
        <div className="fontw600 fSize12">Video has been accepted</div>
      )}
      <RolesConfirmModal
        modalState={isAcceptModalOpen}
        stateHandler={setIsAcceptModalOpen}
        type={type}
        handleVideo={handleVideo}
        vidInfo={vidInfo}
        confirmLoading={confirmLoading}
      />
      <RolesRejectModal
        modalState={isRejectModalOpen}
        stateHandler={setIsRejectModalOpen}
        type={type}
        handleVideo={handleVideo}
        vidInfo={vidInfo}
        confirmLoading={confirmLoading}
      />
    </div>
  );
};

export const RolesConfirmModal = ({
  modalState,
  stateHandler,
  type,
  handleVideo,
  vidInfo,
  confirmLoading,
}) => {
  const status = type === "accept" ? "approved" : "declined";
  const queryClient = useQueryClient();
  const handleConfirm = async () => {
    const res = await handleVideo(
      vidInfo?._id,
      status,
      "Accepted by Admin personnel"
    );
    queryClient.invalidateQueries({ queryKey: ["getMerchantRolesVideos"] });
    stateHandler(false);
  };
  return (
    <Modal
      open={modalState}
      onCancel={() => stateHandler(false)}
      maskClosable={false}
      closable={false}
      onOk={() => handleConfirm()}
      confirmLoading={confirmLoading}
    >
      {type === "accept" && <p className="fontSize17 fw600">Confirm Video</p>}
    </Modal>
  );
};

export const RolesRejectModal = ({
  modalState,
  stateHandler,
  type,
  handleVideo,
  vidInfo,
  confirmLoading,
}) => {
  const status = type === "accept" ? "approved" : "declined";
  const [rejectionReason, setRejectionReason] = useState("");
  const queryClient = useQueryClient();

  const handleChange = (e) => {
    setRejectionReason(e.target.value);
  };
  const handleConfirm = async () => {
    if (rejectionReason.length < 1) {
      openNotificationWithIcon(
        "error",
        "Error",
        "Rejection reason is empty, please provide a reason for rejection."
      );
      return;
    }

    const res = await handleVideo(vidInfo?._id, status, rejectionReason);
    // console.log(res, " this is the res");
    stateHandler(false);
    queryClient.invalidateQueries({ queryKey: ["getMerchantRolesVideos"] });
    // clear reason
    setRejectionReason("");
  };
  return (
    <Modal
      open={modalState}
      onCancel={() => stateHandler(false)}
      maskClosable={false}
      closable={false}
      onOk={() => handleConfirm()}
      confirmLoading={confirmLoading}
    >
      {type === "reject" && (
        <div>
          <p className="fontSize17 fw600">
            Are you sure that you want to reject this video?
          </p>
          <TextArea
            allowClear
            placeholder="Enter rejection reason"
            onChange={(e) => handleChange(e)}
          />
        </div>
      )}
    </Modal>
  );
};
