import React, { useContext, useEffect, useCallback } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import { Col, Row, Select } from "antd";
import numeral from "numeral";
import NProgress from "nprogress";
import * as dayjs from "dayjs";

import icn from "../../../assets/images/bus.svg";
import { appContext } from "../../../store/appContext";

import "./Home.scss";

import {
  getbalance,
  getTopMerchants,
  getFailedSettlements,
  getActiveMerchants,
  getMerchantSignups,
  getMerchantsGraph,
  getMerchantsTxn,
  getTnxValue,
  getCashpointSignups,
  signOut,
} from "../../../services/http_service";

const Home = () => {
  let location = useLocation();

  let history = useHistory();
  let context = useContext(appContext);

  let {
    merchantSignups,
    activeMerchants,
    failedSettlements,
    ledgerBalance,
    topMerchants,
    merchantTxnCount,
    graphData,
    cashpointSignups,
    merchantTxnValue,

    globalFilter,
    updateTopMerchants,
    updateLedgerBalance,
    updateStateValue,
    updateGraphData,
    boundaryType,
  } = context;

  let handleLogout = useCallback(() => {
    NProgress.inc();

    signOut()
      .then((res) => {
        localStorage.clear();
        sessionStorage.clear();
        history.push("/?return_url=" + location.pathname);
      })
      .catch((err) => {
        localStorage.clear();
        sessionStorage.clear();
        history.push("/?return_url=" + location.pathname);
      })
      .finally(() => {
        NProgress.done();
      });
  }, [history, location.pathname]);

  let getDahboardData = useCallback(() => {
    NProgress.inc();

    getbalance()
      .then((res) => {
        updateLedgerBalance(res.data.ledger_balance);
      })
      .catch((err) => {
        if (err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();
      })
      .finally(() => {
        //NProgress.done();
      });

    NProgress.inc();
    getCashpointSignups({
      ...globalFilter,
      limit: 10,
    })
      .then((res) => {
        NProgress.done();
        updateStateValue({ cashpointSignups: res.data.cashier_sign_ups });
      })
      .catch((err) => {
        console.log({ err });
        if (err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();
      });

    NProgress.inc();
    getTopMerchants(globalFilter)
      .then((res) => {
        updateTopMerchants(res.data);
      })
      .catch((err) => {
        if (err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();
      })
      .finally(() => {
        //NProgress.done();
      });

    NProgress.inc();
    getFailedSettlements({ ...globalFilter, type: boundaryType })
      .then((res) => {
        updateStateValue({
          failedSettlements: res.data.transaction_count,
        });
      })
      .catch((err) => {
        if (err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();
      })
      .finally(() => {
        //NProgress.done();
      });

    NProgress.inc();
    getActiveMerchants(globalFilter)
      .then((res) => {
        updateStateValue({
          activeMerchants: res.data.length ? res.data[0].active_merchants : 0,
        });
      })
      .catch((err) => {
        if (err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();
      })
      .finally(() => {
        //NProgress.done();
      });

    NProgress.inc();
    getMerchantSignups(globalFilter)
      .then((res) => {
        updateStateValue({
          merchantSignups: res.data.signups_count,
        });
      })
      .catch((err) => {
        if (err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();
      })
      .finally(() => {
        //NProgress.done();
      });

    NProgress.inc();
    getMerchantsTxn({ ...globalFilter, type: boundaryType })
      .then((res) => {
        updateStateValue({
          merchantTxnCount: res.data.transactions_count,
        });
      })
      .catch((err) => {
        if (err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();
      })
      .finally(() => {
        //NProgress.done();
      });

    NProgress.inc();
    getTnxValue({ ...globalFilter, type: boundaryType })
      .then((res) => {
        updateStateValue({
          merchantTxnValue: res.data.processed_value,
        });
      })
      .catch((err) => {
        if (err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();
      })
      .finally(() => {
        //NProgress.done();
      });

    NProgress.inc();
    getMerchantsGraph()
      .then((res) => {
        NProgress.done();
        updateGraphData(
          res.data
            .map((itm) => {
              let shapedDate = `${itm._id.month}-01-${itm._id.year}`;
              return {
                ...itm,
                label: dayjs(shapedDate).format("MMM YYYY"),
                date: dayjs(shapedDate),
              };
            })
            .sort((a, b) => {
              if (a.date.isBefore(b.date)) return 1;
              if (!a.date.isBefore(b.date)) return -1;
              return 0;
            })
            .reverse()
        );
      })
      .catch((err) => {
        NProgress.done();
        if (err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();
      })
      .finally(() => {
        NProgress.done();
      });
  }, [boundaryType, globalFilter, handleLogout, updateGraphData, updateLedgerBalance, updateStateValue, updateTopMerchants]);

  useEffect(() => {
    getDahboardData();
  }, [getDahboardData]);

  const { Option } = Select;

  const data = {
    labels: graphData.map((d) => d.label),
    datasets: [
      {
        label: "Merchants Growth with Active Merchants",
        backgroundColor: "#09427D",
        borderColor: "#09427D",
        borderWidth: 1,
        //stack: 1,
        data: graphData.map((d) => d.total_merchants),
      },
      // {
      //   label: "Merchants Transaction Totals",
      //   backgroundColor: "rgba(71, 160, 139, 0.2)",
      //   borderColor: "rgba(71, 160, 139, 0)",
      //   borderWidth: 1,
      //   //stack: 1,
      //   data: [45, 79, 50, 41, 16, 85, 20, 50, 41, 16, 85, 20],
      // },
    ],
  };

  const options = {
    responsive: true,
    cornerRadius: 20,
    legend: {
      display: false,
    },
    type: "bar",
    scales: {
      xAxes: [
        {
          barThickness: 16,
          maxBarThickness: 16,
          gridLines: {
            drawBorder: false,
            display: false,
          },
          //   stacked: true,
        },
      ],
      yAxes: [
        {
          ticks: {
            maxTicksLimit: 4,
            beginAtZero: true,
          },
          gridLines: {
            drawBorder: true,
            display: true,
            color: "#F2F2F2",
            zeroLineColor: "#F2F2F2",
          },
          //   stacked: true,
        },
      ],
    },
  };

  return (
    <div className="home">
      <Row gutter={30}>
        <Col md={18}>
          <Row gutter={30}>
            <Col md={8}>
              <Link to="/dashboard/merchants">
                <div className="card white-bg">
                  <p className="card-title">Merchant Sign Ups</p>
                  <p className="rating">
                    <span className="count">{merchantSignups}</span>
                    {/* <span className='grade red'>
										<CaretDownFilled />
										2.00%
									</span> */}
                  </p>
                </div>
              </Link>
            </Col>

            <Col md={8}>
              <Link to="/dashboard/merchants?active=1">
                <div className="card white-bg">
                  <p className="card-title">Active Merchants </p>
                  <p className="rating">
                    <span className="count">{activeMerchants}</span>
                    {/* <span className='grade red'>
									<CaretDownFilled />
									2.00%
								</span> */}
                  </p>
                </div>
              </Link>
            </Col>

            <Col md={8}>
              <div className="card white-bg">
                <p className="card-title">Cashpoint Sign Ups</p>
                <p className="rating">
                  <span className="count">{cashpointSignups}</span>
                  {/* <span className='grade red'>
									<CaretDownFilled />
									2.00%
								</span> */}
                </p>
              </div>
            </Col>

            <Col md={8}>
              <div className="card white-bg">
                <p className="card-title">Total value of Merchant Transaction Processed</p>
                <p className="rating">
                  <span className="count">{numeral(merchantTxnValue).format("0,0.00")}</span>
                  {/* <span className='grade red'>
									<CaretDownFilled />
									2.00%
								</span> */}
                </p>
              </div>
            </Col>

            <Col md={8}>
              <div className="card white-bg">
                <p className="card-title">Merchant Transactions Processed</p>
                <p className="rating">
                  <span className="count">{numeral(merchantTxnCount).format("0,0.00")}</span>
                  {/* <span className='grade red'>
									<CaretDownFilled />
									2.00%
								</span> */}
                </p>
              </div>
            </Col>

            <Col md={8}>
              <div className="card white-bg">
                <p className="card-title">Failed Settlements</p>
                <p className="rating">
                  <span className="count">{failedSettlements}</span>
                  {/* <span className='grade red'>
									<CaretDownFilled />
									2.00%
								</span> */}
                </p>
              </div>
            </Col>
          </Row>
        </Col>

        <Col md={6}>
          <div className="green-card">
            <p className="label">System Account</p>
            <p className="label-title">Ledger Balance</p>
            <p className="amount">N{numeral(ledgerBalance).format("0,0.00")}</p>
          </div>
        </Col>
      </Row>

      <Row gutter={30}>
        <Col md={16}>
          <p className="sect-title">Merchant Growth</p>
          <div className="white-bg chart">
            <div className="head">
              <div className="indicators">
                <p>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="14" height="14" rx="5" fill="#09427D" />
                  </svg>
                  <span>Merchants Growth with Active Merchants </span>
                </p>
              </div>
            </div>
            <Bar data={data} width={null} height={null} options={options} />
          </div>
        </Col>
        <Col md={8}>
          <p className="sect-title">Top 5 Merchants</p>
          <div className="white-bg items">
            {topMerchants
              .filter((merchant) => merchant.merchant)
              .map((itm, idx) => (
                <div className="item" key={idx}>
                  <div className="wide">
                    <img src={icn} alt="" style={{ width: 45 }} />
                    <div className="info">
                      <p className="name">{itm.merchant.businessname || "Unspecified Merchant"}</p>
                      <span>{itm.merchant.address || "---"}</span>
                    </div>
                  </div>
                  <div className="narrow">
                    <p className="amt">{numeral(itm.amount).format("0,0.00")}</p>
                    {/* <div className='percent'>
								<CaretUpFilled />
								7.35%
								</div> */}
                  </div>
                </div>
              ))}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Home;
