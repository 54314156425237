import React, { useState, useCallback, useContext } from "react";
import { useHistory, useLocation, useRouteMatch, Link } from "react-router-dom";

import { DatePicker, Dropdown, Menu, Switch as ASwitch, Button } from "antd";

import filter from "../assets/images/filter.svg";
import avatar from "../assets/images/avatar.svg";
import Home from "./pages/Home/Home";
import Merchants from "./pages/Merchants/Merchants";
import Marketers from "./pages/Merchants/Marketers";
import Cashiers from "./pages/Merchants/Cashiers";
import Manager from "./pages/Manager/Manager";
import EndpoinLogs from "./pages/Merchants/EndpointLogs";
import UserLogs from "./pages/Merchants/UserLogs";
import Roles from "./pages/Roles/Roles";
import BVN_NIN from "./pages/Merchants/bvn_nin";
import { ROLES } from "../store/utilities";

import { useFeatureFlag } from "./hooks/use_Feature_Flag";

import "../assets/css/page_routes.scss";
import Sidebar from "../components/Sidebar/Sidebar";
import { appContext } from "../store/appContext";
import NProgress from "nprogress";
import store from "store";

import { signOut } from "../services/http_service";
import { Disputes } from "./pages/Merchants/Disputes";
import { Aggregators } from "./pages/Merchants/Aggregators";
import { Notifications } from "./pages/Merchants/Notifications";
import Loans from "./pages/Loan/Loan";

const { RangePicker } = DatePicker;

const Pages = (props) => {
  let match = useRouteMatch();
  let location = useLocation();
  let history = useHistory();
  const { isFlagRequired } = useFeatureFlag("admin.loan");

  let context = useContext(appContext);

  let { globalFilter, updateGlobalFilter, updateStateValue, userInfo } =
    context;

  let dateFormat = "YYYY-MM-DD";
  let [name, setName] = useState("Dashboard");
  let [visible, setVisible] = useState(false);
  let [visible_, setVisible_] = useState(false);

  let handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  let handleVisibleChange_ = (flag) => {
    setVisible_(flag);
  };

  let handleMenuClick = (e) => {
    if (e.key === "1") {
      setVisible(false);
    }
  };

  let onDateChange = (e) => {
    let single_date = e.format(dateFormat);
    updateGlobalFilter({
      ...globalFilter,
      single_date,
      end_date: null,
      start_date: null,
      all_time: false,
    });

    store.set("merchant_list_filters", {
      all_time: false,
      end_date: null,
      single_date: single_date,
      start_date: null,
      limit: store.get("merchant_list_filters").limit,
      page: store.get("merchant_list_filters").page,
      location: store.get("merchant_list_filters").location,
    });
  };

  let handleClearFilters = () => {
    updateGlobalFilter({
      ...globalFilter,
      single_date: null,
      end_date: null,
      start_date: null,
      all_time: true,
    });
  };

  let handleLogout = useCallback(() => {
    NProgress.inc();

    signOut()
      .then((res) => {
        localStorage.clear();

        sessionStorage.clear();
        history.push("/");
      })
      .catch((err) => {
        localStorage.clear();

        sessionStorage.clear();
        history.push("/");

        console.log(err);
      })
      .finally(() => {
        NProgress.done();
      });
  }, [history]);

  let logged_in_admin = {};
  if (sessionStorage.getItem("dartpay_token")) {
    logged_in_admin = JSON.parse(
      window.atob(sessionStorage.getItem("dartpay_token").split(".")[1])
    );
  } else {
    handleLogout();
  }

  let onchangeRange = (e, f, g) => {
    let start_date = e[0].format(dateFormat);
    let end_date = e[1].format(dateFormat);

    updateGlobalFilter({
      ...globalFilter,
      end_date,
      start_date,
      single_date: null,
      all_time: false,
    });

    store.set("merchant_list_filters", {
      all_time: false,
      end_date: end_date,
      single_date: null,
      start_date: start_date,
      limit: store.get("merchant_list_filters").limit,
      page: store.get("merchant_list_filters").page,
      location: store.get("merchant_list_filters").location,
    });
  };

  let menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item
        key="1"
        onClick={() => {
          updateGlobalFilter({
            ...globalFilter,
            single_date: null,
            end_date: null,
            start_date: null,
            all_time: true,
          });

          store.set("merchant_list_filters", {
            all_time: true,
            end_date: null,
            single_date: null,
            start_date: null,
            limit: 10,
            page: 1,
            location: store.get("merchant_list_filters").location,
          });
        }}
      >
        All Time
      </Menu.Item>

      <Menu.Item
        key="2"
        style={{ position: "relative", height: 35, cursor: "pointer" }}
      >
        <div
          style={{
            width: 200,
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 10,
            padding: 10,
            cursor: "pointer",
          }}
        >
          Single Date
        </div>
        <DatePicker
          onChange={onDateChange}
          format={dateFormat}
          style={{
            width: 200,
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 12,
            opacity: 0,
            marginRight: 100,
            cursor: "pointer",
          }}
        />
      </Menu.Item>

      <Menu.Item key="3" style={{ position: "relative", height: 35 }}>
        <div
          style={{
            width: 200,
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 10,
            padding: 10,
            cursor: "pointer",
          }}
        >
          Date Range
        </div>
        <RangePicker
          format={dateFormat}
          onChange={onchangeRange}
          style={{
            width: 200,
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 12,
            opacity: 0,
            marginRight: 100,
          }}
          bordered={false}
          placeholder="Date Range"
          size="small"
          renderExtraFooter={() => "extra footer"}
        />
      </Menu.Item>
    </Menu>
  );

  let profileMenu = (
    <Menu style={{ width: "130px" }}>
      <Menu.Item key="1">
        <div className="avatar-menu">
          <img src={avatar} alt="" />
          <div className="info">
            <p>{userInfo.name}</p>
            <span>{userInfo.role}</span>
          </div>
        </div>
      </Menu.Item>

      <Menu.Item
        key="2"
        style={{
          width: "150px",
          position: "relative",
          height: 35,
          cursor: "pointer",
        }}
      >
        <Link to="/dashboard/roles">
          <div
            style={{
              width: "150px",
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 10,
              padding: 10,
              cursor: "pointer",
            }}
          >
            Portal roles
          </div>
        </Link>
      </Menu.Item>

      <Menu.Item key="3" style={{ position: "relative", height: 35 }}>
        <div
          onClick={handleLogout}
          style={{
            width: 200,
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 10,
            padding: 10,
            cursor: "pointer",
          }}
        >
          Logout
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="dashboard">
      <div className={`side-wrap open`}>
        <Sidebar match={match} setName={setName} />
      </div>

      {/* <HashRouter> */}
      <div className={`dash-contents`}>
        <div className="text-right py-2">
          <div className="font-weight-bold">{logged_in_admin.username}</div>
          <small>
            {logged_in_admin.role && logged_in_admin.role.toUpperCase()}
          </small>
        </div>

        {/* <div className="text-right">
                        <Button type="link" onClick={handleClearFilters}>Clear Filters</Button>
                    </div> */}

        <nav>
          <span className="page-title">{name}</span>

          <div className="options" style={{ textAlign: "right" }}>
            {location.pathname !== "/dashboard/bvn_nin" &&
              location.pathname !== "/dashboard/merchants" &&
              location.pathname !== "/dashboard/disputes" &&
              location.pathname !== "/dashboard/aggregators" && (
                <Dropdown
                  onVisibleChange={handleVisibleChange}
                  visible={visible}
                  overlay={menu}
                  placement="bottomRight"
                >
                  <span className="filter">
                    <img
                      src={filter}
                      style={{ width: 20, marginRight: 7 }}
                      alt=""
                    />{" "}
                    Filter
                  </span>
                </Dropdown>
              )}
            {location.pathname === "/dashboard/manager" && (
              <Dropdown
                onVisibleChange={handleVisibleChange_}
                visible={visible_}
                overlay={profileMenu}
                placement="bottomRight"
              >
                <img style={{ marginLeft: 20 }} src={avatar} alt="" />
              </Dropdown>
            )}
            <br />
            <br />
            {location.pathname === "/dashboard/" ||
            location.pathname === "/dashboard/manager" ? (
              <ASwitch
                className="filter2"
                checkedChildren="Inbound"
                unCheckedChildren="Outbound"
                onChange={(e) =>
                  updateStateValue({
                    boundaryType: e ? "inbound" : "outbound",
                  })
                }
              />
            ) : null}
          </div>
        </nav>

        {logged_in_admin.role_level >= ROLES.super_admin_view.level && (
          <div>
            {location.pathname === "/dashboard/bvn_nin" && <BVN_NIN />}
            {location.pathname === "/dashboard/roles" && <Roles />}
            {location.pathname === "/dashboard/marketers" && <Marketers />}
            {location.pathname === "/dashboard/cashier" && <Cashiers />}
            {location.pathname === "/dashboard/manager" && <Manager />}
            {isFlagRequired && location.pathname === "/dashboard/loans" && (
              <Loans />
            )}
            {location.pathname === "/dashboard/endpoint-audit-log" && (
              <EndpoinLogs />
            )}
            {location.pathname === "/dashboard/user-audit-log" && <UserLogs />}
          </div>
        )}
        {location.pathname === "/dashboard/notifications" &&
          logged_in_admin.role_level >= ROLES.super_admin_view.level && (
            <Notifications />
          )}
        {(logged_in_admin.role_level >= ROLES.super_admin_view.level ||
          logged_in_admin.role_level === ROLES.business_management.level) && (
          <div>{location.pathname === "/dashboard" && <Home />}</div>
        )}

        {logged_in_admin.role_level < ROLES.super_admin_view.level &&
          ![
            "/dashboard",
            "/dashboard/merchants",
            "/dashboard/disputes",
            "/dashboard/aggregators",
          ].includes(location.pathname) && (
            <div>You don't have permissions to view this page.</div>
          )}

        {location.pathname === "/dashboard/merchants" && <Merchants />}
        {location.pathname === "/dashboard/disputes" && <Disputes />}
        {location.pathname === "/dashboard/aggregators" && <Aggregators />}
      </div>
      {/* </HashRouter> */}
    </div>
  );
};

export default Pages;
