import { CaretDownFilled, UploadOutlined } from "@ant-design/icons";
import { Col, Row, Menu, Dropdown, notification, Upload, Button, Divider, Radio, Table } from "antd";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

import icn from "../../../assets/images/bus.svg";
import filter from "../../../assets/images/filter.svg";
import { appContext } from "../../../store/appContext";

import numeral from "numeral";
import NProgress from "nprogress";

import "./Manager.scss";
import "./manager.css";
import {
  getTopMerchants,
  getTnxValue,
  getLocationMerchantsCount,
  getIndustryMerchantsCount,
  getChannelMerchantsCount,
  getTnxPerMerchant,
  searchMerchantForRevenue,
  getChannelOptions,
  getTnxByBank,
  signOut,
} from "../../../services/http_service";
import dayjs from "dayjs";
let publicKey = "SXZhbkFsZXJnYXBJc05vdE9ubHlBV2l0dHlPbmVIYW5uYWhCYW5la3VJc1RoZVNlY29uZCNOdW4=";
let requestId = new Date().getTime();

export default function Manager() {
  let history = useHistory();
  let location_ = useLocation();

  const context = useContext(appContext);
  const { topMerchants, globalFilter, boundaryType } = context;
  const [topMerchants_, setTopMerchants_] = useState(topMerchants);
  const [tnxValue, setTnxValue] = useState(0);
  const [locationCount, setLocationCount] = useState(0);
  const [industryCount, setIndustryCount] = useState(0);
  const [channelCount, setChannelCount] = useState(0);
  const [location, setLocation] = useState("lekki");
  const [industry, setIndustry] = useState("technology");
  const [channel, setChannel] = useState("retail");
  const [locationSearchVal, setLocationSearchVal] = useState("");
  const [industrySearchVal, setIndustrySearchVal] = useState("");
  const [channelSearchVal, setChannelSearchVal] = useState("");
  const [bankSearchVal, setBankSearchVal] = useState("");
  const [searchResults, setsearchResults] = useState([]);
  const [pickedMerchant, setPickedMerchant] = useState({});
  const [pickedMerchantTnxValue, setpickedMerchantTnxValue] = useState(0);
  const [channelOptions, setChannelOptions] = useState([]);
  const [tnxByBank, setTnxByBank] = useState([]);
  const [selectedBankOption, setSelectedBankOption] = useState({});
  const [_upload_type, setUploadType] = useState("txns");
  const [reversals_report, setReversalReport] = useState([]);

  let [file_list, setFileList] = useState([]);
  let [uploading, setUploading] = useState(false);

  const locations = ["Lekki", "Ajah", "Oshodi", "Gbagada", "Epe", "Bariga", "Festac", "Lekki", "Ajah", "Oshodi", "Gbagada", "Epe", "Bariga", "Festac"];
  const industries = ["Technology", "Business", "Education", "Real Estate"];
  const channels = ["retail", "wholesale"];

  const menu = (
    <Menu style={{ width: 150 }} onClick={(e) => fetchMerchantTnxValue(e.key)}>
      <Menu.Item key="all">All time</Menu.Item>
      <Menu.Item key="day">Day</Menu.Item>
      <Menu.Item key="week">Week</Menu.Item>
      <Menu.Item key="month">Month</Menu.Item>
      <Menu.Item key="year">Year</Menu.Item>
    </Menu>
  );

  const limits = [5, 10, 15, 20, 25];
  const dateFormat = "YYYY-MM-DD";

  const limitsMenu = (
    <Menu style={{ width: 150 }}>
      {limits.map((limit) => (
        <Menu.Item onClick={() => fetchMetchants(limit)} key={limit}>
          Top {limit}
        </Menu.Item>
      ))}
    </Menu>
  );

  const menu2 = (
    <Menu style={{ width: 150, padding: 12, height: 215, overflow: "auto" }}>
      <input
        autoFocus={true}
        style={{
          width: "100%",
          border: "1px solid #eee",
          height: 30,
          paddingLeft: 10,
          marginBottom: 10,
        }}
        onChange={(e) => setLocationSearchVal(e.target.value)}
      />
      {locations
        .filter((place) => (locationSearchVal ? place.toLowerCase().includes(locationSearchVal) : place))
        .map((location) => (
          <Menu.Item key={location} onClick={() => fetchCount(location)}>
            {location}
          </Menu.Item>
        ))}
    </Menu>
  );

  const menu3 = (
    <Menu style={{ width: 150, padding: 12, height: 215, overflow: "auto" }}>
      <input
        autoFocus={true}
        style={{
          width: "100%",
          border: "1px solid #eee",
          height: 30,
          paddingLeft: 10,
          marginBottom: 10,
        }}
        onChange={(e) => setIndustrySearchVal(e.target.value)}
      />
      {industries
        .filter((item) => (locationSearchVal ? item.toLowerCase().includes(industrySearchVal) : item))
        .map((industry, idx) => (
          <Menu.Item onClick={() => fetchIndustryCount(industry)} key={idx}>
            {industry}
          </Menu.Item>
        ))}
    </Menu>
  );

  const menu4 = (
    <Menu style={{ width: 150, padding: 12, height: 215, overflow: "auto" }}>
      <input
        autoFocus={true}
        style={{
          width: "100%",
          border: "1px solid #eee",
          height: 30,
          paddingLeft: 10,
          marginBottom: 10,
        }}
        onChange={(e) => setChannelSearchVal(e.target.value)}
      />
      {channelOptions
        .filter((item) => (channelSearchVal ? item.option.toLowerCase().includes(channelSearchVal) : item))
        .map((channel, idx) => (
          <Menu.Item onClick={() => fetchChannelCount(channel.option)} key={idx}>
            {channel.option}
          </Menu.Item>
        ))}
    </Menu>
  );

  const merchantsDropDown = (
    <Menu style={{ width: 150, padding: 12, height: 215, overflow: "auto" }}>
      <input
        style={{
          width: "100%",
          border: "1px solid #eee",
          height: 30,
          paddingLeft: 10,
          marginBottom: 10,
        }}
        placeholder="Search for merchant"
        onChange={(e) => searchMerchant(e.target.value)}
      />
      {searchResults.map((merc, idx) => (
        <Menu.Item onClick={() => fetchTnxPerMerchantValue(merc)} key={idx}>
          {merc.businessname}
        </Menu.Item>
      ))}
    </Menu>
  );

  const tnxByBankOptions = (
    <Menu style={{ width: 150, padding: 12, height: 215, overflow: "auto" }}>
      <input
        style={{
          width: "100%",
          border: "1px solid #eee",
          height: 30,
          paddingLeft: 10,
          marginBottom: 10,
        }}
        placeholder="Search banks"
        onChange={(e) => setBankSearchVal(e.target.value)}
      />

      {tnxByBank
        .filter((b) => (b.bank && bankSearchVal ? b.bank.toLowerCase().includes(bankSearchVal) : b))
        .map((option, idx) => (
          <Menu.Item onClick={() => setSelectedBankOption(option)} key={idx}>
            {option.bank}
          </Menu.Item>
        ))}
    </Menu>
  );

  const getFilterDates = (filter) => {
    let start = null;
    let end = null;

    switch (filter) {
      case "week":
        start = dayjs().subtract(1, "week").format(dateFormat);
        end = dayjs().format(dateFormat);
        break;
      case "month":
        start = dayjs().subtract(1, "month").format(dateFormat);
        end = dayjs().format(dateFormat);
        break;
      case "year":
        start = dayjs().subtract(1, "year").format(dateFormat);
        end = dayjs().format(dateFormat);
        break;
      default:
        break;
    }

    let range = {
      start_date: start,
      end_date: end,
    };

    let data = {
      type: boundaryType,
      all_time: filter !== "all" ? false : true,
      single_date: filter === "day" ? dayjs().format(dateFormat) : null,
      ...range,
    };

    return data;
  };

  let handleLogout = useCallback(() => {
    NProgress.inc();

    signOut()
      .then((res) => {
        localStorage.clear();
        sessionStorage.clear();
        history.push("/?return_url=" + location.pathname);
      })
      .catch((err) => {
        localStorage.clear();
        sessionStorage.clear();
        history.push("/?return_url=" + location.pathname);
      })
      .finally(() => {
        NProgress.done();
      });
  }, [history, location.pathname]);

  const searchMerchant = (name) => {
    searchMerchantForRevenue({ name })
      .then((res) => {
        setsearchResults(res.data);
      })
      .catch((err) => {
        if (err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();
        console.log(err);
        notification.error({
          message: "ERROR",
          description: err.response ? err.response.data.message : "An error occured. Please try again.",
        });
      });
  };

  const fetchTnxPerMerchantValue = (merc) => {
    getTnxPerMerchant({ mid: merc._id })
      .then((res) => {
        setPickedMerchant(merc);
        setpickedMerchantTnxValue(res.data.length ? res.data[0].total_revenue_earned : 0);
      })
      .catch((err) => {
        if (err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();
        notification.error({
          message: "ERROR",
          description: err.response ? err.response.data.message : "An error occured. Please try again.",
        });
      });
  };

  const fetchMerchantTnxValue = (filter) => {
    let data = getFilterDates(filter);
    getTnxValue(data)
      .then((res) => setTnxValue(res.data.processed_value))
      .catch((err) => {
        if (err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();
        notification.error({
          message: "ERROR",
          description: err.response ? err.response.data.message : "An error occured. Please try again.",
        });
      });
  };

  const fetchCount = (location) => {
    setLocation(location);
    let filters = {
      ...globalFilter,
      location,
    };
    getLocationMerchantsCount(filters)
      .then((res) => {
        setLocationCount(res.data.no_merchants);
      })
      .catch((err) => {
        if (err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();
        setLocationCount(0);
        notification.error({
          message: "ERROR",
          description: err.response ? err.response.data.message : "An error occured. Please try again.",
        });
      });
  };

  const fetchIndustryCount = (industry) => {
    setIndustry(industry);
    let filters = {
      ...globalFilter,
      industry,
    };
    getIndustryMerchantsCount(filters)
      .then((res) => {
        setIndustryCount(res.data.no_merchants);
      })
      .catch((err) => {
        if (err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();
        setIndustryCount(0);
        notification.error({
          message: "ERROR",
          description: err.response ? err.response.data.message : "An error occured. Please try again.",
        });
      });
  };

  const fetchChannelCount = (channel) => {
    setChannel(channel);
    let filters = {
      ...globalFilter,
      channel,
    };

    getChannelMerchantsCount(filters)
      .then((res) => {
        setChannelCount(res.data.no_merchants);
      })
      .catch((err) => {
        if (err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();

        setChannelCount(0);
        notification.error({
          message: "ERROR",
          description: err.response ? err.response.data.message : "An error occured. Please try again.",
        });
      });
  };

  const fetchMetchants = (limit) => {
    getTopMerchants({ ...globalFilter, limit })
      .then((res) => {
        setTopMerchants_(res.data);
      })
      .catch((err) => {
        if (err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();
        notification.error({
          message: "ERROR",
          description: err.response ? err.response.data.message : "An error occured. Please try again.",
        });
      });
  };

  let handleUpload = () => {
    let form_data = new FormData();

    if (file_list.length) {
      form_data.append("txn_file", file_list[0]);
      form_data.append("file_type", _upload_type);
    }

    setUploading(true);

    try {
      fetch("/service/demo/v1/query/admin/txn/upload", {
        method: "POST",
        body: form_data,
        headers: {
          "b-access-token": sessionStorage.getItem("dartpay_token"),
          publicKey: publicKey,
          requestId: requestId,
          //'Content-Type': 'multipart/form-data'
        },
      })
        .then(async (response) => {
          return response.json();
        })
        .then((result) => {
          notification.info({
            message: "SUCCESS",
            description: result.message ? result.message : "Successful.",
          });

          _upload_type === "reversals" && setReversalReport(result.data);
        })
        .finally(() => {
          setUploading(false);
        });
    } catch (error) {
      console.log("error uploading history: ", error);
      setUploading(false);

      notification.error({
        message: "ERROR",
        description: error.message ? error.message : "An error occured. Please try again.",
      });
    }
  };

  let handleRadioInput = (e) => {
    setUploadType(e.target.value);
  };

  const props = {
    onRemove: (file) => {
      setFileList([]);
    },

    beforeUpload: (file) => {
      setFileList([...file_list, file]);
      return false;
    },

    file_list,
  };

  let reversal_report_cols = [
    {
      title: "Already reversed",
      dataIndex: "already_reversed",
      key: "already_reversed",
    },
    {
      title: "Invalid Merchant name",
      dataIndex: "invalid_merchant_name",
      key: "invalid_merchant_name",
    },
    {
      title: "Invalid Non-inbound record",
      dataIndex: "invalid_noninbound_record",
      key: "invalid_noninbound_record",
    },
    {
      title: "Invalid outbound record",
      dataIndex: "invalid_outbound_record",
      key: "invalid_outbound_record",
    },
    {
      title: "Invalid reversal ID",
      dataIndex: "invalid_reversal_id",
      key: "invalid_reversal_id",
    },
    {
      title: "Invalid wallet record",
      dataIndex: "invalid_wallet_record",
      key: "invalid_wallet_record",
    },
    {
      title: "Successful reversals",
      dataIndex: "successful_reversal",
      key: "successful_reversal",
    },
  ];

  useEffect(() => {
    fetchMerchantTnxValue("all");
    fetchCount(location);
    fetchChannelCount(channel);
    fetchIndustryCount(industry);

    getChannelOptions()
      .then((res) => {
        setChannelOptions(res.data);
      })
      .catch((err) => {
        if (err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();
        notification.error({
          message: "ERROR",
          description: err.response ? err.response.data.message : "An error occured. Please try again.",
        });
      });

    getTnxByBank({ type: boundaryType })
      .then((res) => {
        setTnxByBank(res.data);
        setSelectedBankOption(res.data[0]);
      })
      .catch((err) => {
        if (err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();
        notification.error({
          message: "ERROR",
          description: err.response ? err.response.data.message : "An error occured. Please try again.",
        });
      });
  }, [boundaryType]);

  return (
    <div className="home">
      <Divider />

      <div className="mb-3">
        <div className="mb-2 text-muted small">Select upload type (Vpay Transactions / V Account Statement).</div>
        <div className="mb-3">
          <Radio.Group name="txns_reversals" defaultValue="txns" buttonStyle="solid" onChange={handleRadioInput} value={_upload_type}>
            <Radio.Button value="txns">Transactions</Radio.Button>
            <Radio.Button value="reversals">Reversals</Radio.Button>
          </Radio.Group>
        </div>

        <div>
          <Upload {...props} className="up_list" accept=".xlsx, .csv">
            <Button icon={<UploadOutlined />}>Select file</Button>
          </Upload>
        </div>

        <Button type="primary" onClick={handleUpload} disabled={file_list.length === 0} loading={uploading} style={{ marginTop: 16 }}>
          {uploading ? "Uploading" : "Start Upload"}
        </Button>
      </div>

      {_upload_type === "reversals" && <Table key={(_, idx) => idx} columns={reversal_report_cols} dataSource={reversals_report} bordered showSizeChanger={false} pagination={false} />}

      <Divider />

      <Row gutter={30}>
        <Col md={8}>
          <div className="card white-bg">
            <div className="card-title">
              <p>Merchant transaction value</p>
              <span>
                <Dropdown overlay={menu}>
                  <a style={{ color: "#09427d80" }} onClick={(e) => e.preventDefault()}>
                    <img src={filter} alt="" /> Filter
                  </a>
                </Dropdown>
              </span>
            </div>
            <p className="rating">
              <span className="count">{numeral(tnxValue).format("0,0.00")}</span>
              {/* <span className='grade red'>
						<CaretDownFilled />
						2.00%
					</span> */}
            </p>
          </div>
        </Col>
        <Col md={8}>
          <div className="card white-bg">
            <div className="card-title">
              <p>Total number transfers by Bank </p>

              <span>
                <Dropdown overlay={tnxByBankOptions} trigger="click">
                  <a style={{ color: "#09427d80" }} onClick={(e) => e.preventDefault()}>
                    <img src={filter} alt="" /> Filter
                  </a>
                </Dropdown>
              </span>
            </div>

            <div className="rating">
              <p style={{ marginBottom: 0, color: "green" }}>{selectedBankOption.bank}</p>
              <p>
                <span className="count">{numeral(selectedBankOption.no_of_transactions || 0).format("0,0")}</span>
              </p>
            </div>
          </div>
        </Col>
        <Col md={8}>
          <div className="card white-bg">
            <div className="card-title">
              <p>No of Merchants by Location</p>
              <span>
                <Dropdown overlay={menu2} trigger="click">
                  <a style={{ color: "#09427d80" }} onClick={(e) => e.preventDefault()}>
                    <img src={filter} alt="" /> Filter
                  </a>
                </Dropdown>
              </span>
            </div>
            <div className="rating">
              <p style={{ marginBottom: 0, color: "green" }}>{location}</p>
              <p>
                <span className="count">{numeral(locationCount).format("0,0")}</span>
                {/* <span className='grade red'>
						<CaretDownFilled />
						2.00%
						</span> */}
              </p>
            </div>
          </div>
        </Col>
      </Row>

      <Row gutter={30}>
        <Col md={16}>
          <Row>
            <Col md={12}>
              <div
                className="card white-bg"
                style={{
                  width: "95%",
                }}
              >
                <div className="card-title">
                  <p>Total number of Merchant by Industry</p>
                  <span>
                    <Dropdown overlay={menu3} trigger="click">
                      <a style={{ color: "#09427d80" }} onClick={(e) => e.preventDefault()}>
                        <img src={filter} alt="" /> Filter
                      </a>
                    </Dropdown>
                  </span>
                </div>

                <div className="rating">
                  <p style={{ marginBottom: 0, color: "green" }}>{industry}</p>
                  <p>
                    <span className="count">{numeral(industryCount).format("0,0")}</span>
                    {/* <span className='grade red'>
							<CaretDownFilled />
							2.00%
							</span> */}
                  </p>
                </div>
              </div>
            </Col>

            <Col md={12}>
              <div
                className="card white-bg"
                style={{
                  width: "95%",
                  marginRight: 15,
                  marginLeft: 15,
                }}
              >
                <div className="card-title">
                  <p>Total number of Merchant by Channel</p>
                  <span>
                    <Dropdown overlay={menu4} trigger="click">
                      <a style={{ color: "#09427d80" }} onClick={(e) => e.preventDefault()}>
                        <img src={filter} alt="" /> Filter
                      </a>
                    </Dropdown>
                  </span>
                </div>
                <div className="rating">
                  <p style={{ marginBottom: 0, color: "green" }}>{channel}</p>
                  <p>
                    <span className="count">{numeral(channelCount).format("0,0")}</span>
                  </p>
                </div>
              </div>
            </Col>

            <Col md={12}>
              <div className="card white-bg" style={{ width: "95%", marginRight: "15px" }}>
                <div className="card-title">
                  <p>Total Revenue Earned per merchant</p>
                  <span>
                    <Dropdown overlay={merchantsDropDown} trigger="click">
                      <a style={{ color: "#09427d80" }} onClick={(e) => e.preventDefault()}>
                        <img src={filter} alt="" /> Filter
                      </a>
                    </Dropdown>
                  </span>
                </div>
                <div className="rating">
                  <p style={{ marginBottom: 0, color: "green" }}>{pickedMerchant.businessname}</p>
                  <p>
                    <span className="count">{numeral(pickedMerchantTnxValue).format("0,0.00")}</span>
                  </p>
                </div>
              </div>
            </Col>

            <Col md={12}>
              <div className="card white-bg" style={{ width: "95%", marginLeft: "15px" }}>
                <div className="card-title">
                  <p>Total Expense (on DSA bonus)</p>
                  <span>
                    <img src={filter} alt="" /> Filter
                  </span>
                </div>
                <p className="rating">
                  <span className="count">0</span>
                  <span className="grade red">
                    <CaretDownFilled />
                    2.00%
                  </span>
                </p>
              </div>
            </Col>
          </Row>
        </Col>

        <Col md={8}>
          <div className="sect-title">
            <p>Top {topMerchants_.length} Merchants</p>
            <span style={{ fontSize: 14 }}>
              <Dropdown overlay={limitsMenu}>
                <a style={{ color: "#09427d80" }} onClick={(e) => e.preventDefault()}>
                  <img src={filter} alt="" /> Filter
                </a>
              </Dropdown>
            </span>
          </div>
          <div className="white-bg items">
            {topMerchants_
              .filter((merchant) => merchant.merchant)
              .map((itm, idx) => (
                <div className="item" key={idx}>
                  <div className="wide">
                    <img src={icn} alt="" style={{ width: 45 }} />
                    <div className="info">
                      <p className="name">{itm.merchant.businessname || "Unspecified Merchant"}</p>
                      <span>{itm.merchant.address || "---"}</span>
                    </div>
                  </div>
                  <div className="narrow">
                    <p className="amt">{numeral(itm.amount).format("0,0.00")}</p>
                    {/* <div className='percent'>
							<CaretUpFilled />
							7.35%
							</div> */}
                  </div>
                </div>
              ))}
          </div>
        </Col>
      </Row>
    </div>
  );
}
