import { Col, Row, Spin, Table, notification } from "antd";
import { useEffect, useState } from "react";
import "./Loan.scss";
import nProgress from "nprogress";
import {
  getLoanApplicants,
  getLoanStats,
} from "../../../services/http_service";
import * as dayjs from "dayjs";
import { formatMoney, numberWithCommas } from "../../../helpers";
import { Repayment } from "./Repayment";
import { LoanTable } from "../../Table/Table";

const Loans = () => {
  return (
    <div className="">
      <Spin spinning={false} />
      <Tabs />
    </div>
  );
};

const Tabs = () => {
  const [tabs, setTabs] = useState(1);

  const onClick = (num) => {
    setTabs(num);
  };
  return (
    <div className="paddingTop4">
      <div className="tab-container">
        <div
          className={`${
            tabs === 1 ? "tab-item-active cursor" : "tab-items cursor "
          }`}
          onClick={() => onClick(1)}
        >
          Overview
        </div>
        <div
          className={`${
            tabs === 2 ? "tab-item-active cursor" : "tab-items cursor"
          }`}
          onClick={() => onClick(2)}
        >
          Applicants
        </div>
        <div
          className={`${
            tabs === 3 ? "tab-item-active cursor" : "tab-items cursor"
          }`}
          onClick={() => onClick(3)}
        >
          Repayments
        </div>
      </div>
      {tabs === 1 && <LoanDashboard />}
      {tabs === 2 && <Applicants />}
      {tabs === 3 && <Repayment />}
    </div>
  );
};

const LoanDashboard = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const today = dayjs().format("YYYY-MM-DD");
  const getStatsForLoan = async () => {
    setLoading(true);
    try {
      let res = await getLoanStats("2023-01-01", today, 1);
      setData(res?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error here ", error);
      notification.open({
        message: "Error",
        description: error,
      });
    }
  };
  useEffect(() => {
    getStatsForLoan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="paddingTop4 greyBG">
      {loading && (
        <div className="d-flex justify-center align-center">
          <Spin size="large" />
        </div>
      )}
      {!loading && <DataRow data={data} />}
    </div>
  );
};

const DataRow = ({ data }) => {
  const innerData = data?.data;

  return (
    <div>
      <>
        <Row gutter={32}>
          <Col span={6}>
            <div className="dashboard-card">
              <p className="dashboard-card_title">Active Loans</p>
              <p className="dashboard-card_value">
                {innerData?.activeLoans[0]?.COUNT ?? "NA"}
              </p>
            </div>
          </Col>
          <Col span={6}>
            <div className="dashboard-card">
              <p className="dashboard-card_title">Active Loans Value </p>
              <p className="dashboard-card_value">
                ₦{" "}
                {formatMoney(
                  innerData?.activeLoans[0]?.ACTIVELOANVALUES ?? "00"
                ) ?? "NA"}
              </p>
            </div>
          </Col>
          <Col span={6}>
            <div className="dashboard-card">
              <p className="dashboard-card_title">Defaulting Loans</p>
              <p className="dashboard-card_value">
                {innerData?.defaultingLoans?.COUNT ?? "NA"}
              </p>
            </div>
          </Col>
          <Col span={6}>
            <div className="dashboard-card">
              <p className="dashboard-card_title">Defaulting Loans Value</p>
              <p className="dashboard-card_value">
                {innerData?.defaultingLoans?.COUNT ?? "NA"}
              </p>
            </div>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={6}>
            <div className="dashboard-card">
              <p className="dashboard-card_title">Loan Applicants</p>
              <p className="dashboard-card_value">
                {innerData?.LoanApplications ?? "NA"}
              </p>
            </div>
          </Col>
          <Col span={6}>
            <div className="dashboard-card">
              <p className="dashboard-card_title">Total Repaid Loans</p>
              <p className="dashboard-card_value">
                {innerData?.repaidLoans[0]?.COUNT ?? "NA"}
              </p>
            </div>
          </Col>
          <Col span={6}>
            <div className="dashboard-card">
              <p className="dashboard-card_title">Repaid Loans Value</p>
              <p className="dashboard-card_value">
                ₦
                {formatMoney(innerData?.repaidLoans[0]?.REPAIDLOANVALUES) ??
                  "NA"}
              </p>
            </div>
          </Col>
          <Col span={6}>
            <div className="dashboard-card">
              <p className="dashboard-card_title">Loan revenue generated</p>
              <p className="dashboard-card_value">
                ₦
                {formatMoney(innerData?.generatedRevenue[0]?.TOTALREVENUE) ??
                  "NA"}
              </p>
            </div>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={6}>
            <div className="dashboard-card">
              <p className="dashboard-card_title">
                Value of Processing Fee charged
              </p>
              <p className="dashboard-card_value">
                ₦
                {formatMoney(
                  innerData?.totalServiceCharge[0]?.totalServiceCharge
                ) ?? "NA"}
              </p>
            </div>
          </Col>
          <Col span={6}>
            <div className="dashboard-card">
              <p className="dashboard-card_title">Total Loan Value Given</p>
              <p className="dashboard-card_value">
                {innerData?.totalLoansGiven[0]?.totalLoansGiven ?? "NA"}
              </p>
            </div>
          </Col>
        </Row>
      </>
    </div>
  );
};

const Applicants = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const today = dayjs().format("YYYY-MM-DD");

  const fetchLoanApplicants = async () => {
    setLoading(true);
    try {
      nProgress.inc();
      const res = await getLoanApplicants("2023-01-01", today, 1);
      setData(res?.data?.data);
      setLoading(false);
      nProgress.done();
    } catch (error) {
      nProgress.done();
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchLoanApplicants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headers = ["Merchant Name","Eligibility check date","Requested Amount","Current Loan","Application Date","Loan Tenure","Merchant Code","Interest Rate","Loan Status","Approval Status"]

  const table_columns = [
    {
      title: "Merchant Name",
      dataIndex: "merchant",
      key: "merchant",
      render: (_, record) => {
        return (
          <span className="loan-table-item">
            {record?.merchant?.businessname}
          </span>
        );
      },
    },
    {
      title: "Eligibility check date",
      dataIndex: "Eligibility check date",
      key: "eligibility_check_date",
      render: (_, record) => {
        return (
          <span className="text-cut">
            {dayjs(record?.application_date).format("MMMM D, YYYY h:mm A")}
          </span>
        );
      },
    },
    {
      title: "Requested Amount",
      dataIndex: "requested_amount",
      key: "requested_amount",
    },
    {
      title: "Current Loan",
      dataIndex: "repayment_amount",
      key: "current_loan",
      render: (_, record) => {
        return (
          <span>
            {record.repayment_amount === 0 || !record.repayment_amount
              ? ""
              : formatMoney(record.repayment_amount)}
          </span>
        );
      },
    },
    {
      title: "Application Date",
      dataIndex: "application_date",
      key: "application_date",
      render: (_, record) => {
        return (
          <span className="text-cut">
            {dayjs(record?.application_date).format("MMMM D, YYYY h:mm A")}
          </span>
        );
      },
    },
    {
      title: "Loan Tenure",
      dataIndex: "loan_tenure",
      key: "loan_tenure",
    },
    {
      title: "Merchant Code",
      dataIndex: "merchant_code",
      key: "merchant_code",
    },
    {
      title: "Interest Rate",
      dataIndex: "interest_rate",
      key: "interest_rate",
    },
    {
      title: "Loan Status",
      dataIndex: "status",
      key: "loan_status ",
      render: (_, { status }) => {
        let color =
          status === "pending"
            ? "orange"
            : status === "overdue"
            ? "red"
            : "green";
        return (
          <span style={{ color }} className="loan-fw500">
            {status}
          </span>
        );
      },
    },
    {
      title: "Approval Status",
      dataIndex: "warranter_approval_status",
      key: "warranter_approval_status",
      render: (_, { warranter_approval_status }) => {
        let color =
          warranter_approval_status === "pending_approval"
            ? "orange"
            : warranter_approval_status === "declined"
            ? "red"
            : "green";
        return (
          <span style={{ color }} className="loan-fw500">
            {warranter_approval_status}
          </span>
        );
      },
    },
  ];

  return (
    <div className="loan-container table-responsive-sm">
      <div className="loan-header">Loan Applicants </div>
      <section>
        <Table
          columns={table_columns}
          dataSource={data}
          rowKey={(record) => record.id}
          loading={loading}
          size="small"
          bordered
        />

        <LoanTable headers={headers} />
      </section>
    </div>
  );
};
export default Loans;
