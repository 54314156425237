import { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, Col, Divider, Row, Spin, Table, Drawer, PageHeader, notification, Input, DatePicker, Space } from "antd";
import NProgress from "nprogress";

import "./Merchants.scss";

import { aggregatorMembers, allAggregators, getAggregatorTxnGraph } from "../../../services/http_service";
import moment from "moment";
import { LineChartOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { CustomLineChart } from "../../Chart/Line";

let { RangePicker } = DatePicker;

const BucketButton = ({ text, onClick, active_name }) => {
  return (
    <Col span={4}>
      <Card bordered className={`crd ${active_name === text && "active_bucket"}`}>
        <Button style={{ fontSize: "12px" }} className="px-0" onClick={() => onClick()} type="link">
          {text}
        </Button>
      </Card>
    </Col>
  );
};

export const Aggregators = () => {
  const dateFormat = "YYYY-MM-DD";
  const [is_fetching, setIsFetching] = useState(false);
  const [tableDetails, setTableDetails] = useState([]);
  const [openDrawer, setDrawerOpen] = useState(false);
  const [chartDrawer, setChartDrawer] = useState(false);
  const [currentDetails, setCurrentDetails] = useState({});
  const [email, setEmail] = useState("");
  const [date, setDate] = useState({
    start_date: "",
    end_date: "",
  });
  const [filter_pagination, setFilterPagination] = useState({ page: 1, total: 0 });
  const [chartData, setChartData] = useState({
    debit: [],
    credit: [],
  });

  function disabledDate(current) {
    return current && current > moment().endOf("day");
  }

  const fetchAggregators = async (page, defaultMail, defaultData) => {
    try {
      let data = {
        page: page || 1,
        email: defaultMail ?? email,
        start_date: date.start_date ?? null,
        end_date: date.end_date ?? null,
      };
      NProgress.inc();
      setIsFetching(true);
      const res = await allAggregators(defaultData ?? data);
      setTableDetails(res.data);
      setIsFetching(false);
      setFilterPagination({ page: res.data.page, total: Number(res.data.pages) * 10 });
      NProgress.done();
    } catch (error) {
      setTableDetails("error", error);
      setIsFetching(false);
      NProgress.done();
    }
  };

  const handleSingleDate = (e) => {
    let single_date = e.format(dateFormat);
    let data = {
      email,
      single_date,
      page: 1,
    };
    fetchAggregators(1, null, data);
  };

  const handleDateRange = (e) => {
    let start_date = e[0].format(dateFormat);
    let end_date = e[1].format(dateFormat);
    setDate({ start_date, end_date });
    let data = {
      email,
      start_date,
      end_date,
      page: 1,
    };
    fetchAggregators(1, null, data);
  };

  useEffect(() => {
    fetchAggregators(1);
  }, []);

  const handleClearFilter = () => {
    setEmail("");
    setDate({ start_date: "", end_date: "" });
    fetchAggregators(1);
  };

  const fetchTransactionDetails = async () => {
    const id = currentDetails?.aggregatorid;
    if (id === undefined) {
      notification.error({
        message: "Error",
        description: "No ID found",
      });
      return;
    }
    const [debit, credit] = await Promise.all([getAggregatorTxnGraph(id, "credit"), getAggregatorTxnGraph(id, "debit")]);

    setChartData({
      debit: debit.data?.wallet
        .map((itm) => {
          let shapedDate = `${itm._id.month}-01-${itm._id.year}`;
          return {
            ...itm,
            label: dayjs(shapedDate).format("MMM YYYY"),
            date: dayjs(shapedDate),
          };
        })
        ?.sort((a, b) => {
          if (a.date.isBefore(b.date)) return 1;
          if (!a.date.isBefore(b.date)) return -1;
          return 0;
        })
        .reverse(),

      credit: credit.data?.wallet.map((itm) => {
        let shapedDate = `${itm._id.month}-01-${itm._id.year}`;
        return {
          ...itm,
          label: dayjs(shapedDate).format("MMM YYYY"),
          date: dayjs(shapedDate),
        };
      }),
    })
      ?.sort((a, b) => {
        if (a.date.isBefore(b.date)) return 1;
        if (!a.date.isBefore(b.date)) return -1;
        return 0;
      })
      .reverse();
  };

  useEffect(() => {
    (async () => {
      if (currentDetails.aggregatorid) {
        await fetchTransactionDetails();
      }
    })();
  }, [currentDetails.aggregatorid]);

  const table_columns = [
    {
      title: "Business Name",
      dataIndex: "businessname",
      key: "businessname",
    },
    {
      title: "Business Phone",
      dataIndex: "businessphone",
      key: "businessphone",
    },
    {
      title: "Business Email",
      dataIndex: "businessemail",
      key: "businessemail",
    },
    {
      title: "Aggregator ID",
      dataIndex: "aggregatorid",
      key: "aggregatorid",
    },
    {
      title: "Industry",
      dataIndex: "industry",
      key: "industry",
    },
    {
      title: "Referral link",
      dataIndex: "referrallink",
      key: "referrallink",
      render: (text, record) => {
        return (
          <a href={text} target="_blank">
            {text}
          </a>
        );
      },
    },
  ];

  let FilterMenu = useCallback(() => {
    return (
      <div className="text-right mt-3 mb-3">
        <Space direction="vertical">
          <div key="2" style={{ cursor: "pointer" }} className="pb-2">
            <DatePicker onChange={(e) => handleSingleDate(e)} format={dateFormat} disabledDate={disabledDate} placeholder="Single Date" />
          </div>

          <div key="3">
            <RangePicker format={dateFormat} disabledDate={disabledDate} onChange={(e) => handleDateRange(e)} />
          </div>
          <div key="4">
            <Button onClick={handleClearFilter} type="link">
              Clear Filters
            </Button>
          </div>
        </Space>
      </div>
    );
  }, []);

  return (
    <div className="merchants">
      <div className="text-right">
        <Input onPressEnter={() => fetchAggregators()} style={{ maxWidth: "250px" }} value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Search by email or code" />
      </div>
      <FilterMenu />
      <Spin spinning={is_fetching}>
        <Divider />
        <div className="site-card-wrapper">
          <Row align="bottom" gutter={16}>
            <BucketButton
              active_name="Aggregators"
              text="Aggregators"
              onClick={() => {
                setEmail("");
                fetchAggregators(1, "");
              }}
            />
          </Row>
        </div>
        <Divider />
        {tableDetails?.aggregators && (
          <Table
            pagination={{
              total: Number(filter_pagination.total),
              current: Number(filter_pagination.page),
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  setCurrentDetails(record);
                  setDrawerOpen(true);
                },
              };
            }}
            onChange={(event) => fetchAggregators(event.current)}
            showSizeChanger
            className="ant-dispute-table"
            bordered
            size="small"
            rowKey={(record) => record._id}
            columns={table_columns}
            dataSource={tableDetails.aggregators}
          />
        )}
      </Spin>
      <Drawer visible={openDrawer} width={800} onClose={() => setDrawerOpen(false)} closable={true}>
        <Row>
          {currentDetails && openDrawer !== "cashpoint" && <AggregatorMembers setChartDrawer={setChartDrawer} aggregatorCode={currentDetails.aggregatorid} />}
          {!currentDetails && <PageHeader className="site-page-header px-0" title="Empty Content" />}
        </Row>
      </Drawer>
      <Drawer placement="left" visible={chartDrawer} width={1100} onClose={() => setChartDrawer(false)} closable={false}>
        <CustomLineChart chartData={chartData} />
      </Drawer>
    </div>
  );
};

export const AggregatorMembers = ({ aggregatorCode, setChartDrawer }) => {
  const [is_fetching, setIsFetching] = useState(false);
  const [details, setDetails] = useState({});
  const history = useHistory();

  const table_columns = [
    {
      title: "Business Name",
      dataIndex: "businessname",
      key: "businessname",
    },
    {
      title: "Business Phone",
      dataIndex: "businessphone",
      key: "businessphone",
    },
    {
      title: "Business Email",
      dataIndex: "businessemail",
      key: "businessemail",
    },
    {
      title: "Industry",
      dataIndex: "industry",
      key: "industry",
    },
  ];

  const fetchAggregatorMembers = async () => {
    try {
      NProgress.inc();
      setIsFetching(true);
      const res = await aggregatorMembers({ aggregatorCode });
      setDetails(res.data);
      NProgress.done();
      setIsFetching(false);
    } catch (error) {
      notification.error({
        message: "Error",
        description: error.response?.data?.message,
      });
      setIsFetching(false);
      NProgress.done();
    }
  };

  useEffect(() => {
    fetchAggregatorMembers();
  }, [aggregatorCode]);

  return (
    <Spin spinning={is_fetching}>
      <Col style={{ marginLeft: "3em" }} md={24}>
        <Row>
          <Col md={24}>
            <PageHeader className="site-page-header px-0" title="Members" />
          </Col>
          <Col md={24}>
            <Button type="link" onClick={() => setChartDrawer(true)}>
              Click to view Aggregator Transaction Chart <LineChartOutlined />{" "}
            </Button>
          </Col>
        </Row>
        {details.members && details.members.length > 0 && (
          <Table
            showSizeChanger
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  history.push(`/merchant/${record._id}`);
                }, // click row
              };
            }}
            className="ant-dispute-table"
            bordered
            size="small"
            rowKey={(record) => record._id}
            columns={table_columns}
            dataSource={details.members}
          />
        )}
        {details.members && details.members.length < 1 && <PageHeader className="site-page-header px-0" title="This Aggregator has no member" />}
      </Col>
    </Spin>
  );
};
