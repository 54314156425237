import { Alert } from "antd";
import React, { useEffect, useState } from "react";

export default function AppAlert({ type, description, show }) {
  return (
    <>
      {show && (
        <div
          style={{
            position: "fixed",
            top: 20,
            right: 20,
            zIndex: "123467654345",
            width: 400,
          }}
        >
          <Alert
            message={type}
            description={description}
            type={type}
            showIcon
          />
        </div>
      )}
    </>
  );
}
