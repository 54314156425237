import { Button, Col, Drawer, Input, notification, Row, Card, Divider, Tag, Form, Switch, Spin, Table, Space, DatePicker } from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";

import AppChart from "../../../components/Chart/Chart";
import { CloseCircleFilled, LoadingOutlined } from "@ant-design/icons";

import {
  getMerchantInfo,
  getMerchantsTxnGraph,
  getMerchantCashpoints,
  getAllMerchants,
  getActiveMerchantsList,
  searchMerchantData,
  activateBank,
  signOut,
  filterAllMerchants,
  filterMerchantSystemStats,
} from "../../../services/http_service";
import { appContext } from "../../../store/appContext";

import NProgress from "nprogress";
import store from "store";
import moment from "moment";

import "./Merchants.scss";
import "../../../assets/css/merchant_list.css";
import ReferredMarketers from "./ReferredMarketers";
import * as dayjs from "dayjs";
import AppAlert from "../../../components/Alert/AppAlert";
import { useLocation, useHistory } from "react-router-dom";
let { RangePicker } = DatePicker;
let dateFormat = "YYYY-MM-DD";

export default function Merchants() {
  let global_filters = store.get("merchant_list_filters");

  if (!global_filters) {
    store.set("merchant_list_filters", {
      all_time: true,
      end_date: null,
      single_date: null,
      start_date: null,
      limit: 10,
      page: 1,
      location: "lekki",
      target: "kyc_absent",
      total: 0,
    });
  }

  let context = useContext(appContext);
  let location = useLocation();
  let history = useHistory();

  let { globalFilter, updateStateValue } = context;

  let [selectedItem, setselectedItem] = useState(null);
  let [visible, setVisible] = useState(false);
  let [visible_, setVisible_] = useState(false);

  let [merchantDetails, setmerchantDetails] = useState({});
  let [filters, setFilters] = useState([]);
  let [loadingGraph, setloadingGraph] = useState(false);
  let [merchantTxnGraphData, setmerchantTxnGraphData] = useState({});
  let [cashpoints, setCashpoints] = useState({});
  let [filter_pagination, setFilterPagination] = useState({ page: 1, total: 0 });

  let [searchVal, setSearchVal] = useState("");
  let [is_fetching, setIsFetching] = useState(false);
  let [searching, setSearching] = useState(false);
  let [sys_stats, setSysStats] = useState({});
  let [active_bucket, setActiveBucket] = useState("");
  let [search_nuban, setSearchNuban] = useState(false);

  let [merchant_list, setMerchantList] = useState([]);
  let [showAlert, setshowAlert] = useState({ show: false, type: "", description: "" });

  let handleLogout = useCallback(() => {
    NProgress.inc();

    signOut()
      .then((res) => {
        localStorage.clear();
        sessionStorage.clear();
        store.clearAll();
        history.push("/?return_url=" + location.pathname);
      })
      .catch((err) => {
        localStorage.clear();
        sessionStorage.clear();
        store.clearAll();
        history.push("/?return_url=" + location.pathname);
      })
      .finally(() => {
        NProgress.done();
      });
  }, [history, location.pathname]);

  let toDashboard = (active_merchant) => {
    history.push("/merchant/" + active_merchant);
  };

  let filterMerchant = useCallback(
    async (data) => {
      NProgress.inc();
      store.set("active_bucket", data.target);
      setIsFetching(true);

      try {
        let results = await filterAllMerchants({
          target: data.target,
          page: data.page,
          limit: data.limit,
          all_time: data.all_time,
          end_date: data.end_date,
          single_date: data.single_date,
          start_date: data.start_date,
          isReady: data.target === "ready_verify" ? true : false,
        });

        setMerchantList(results.data.merchants);
        setActiveBucket(data.target);
        setIsFetching(false);
        setFilterPagination({ page: data.page, total: Number(results.data.pages) * results.data.merchants.length });

        store.set("merchant_list_filters", {
          all_time: data.all_time,
          end_date: data.end_date,
          single_date: data.single_date,
          start_date: data.start_date,
          limit: data.limit,
          page: data.page,
          location: data.location,
          target: data.target,
          total: Number(results.data.pages) * results.data.merchants.length,
        });

        NProgress.done();
      } catch (err) {
        NProgress.done();
        setIsFetching(false);
        if (err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();
      }
    },
    [handleLogout]
  );

  let fetchSystemStats = useCallback(async () => {
    NProgress.inc();
    setIsFetching(true);

    try {
      let results = await filterMerchantSystemStats();
      setSysStats(results.data);

      NProgress.done();
      setIsFetching(false);
    } catch (err) {
      if (err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();
      NProgress.done();
      setIsFetching(false);
    }
  }, [handleLogout]);

  let fetchCashpoints = (merchant, page) => {
    NProgress.inc();
    getMerchantCashpoints({
      ...globalFilter,
      page,
      merchant,
    })
      .then((res) => {
        setCashpoints(res.data);
        setVisible_(true);
      })
      .catch((err) => {
        if (err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();
      })
      .finally(() => {
        NProgress.done();
      });
  };

  let searchMerchant = useCallback(
    (page, limit, search_val) => {
      if (search_val) {
        setSearching(true);

        store.set("search_value", search_val);
        store.set("active_bucket", "");

        let data;

        store.get("search_nuban_")
          ? (data = {
              page: page || 1,
              nuban: search_val.trim(),
              search_nuban: store.get("search_nuban_"),
              limit: limit || 10,
            })
          : (data = {
              page: page || 1,
              name: search_val.trim(),
              search_nuban: store.get("search_nuban_"),
              limit: limit || 10,
            });

        NProgress.inc();
        setIsFetching(true);

        searchMerchantData(data)
          .then((res) => {
            setMerchantList(res.data.merchants);
            setFilterPagination({ page: page, total: Number(res.data.pages) * res.data.merchants.length });

            store.set("merchant_list_filters", {
              all_time: store.get("merchant_list_filters").all_time,
              end_date: store.get("merchant_list_filters").end_date,
              single_date: store.get("merchant_list_filters").single_date,
              start_date: store.get("merchant_list_filters").start_date,
              limit: limit,
              page: page,
              location: store.get("merchant_list_filters").location,
              target: store.get("merchant_list_filters").target,
              total: Number(res.data.pages) * res.data.merchants.length,
            });
          })
          .catch((err) => {
            if (err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();

            notification.error({
              message: "ERROR",
              description: err.response ? err.response.data.message : "An error occured. Please try again.",
            });
          })
          .finally(() => {
            setSearching(false);
            NProgress.done();
            setIsFetching(false);
          });
      }
    },
    [handleLogout]
  );

  let fetchAllMerchants = useCallback((data) => {
    if (!data) {
      data = {
        all_time: true,
        end_date: null,
        single_date: null,
        start_date: null,
        limit: 10,
        page: 1,
        location: "lekki",
        target: "kyc_absent",
        total: 0,
      };
    }

    if (store.get("active_bucket")) {
      filterMerchant(data);
    } else if (store.get("search_value")) {
      setSearchVal(store.get("search_value"));

      setTimeout(() => {
        searchMerchant(data.page, data.limit, store.get("search_value"));
      }, 1000);
    } else {
      filterMerchant(data);
    }
  }, []);

  useEffect(() => {
    if (store.get("search_value")) {
      setSearchVal(store.get("search_value"));
    }

    fetchSystemStats();
    fetchAllMerchants(store.get("merchant_list_filters"));
  }, []);

  let _merchant_list_columns = [
    {
      title: "Date Created",
      dataIndex: "createdon",
      render: (_, record) => <span>{record.createdon ? new Date(record.createdon).toLocaleString() : "N/A"}</span>,
      key: "createdon",
      className: "p-3",
    },
    {
      title: "Name",
      dataIndex: "businessname",
      key: "businessname",
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => {
            toDashboard(record._id);
          }}
        >
          {record.businessname}
        </Button>
      ),
      className: "p-3",
    },
    {
      title: "Email",
      dataIndex: "businessemail",
      key: "businessemail",
      className: "p-3",
    },
    {
      title: "Verified",
      dataIndex: "certified",
      render: (certified) => <Tag color={certified ? "green" : "red"}>{certified ? "True" : "False"}</Tag>,
      key: "verified",
      className: "p-3",
    },
    // {
    //   title: "Upgraded",
    //   dataIndex: "verified",
    //   render: (verified) => <Tag color={verified ? "green" : "red"}>{verified ? "True" : "False"}</Tag>,
    //   key: "verified",
    //   className: "p-3",
    // },
    // {
    //   title: "Date Upgraded",
    //   dataIndex: "verifiedon",
    //   render: (_, record) => <span>{record.verifiedon ? new Date(record.verifiedon).toLocaleString() : "N/A"}</span>,
    //   key: "verifiedon",
    //   className: "p-3",
    // },
    {
      title: "KYC",
      dataIndex: "kyc",
      render: (_, record) => (
        <div>
          <div>
            {record.user && record.user.issetNIN_BVN && (
              <Tag color="green" className="mb-1">
                BVN/NIN
              </Tag>
            )}
            {record.user && record.user.isset_id_card && <Tag color="green">ID Card</Tag>}
          </div>

          <div>
            {record.user && record.user.isset_proof_of_address && (
              <Tag color="green" className="mb-1">
                Address Proof
              </Tag>
            )}
            {record.user && record.user.isset_international_passport && <Tag color="green">Intl' Passport</Tag>}
          </div>

          <div>{record.user && record.user.isset_liveliness_test && <Tag color="green">Liveliness Video</Tag>}</div>

          <div>{record.user && record.user?.isset_ammban_certificate && <Tag color="green">AMMBAN</Tag>}</div>

          <div>{record.user && record.user?.isset_certificate_of_business && <Tag color="green">CAC</Tag>}</div>

          <div>{record.user && record.user?.bvn_verified && !record.user?.issetNIN_BVN && <Tag color="green">BVN not verified</Tag>}</div>

          {record.user &&
            !record.user.isset_international_passport &&
            !record.user.isset_proof_of_address &&
            !record.user.isset_id_card &&
            !record.user.issetNIN_BVN &&
            !record.user.isset_liveliness_test &&
            !record.user?.isset_ammban_certificate &&
            !record.user?.isset_certificate_of_business && <Tag color="red">ABSENT</Tag>}
        </div>
      ),
      key: "kyc",
      className: "p-3",
    },
    {
      title: "Agency Type",
      dataIndex: "agencytype",
      render: (_, record) => <span>{record.isaggregator ? "Aggregator" : record?.aggregatorcode && !record.isaggregator ? "Member" : "N/A"}</span>,
      key: "agencytype",
      className: "p-3",
    },
  ];

  function disabledDate(current) {
    return current && current > moment().endOf("day");
  }

  let handleSingleDate = (event) => {
    let single_date = event.format(dateFormat);

    let data = {
      all_time: false,
      end_date: null,
      single_date: single_date,
      start_date: null,
      limit: 10,
      page: 1,
      target: store.get("merchant_list_filters") && store.get("merchant_list_filters").target,
      location: store.get("merchant_list_filters") && store.get("merchant_list_filters").location,
    };

    store.set("filters", ["single_date"]);

    store.set("merchant_list_filters", data);

    fetchAllMerchants(data);
  };

  let handleDateRange = (event) => {
    let start_date = event[0].format(dateFormat);
    let end_date = event[1].format(dateFormat);

    let data = {
      all_time: false,
      end_date: end_date,
      single_date: null,
      start_date: start_date,
      limit: 10,
      page: 1,
      target: store.get("merchant_list_filters") && store.get("merchant_list_filters").target,
      location: store.get("merchant_list_filters") && store.get("merchant_list_filters").location,
    };

    store.set("filters", ["date_range"]);

    store.set("merchant_list_filters", data);

    fetchAllMerchants(data);
  };

  let FilterMenu = () => {
    return (
      <div className="text-right mb-3">
        <Space direction="vertical">
          <div key="2" style={{ cursor: "pointer" }} className="pb-2">
            <DatePicker onChange={handleSingleDate} format={dateFormat} placeholder="Single Date" disabledDate={disabledDate} />
          </div>

          <div key="3">
            <RangePicker format={dateFormat} onChange={handleDateRange} disabledDate={disabledDate} />
          </div>
        </Space>
      </div>
    );
  };

  let handleBucketFilter = (target) => {
    store.set("search_value", "");
    setSearchVal("");
    store.set("search_nuban_", false);

    let data = {
      all_time: true,
      end_date: null,
      single_date: null,
      start_date: null,
      limit: 10,
      page: 1,
      target: target,
      location: store.get("merchant_list_filters") && store.get("merchant_list_filters").location,
    };

    store.set("merchant_list_filters", data);

    fetchAllMerchants(data);
  };

  function handleSwitch(checked) {
    setSearchNuban(checked);
    store.set("search_nuban_", checked);
  }

  return (
    <div className="merchants">
      <Spin spinning={is_fetching}>
        <AppAlert show={showAlert.show} type={showAlert.type} description={showAlert.description} />

        <FilterMenu />

        <div className="text-right">
          <Input
            style={{
              width: 200,
              height: 40,
              textAlign: "right",
              //marginTop: -113,
              //marginRight: 115,
              border: `0.1px solid rgba(9, 66, 125, 0.123)`,
              backgroundColor: `#fff`,
              borderRadius: 5,
            }}
            // onBlur={() => searchMerchant(null)}
            onPressEnter={() => searchMerchant(1, 10, searchVal)}
            value={searchVal}
            suffix={
              searching ? (
                <LoadingOutlined />
              ) : (
                <CloseCircleFilled
                  onClick={() => {
                    setSearchVal("");
                    store.set("search_value", "");
                    fetchAllMerchants({
                      all_time: true,
                      end_date: null,
                      single_date: null,
                      start_date: null,
                      limit: 10,
                      page: 1,
                      target: store.get("merchant_list_filters") && store.get("merchant_list_filters").target,
                      location: store.get("merchant_list_filters") && store.get("merchant_list_filters").location,
                    });
                  }}
                />
              )
            }
            onChange={(e) => {
              setSearchVal(e.target.value);
            }}
            bordered={false}
            placeholder="Search by name, email and phone."
          />
        </div>

        <div className="text-right mb-2">
          <Form.Item className="mb-0">
            <span className="mr-2">Search by account number</span>
            <Switch name="message_toggle" defaultChecked={store.get("search_nuban_")} onChange={handleSwitch} />
          </Form.Item>
        </div>

        {!store.get("merchant_list_filters").all_time && (
          <div className="text-right text-muted text-small" style={{ fontSize: "small" }}>
            Current filters:
            {store.get("filters") &&
              store.get("filters").map((f) => {
                return <span> {f} </span>;
              })}
          </div>
        )}

        <div className="text-right mb-3">
          <Button
            type="link"
            key="1"
            className="px-0"
            onClick={() => {
              store.set("filters", []);
              store.set("search_nuban_", false);
              store.set("search_value", "");
              setSearchVal("");
              setSearchNuban(false);

              store.set("txn_pagination", {
                all_time: true,
                end_date: null,
                single_date: null,
                start_date: null,
                limit: 10,
                page: 1,
                target: store.get("merchant_list_filters") && store.get("merchant_list_filters").target,
                location: store.get("merchant_list_filters") && store.get("merchant_list_filters").location,
              });

              // fetchAllMerchants({
              // 	all_time: true,
              // 	end_date: null,
              // 	single_date: null,
              // 	start_date: null,
              // 	limit: 10,
              // 	page: 1,
              // 	target: store.get("merchant_list_filters") && store.get("merchant_list_filters").target,
              // 	location: store.get("merchant_list_filters") && store.get("merchant_list_filters").location
              // });

              history.go(0);
            }}
          >
            Clear Filters
          </Button>
        </div>

        <div className="site-card-wrapper">
          <Row align="bottom" gutter={16}>
            <Col span={4}>
              <Card bordered className={active_bucket === "kyc_absent" ? "active_bucket crd" : "crd"}>
                <Button
                  style={{ fontSize: "12px" }}
                  className="px-0"
                  onClick={() => {
                    handleBucketFilter("kyc_absent", 1, 10);
                  }}
                  type="link"
                >
                  Signup with no KYC: {sys_stats.kyc_absent || 0}
                </Button>
              </Card>
            </Col>
            {/* 
            <Col span={4}>
              <Card bordered className={active_bucket === "bvn_absent" ? "active_bucket crd" : "crd"}>
                <Button
                  style={{ fontSize: "12px" }}
                  className="px-0"
                  onClick={() => {
                    handleBucketFilter("bvn_absent", 1, 10);
                  }}
                  type="link"
                >
                  BVN Absent: {sys_stats.bvn_absent || 0}
                </Button>
              </Card>
            </Col> */}

            <Col span={4}>
              <Card bordered className={active_bucket === "kyc_verified" ? "active_bucket crd" : "crd"}>
                <Button
                  style={{ fontSize: "12px" }}
                  className="px-0"
                  onClick={() => {
                    handleBucketFilter("kyc_verified", 1, 10);
                  }}
                  type="link"
                >
                  Upgraded but no KYC: {sys_stats.kyc_verified || 0}
                </Button>
              </Card>
            </Col>

            <Col span={5}>
              <Card title="" bordered className={active_bucket === "kyc_present" ? "active_bucket crd" : "crd"}>
                <Button
                  style={{ fontSize: "12px" }}
                  className="px-0"
                  onClick={() => {
                    handleBucketFilter("kyc_present", 1, 10);
                  }}
                  type="link"
                >
                  KYC present but not verified: {sys_stats.kyc_present || 0}{" "}
                </Button>
              </Card>
            </Col>

            <Col span={5}>
              <Card title="" bordered className={active_bucket === "ready_verify" ? "active_bucket crd" : "crd"}>
                <Button
                  style={{ fontSize: "12px" }}
                  className="px-0"
                  onClick={() => {
                    handleBucketFilter("ready_verify", 1, 10);
                  }}
                  type="link"
                >
                  Ready to be Verified: {sys_stats.ready_to_verify || 0}{" "}
                </Button>
              </Card>
            </Col>

            <Col span={5}>
              <Card bordered className={active_bucket === "certified" ? "active_bucket crd" : "crd"}>
                <Button
                  style={{ fontSize: "12px" }}
                  className="px-0"
                  onClick={() => {
                    handleBucketFilter("certified", 1, 10);
                  }}
                  type="link"
                >
                  Verified but not upgraded: {sys_stats.certified || 0}
                </Button>
              </Card>
            </Col>

            <Col span={4} className="pt-2">
              <Card title="" bordered className={active_bucket === "verified" ? "active_bucket crd" : "crd"}>
                <Button
                  style={{ fontSize: "12px" }}
                  className="px-0"
                  onClick={() => {
                    handleBucketFilter("verified", 1, 10);
                  }}
                  type="link"
                >
                  Upgraded merchants: {sys_stats.verified || 0}
                </Button>
              </Card>
            </Col>

            <Col span={4} className="pt-2">
              <Card bordered className={active_bucket === "investigate" ? "active_bucket crd" : "crd"}>
                <Button
                  style={{ fontSize: "12px" }}
                  className="px-0"
                  onClick={() => {
                    handleBucketFilter("investigate", 1, 10);
                  }}
                  type="link"
                >
                  Under investigation: {sys_stats.under_investigation || 0}
                </Button>
              </Card>
            </Col>

            <Col span={4} className="pt-2">
              <Card bordered className={active_bucket === "limited_upgrade" ? "active_bucket crd" : "crd"}>
                <Button
                  style={{ fontSize: "12px" }}
                  className="px-0"
                  onClick={() => {
                    handleBucketFilter("limited_upgrade", 1, 10);
                  }}
                  type="link"
                >
                  Limited Upgrade: {sys_stats.limited_upgrade || 0}
                </Button>
              </Card>
            </Col>

            <Col span={4} className="pt-2">
              <Card bordered className={active_bucket === "minimal_kyc" ? "active_bucket crd" : "crd"}>
                <Button
                  style={{ fontSize: "12px" }}
                  className="px-0"
                  onClick={() => {
                    handleBucketFilter("minimal_kyc", 1, 10);
                  }}
                  type="link"
                >
                  Minimal KYC: {sys_stats.minimal_kyc || 0}
                </Button>
              </Card>
            </Col>

            <Col span={4} className="pt-2">
              <Card bordered className={active_bucket === "trashed" ? "active_bucket crd" : "crd"}>
                <Button
                  style={{ fontSize: "12px" }}
                  className="px-0"
                  onClick={() => {
                    handleBucketFilter("trashed", 1, 10);
                  }}
                  type="link"
                >
                  Trash account: {sys_stats.trashed || 0}
                </Button>
              </Card>
            </Col>
          </Row>
        </div>

        <Divider />

        <Table
          columns={_merchant_list_columns}
          dataSource={merchant_list}
          bordered
          size="small"
          rowKey={(record) => record._id}
          showSizeChanger={false}
          onChange={(event) => {
            fetchAllMerchants({
              all_time: store.get("merchant_list_filters").all_time,
              end_date: store.get("merchant_list_filters").end_date,
              single_date: store.get("merchant_list_filters").single_date,
              start_date: store.get("merchant_list_filters").start_date,
              limit: 10,
              page: event.current,
              target: store.get("merchant_list_filters") && store.get("merchant_list_filters").target,
              location: store.get("merchant_list_filters") && store.get("merchant_list_filters").location,
            });
          }}
          pagination={{
            total: Number(filter_pagination.total),
            current: Number(filter_pagination.page),
          }}
          loading={searching}
          scroll={{ x: "max-content" }}
        />

        <Drawer visible={visible} width={900} closable={false}>
          <AppChart setVisible={setVisible} showKey={false} chartData={merchantTxnGraphData} title="Merchant Transaction Totals" />
        </Drawer>

        <Drawer visible={visible_} width={1100} closable={false}>
          <ReferredMarketers
            heading="Cashpoints"
            pageData={cashpoints.cashpoints}
            setVisible={setVisible_}
            emptyText="No Cashpoints for this merchant"
            updateData={fetchCashpoints}
            current_page={cashpoints.page}
            pages={cashpoints.pages}
          />
        </Drawer>
      </Spin>
    </div>
  );
}
