import { Empty, notification, Pagination, } from "antd";

import dayjs from "dayjs";
import React, { useContext, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { getLoginAudit, signOut } from "../../../services/http_service";
import { appContext } from "../../../store/appContext";
import NProgress from 'nprogress';

import "./Merchants.scss";

export default function EndpoinLogs() {
	let context = useContext(appContext);
	let { loginAudit, updateStateValue, globalFilter } = context;
	let history = useHistory();
	let location = useLocation();

	let handleLogout = useCallback(() => {

        NProgress.inc();

		signOut()
			.then((res) => {
				localStorage.clear();
				sessionStorage.clear();
				history.push("/?return_url=" + location.pathname);
			})
			.catch((err) => {
				localStorage.clear();
				sessionStorage.clear();
				history.push("/?return_url=" + location.pathname);
			}).finally(() => {
				NProgress.done();
			});

    }, [history, location.pathname]);

	let fetchData = useCallback((page) => {
		getLoginAudit({
			...globalFilter,
			page,
		})
		.then((res) => {
			updateStateValue({ loginAudit: res.data });
		})
		.catch((err) => {
			if(err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();

			notification.error({
			message: "ERROR",
			description: err.response
				? err.response.data.message
				: "An error occured. Please try again.",
			});
		})
		.finally(() => { NProgress.done(); });
	}, [globalFilter, handleLogout, updateStateValue]);

	useEffect(() => {
		fetchData(1);
	}, [fetchData, globalFilter]);

	return (
		<div className='merchants' style={{ textAlign: "left" }}>
			<div className='trow thead'>
				<div className='tdata'>Username</div>
				<div className='tdata email'>Email</div>
				<div className='address tdata'>IP</div>
				<div className='tdata'>Sign Up Date</div>
				<div className='tdata address'>Last Login Date</div>
			</div>
			
			{loginAudit.log && loginAudit.log.length && loginAudit.log.map((itm, i) => (
				<div className='single-item' key={i}>
					<div className='trow titem'>
						<div className='tdata'>{itm.name}</div>
						<div className='tdata email'>{itm.email}</div>
						<div className='address tdata'>{itm.ip}</div>
						<div className='tdata'>
						{dayjs(itm.sigup_date).format("DD MMM YYYY")}
						</div>
						<div className='tdata'>
						{dayjs(itm.last_login_date).format("DD MMM YYYY")}
						</div>
					</div>
				</div>
			))}

			{loginAudit.log.length ? (
				<Pagination
					style={{ marginTop: 50 }}
					defaultCurrent={loginAudit.page}
					total={10 * loginAudit.pages}
					onChange={(e) => {
						fetchData(e);
					}}
				/>
			) : null}
			{!loginAudit.log.length && <Empty />}
		</div>
	);
}
