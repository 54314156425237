import { Empty, notification, Pagination } from "antd";
import dayjs from "dayjs";
import React, { useContext, useEffect, useCallback, useState } from "react";
import { getEndpointsAudit, signOut } from "../../../services/http_service";
import { appContext } from "../../../store/appContext";
import { useHistory, useLocation } from "react-router-dom";
import NProgress from 'nprogress';

import "./Merchants.scss";
export default function EndpoinLogs() {
	let history = useHistory();
	let location = useLocation();

	let context = useContext(appContext);
	let { globalFilter } = context;
	let [endpointsAudit, setEndpointAuditLogs] = useState({pages: 0, page: 1, endpointlogs: []});

	let handleLogout = useCallback(() => {

        NProgress.inc();

		signOut()
			.then((res) => {
				localStorage.clear();
				sessionStorage.clear();
				history.push("/?return_url=" + location.pathname);
			})
			.catch((err) => {
				localStorage.clear();
				sessionStorage.clear();
				history.push("/?return_url=" + location.pathname);
			}).finally(() => {
				NProgress.done();
			});

    }, [history, location.pathname]);

	let fetchData = useCallback((page) => {
		NProgress.inc();
		getEndpointsAudit({
			...globalFilter,
			page,
			})
			.then((res) => {
				setEndpointAuditLogs(res.data);
			})
			.catch((err) => {
				if(err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();
				
				notification.error({
				message: "ERROR",
				description: err.response
					? err.response.data.message
					: "An error occured. Please try again.",
				});
		})
		.finally(() => { NProgress.done(); });

	}, [globalFilter, handleLogout]);

	useEffect(() => {
		fetchData(1);
	}, [fetchData, globalFilter]);

	return (
		<div className='merchants' style={{ textAlign: "left" }}>
			<div className='trow thead'>
				<div className='tdata' style={{width: "300px"}}>Endpoint Path</div>
				<div className='tdata' style={{width: "200px"}}>Date</div>
				<div className='tdata email'>User-Agent</div>
				<div className='tdata' style={{width: "120px"}}>IP</div>
			</div>
			
			{endpointsAudit && endpointsAudit.endpointlogs && endpointsAudit.endpointlogs.map((itm, i) => (
				<div className='single-item' key={i}>
					<div className='trow titem'>
						<div className='tdata' style={{width: "280px", overflowWrap: "break-word"}}>{itm.url}</div>
						<div className='tdata ' style={{width: "200px"}}>
						{dayjs(itm.createdon).format("DD MMM YYYY")}
						</div>
						<div className='tdata' style={{width: "350px"}}>{itm.user_agent}</div>
						<div className='tdata' style={{width: "120px"}}>{itm.IP}</div>
					</div>
				</div>
			))}

			{endpointsAudit && endpointsAudit.endpointlogs ? (
				<Pagination
					style={{ marginTop: 50 }}
					defaultCurrent={endpointsAudit.page}
					current={endpointsAudit.page}
					total={10 * endpointsAudit.pages}
					onChange={(e) => {
						fetchData(e);
					}}
				/>
			) : null}
			{endpointsAudit && !endpointsAudit.endpointlogs.length && <Empty />}
		</div>
	);
}
