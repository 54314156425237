import { Button, Checkbox, Input, notification } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import { sendNotificationService, stopNotificationService } from "../../../services/http_service";

export const Notifications = () => {
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [stopLoading, setStopLoading] = useState(false);
  const [sendToInbox, setSendToInbox] = useState(false);
  const [isPinned, setIsPinned] = useState(false);

  const onClick = async () => {
    if (!title.trim().length || !message.trim().length) {
      notification.info({
        message: "Title and message is required",
      });
      return;
    }
    const data = {
      title,
      message,
      isPinned,
      sendToInbox,
    };
    try {
      setLoading(true);
      const res = await sendNotificationService(data);
      notification.success({
        message: "Broadcast started successfully",
      });
    } catch (error) {
      setLoading(false);
      console.error("err", error?.response);
    }
  };

  const onStopBroadcast = async () => {
    try {
      setStopLoading(true);
      setLoading(false);
      const response = await stopNotificationService();
      notification.success({
        message: "Broadcast stopped successfully",
      });
      setStopLoading(false);
    } catch (err) {
      console.err("err,", err?.response);
      setStopLoading(false);
    }
  };

  return (
    <div className="merchants">
      <div className="notifications-container">
        <Input onChange={(e) => setTitle(e.target.value)} value={title} placeholder="Notification Title" />
        <TextArea value={message} onChange={(e) => setMessage(e.target.value)} style={{ minHeight: "400px", margin: "20px 0" }} className="content" placeholder="content" />
        <Checkbox checked={sendToInbox} onChange={(e) => setSendToInbox(e.target.checked)}>
          Send message to inbox
        </Checkbox>
        <Checkbox checked={isPinned} onChange={(e) => setIsPinned(e.target.checked)} style={{ marginTop: "10px" }}>
          Pin
        </Checkbox>
        <Button loading={loading} onClick={onClick} style={{ display: "block", marginTop: "10px" }}>
          Start Broadcast
        </Button>
        <Button loading={stopLoading} onClick={onStopBroadcast} style={{ display: "block", marginTop: "10px" }}>
          Stop Broadcast
        </Button>
      </div>
    </div>
  );
};
