import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import NProgress from "nprogress";
import "./Roles.scss";
import { ROLES } from "../../../store/utilities";

import avatar from "../../../assets/images/avatar.svg";
import { appContext } from "../../../store/appContext";
import { Input, notification, Select, Button, Table } from "antd";
import Modal from "antd/lib/modal/Modal";
import { addAdmin, fetchAdmins, updateAdmin, getRoles, saveNewRole, signOut, resetAdminPassword, editRole } from "../../../services/http_service";

let { Option } = Select;

function Roles(props) {
  let logged_in_admin = JSON.parse(window.atob(sessionStorage.getItem("dartpay_token").split(".")[1]));

  let context = useContext(appContext);
  let { allAdmins, allRoles, userInfo, globalFilter, updateStateValue } = context;
  let [newRole, setNewRole] = useState({ email: "", name: "", phone: "", role: "", _id: "" });
  let [user_inputs, setUserInputs] = useState({ role_name: "", role_level: "", role_id: "" });
  let [loading_target, setLoadingTarget] = useState("");
  let [target_admin, setTargetAdmin] = useState("");

  let [openModal, setOpenModal] = useState("");
  let [isEditting, setIsEditting] = useState(false);

  let [active_admin, setActiveAdmin] = useState({}); //
  let [otherusers, setOtherusers] = useState([]);

  let history = useHistory();
  let location = useLocation();

  let handleLogout = useCallback(() => {
    NProgress.inc();

    signOut()
      .then((res) => {
        localStorage.clear();
        sessionStorage.clear();
        history.push("/?return_url=" + location.pathname);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        NProgress.done();
      });
  }, [history, location.pathname]);

  let submithandler = async () => {
    let { email, name, role, phone } = newRole;

    setLoadingTarget("new_admin");

    if (!email || !name || !role) {
      notification.error("Please fill all fields");
      return;
    }

    let request = isEditting ? updateAdmin({ email, name, role, phone }, newRole._id) : addAdmin({ email, name, role, phone });

    try {
      await request;

      notification.open({
        message: "SUCCESS",
        description: `Admin ${isEditting ? "editted" : "created"} successfully`,
      });
      fetchAdminsHandler();
      setOpenModal("");
      setLoadingTarget("");
    } catch (error) {
      if (error && error.response && (error.response.status === 401 || error.response.status === 403)) handleLogout();
      notification.error({
        message: "ERROR",
        description: error.response ? error.response.data.message : "An error occured. Please try again.",
      });
      setLoadingTarget("");
    }
  };

  let handleNewRole = async () => {
    setLoadingTarget("new_role");

    try {
      await saveNewRole({ role: user_inputs.role_name });
      setUserInputs({ role_name: "" });

      notification.open({
        message: "SUCCESS",
        description: "Role successfully created.",
      });

      fetchRoles();
      setLoadingTarget("");
    } catch (error) {
      setLoadingTarget("");
      if (error && error.response && (error.response.status === 401 || error.response.status === 403)) handleLogout();

      notification.error({
        message: "ERROR",
        description: error.response ? error.response.data.message : "An error occured. Please try again.",
      });
    }
  };

  let fetchAdminsHandler = useCallback(() => {
    NProgress.inc();

    fetchAdmins({
      ...globalFilter,
      page: 1,
      limit: 10,
    })
      .then((res) => {
        updateStateValue({ allAdmins: res.data });

        let logged_in_admin = JSON.parse(window.atob(sessionStorage.getItem("dartpay_token").split(".")[1]));
        setActiveAdmin(logged_in_admin);

        let other_admins = res.data.admins.filter((a) => a.email !== logged_in_admin.email);
        setOtherusers(other_admins);
      })
      .catch((err) => {
        console.log({ err });
        if (err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();
      })
      .finally(() => {
        NProgress.done();
      });
  }, [globalFilter, updateStateValue]);

  let fetchRoles = useCallback(() => {
    NProgress.inc();

    getRoles()
      .then((res) => {
        NProgress.done();
        updateStateValue({ allRoles: res.data });
      })
      .catch((err) => {
        console.log({ err });
        if (err && err.response && (err.response.status === 401 || err.response.status === 403)) handleLogout();
      })
      .finally(() => {
        NProgress.done();
      });
  }, [updateStateValue]);

  let handleEditAdmin = (event) => {
    setNewRole({ email: event.email, name: event.name, phone: event.phone, role: event.role._id, _id: event._id });
    setOpenModal("new_admin");
    setIsEditting(true);
  };

  let handleEditRole = (event) => {
    console.log("event: ", event);

    setUserInputs({ role_name: event.role, role_level: event.role_level, role_id: event._id });
    setOpenModal("edit_role");
  };

  let resetPassword = async (email) => {
    setLoadingTarget("reset");
    setTargetAdmin(email);

    try {
      await resetAdminPassword({ email: email });

      notification.open({
        message: "SUCCESS",
        description: `Password successfully reset.`,
      });

      setLoadingTarget("");
    } catch (error) {
      setLoadingTarget("");

      if (error && error.response && (error.response.status === 401 || error.response.status === 403)) handleLogout();
      notification.error({
        message: "ERROR",
        description: error.response ? error.response.data.message : "An error occured. Please try again.",
      });
    }
  };

  let editRoleHandler = async () => {
    setLoadingTarget("edit_role");

    let data = {
      role_id: user_inputs.role_id,
      role_level: user_inputs.role_level,
    };

    try {
      await editRole(data);
      setUserInputs({ role_name: "", role_id: "", role_level: "" });

      fetchRoles();
      setLoadingTarget("");

      notification.open({
        message: "SUCCESS",
        description: "Role successfully updated.",
      });
    } catch (error) {
      setLoadingTarget("");

      if (error && error.response && (error.response.status === 401 || error.response.status === 403)) handleLogout();

      notification.error({
        message: "ERROR",
        description: error.response ? error.response.data.message : "An error occured. Please try again.",
      });
    }
  };

  useEffect(() => {
    fetchRoles();
    fetchAdminsHandler();
  }, [fetchRoles, fetchAdminsHandler]);

  let role_columns = [
    {
      title: "Name",
      dataIndex: "role",
      render: (role) => `${role.toUpperCase()}`,
      key: "role",
    },
    {
      title: "Role Level",
      dataIndex: "role_level",
      key: "role_level",
    },
    {
      title: "Created On",
      dataIndex: "createdon",
      render: (createdon) => `${new Date(createdon).toDateString()}`,
      key: "createdon",
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "x",
      render: (_, record) => (
        <div>
          <Button
            disabled={logged_in_admin.role_level < ROLES.super_admin_view.level}
            onClick={() => {
              handleEditRole(record);
            }}
            type="link"
          >
            Edit role
          </Button>
        </div>
      ),
    },
  ];

  let admin_columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (role) => `${role.role}`,
      key: "role",
    },
    {
      title: "Created On",
      dataIndex: "createdon",
      render: (createdon) => `${new Date(createdon).toDateString()}`,
      key: "createdon",
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "x",
      render: (_, record) => (
        <div>
          <Button
            disabled={logged_in_admin.role_level < ROLES.super_admin.level}
            onClick={() => {
              handleEditAdmin(record);
            }}
            type="link"
          >
            Edit
          </Button>
          <Button
            loading={loading_target === "reset" && target_admin === record.email}
            danger
            disabled={logged_in_admin.role_level < ROLES.super_admin.level}
            onClick={() => {
              resetPassword(record.email);
            }}
          >
            Reset Password
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="roles white-bg">
      <div className="me">
        <div className="user mine">
          <div className="avatar-menu">
            <img src={avatar} alt="" />
            <div className="info">
              <p>{active_admin.username}</p>
              <span>{active_admin.role}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex me others" style={{ justifyContent: "space-between" }}>
        <p className="section-title"></p>

        <div>
          <Button
            type="primary"
            disabled={logged_in_admin.role_level < 5}
            onClick={() => {
              setIsEditting(false);
              setOpenModal("new_role");
              setNewRole({
                email: "",
                name: "",
                phone: "",
                role: "",
              });
            }}
          >
            Create a New Role
          </Button>
        </div>
      </div>

      <div className="me others">
        <p className="section-title">Existing Roles</p>

        <Table dataSource={allRoles} columns={role_columns} pagination={false} />
      </div>

      <div className="me others">
        <div className="text-right">
          <Button
            type="primary"
            disabled={logged_in_admin.role_level < 5}
            onClick={() => {
              setIsEditting(false);
              setOpenModal("new_admin");
              setNewRole({
                email: "",
                name: "",
                phone: "",
                role: "",
              });
            }}
          >
            Create a New Admin
          </Button>
        </div>

        <p className="section-title">Existing Admin</p>

        <Table dataSource={otherusers} key={(_, idx) => `Itemm${idx}`} columns={admin_columns} pagination={false} />
      </div>

      <Modal visible={openModal === "new_admin"} confirmLoading={loading_target === "new_admin"} onOk={submithandler} onCancel={() => setOpenModal("")}>
        {/* <img src={avatar} alt="" style={{ margin: "20px auto", width: 80 }} /> */}
        <br />
        <Input placeholder="fullname" style={{ marginBottom: 12 }} size="large" value={newRole.name} onChange={(e) => setNewRole({ ...newRole, name: e.target.value })} />
        <Input value={newRole.email} onChange={(e) => setNewRole({ ...newRole, email: e.target.value })} placeholder="email" style={{ marginBottom: 12 }} size="large" />
        <Input value={newRole.phone} onChange={(e) => setNewRole({ ...newRole, phone: e.target.value })} placeholder="phone" style={{ marginBottom: 12 }} size="large" />
        <Select style={{ width: "100%" }} size="large" placeholder="Role" value={newRole.role} defaultValue="" onChange={(e) => setNewRole({ ...newRole, role: e })}>
          <Option disabled value={""}>
            Role
          </Option>
          {allRoles.map((role, idx) => (
            <Option key={idx} value={role._id}>
              {" "}
              {role.role}
            </Option>
          ))}
        </Select>
      </Modal>

      <Modal
        title={"Create new role"}
        confirmLoading={loading_target === "new_role"}
        visible={openModal === "new_role"}
        okButtonProps={{ disabled: !user_inputs.role_name }}
        onOk={handleNewRole}
        onCancel={() => setOpenModal("")}
      >
        {/* <Input
					placeholder="Role name"
					style={{ marginBottom: 12 }}
					size="large"
					value={user_inputs.role_name}
					onChange={(e) => setUserInputs({ ...user_inputs, role_name: e.target.value })}
				/> */}

        <Select style={{ width: "100%" }} size="large" placeholder="Select Role" value={user_inputs.role_name} defaultValue="" onChange={(e) => setUserInputs({ ...user_inputs, role_name: e })}>
          <Option disabled value={""}>
            Role
          </Option>
          <Option value={"super_admin"}>Super Admin</Option>
          <Option value={"super_admin_view"}>Super Admin View</Option>
          <Option value={"compliance"}>Compliance</Option>
          <Option value={"verifier"}>Verifier</Option>
          <Option value={"support"}>Support</Option>
          <Option value={"business_management"}>Business Management</Option>
        </Select>
      </Modal>

      <Modal
        title={"Edit " + user_inputs.role_name}
        confirmLoading={loading_target === "edit_role"}
        visible={openModal === "edit_role"}
        okButtonProps={{ disabled: !user_inputs.role_level }}
        onOk={editRoleHandler}
        onCancel={() => setOpenModal("")}
      >
        {/* <Input
					placeholder="Role name"
					style={{ marginBottom: 12 }}
					size="large"
					value={user_inputs.role_name}
					onChange={(e) => setUserInputs({ ...user_inputs, role_name: e.target.value })}
				/> */}

        <Input
          placeholder="Role level"
          style={{ marginBottom: 12 }}
          size="large"
          type="number"
          value={user_inputs.role_level}
          onChange={(e) => setUserInputs({ ...user_inputs, role_level: e.target.value })}
        />
      </Modal>
    </div>
  );
}

export default Roles;
